import * as React from "react";
import Image from "next/image";
import { FormattedMessage, useIntl } from "react-intl";

import { SidebarLayout } from "@common";

import { ElearningsSidebar } from "./ElearningsSidebar/ElearningsSidebar";
import { ElearningsQuickSearchForm } from "./ElearningsQuickSearchForm/ElearningsQuickSearchForm";
import { ElearningsTopSchools } from "./ElearningsTopSchools/ElearningsTopSchools";
import { ElearningsCourses } from "./ElearningsCourses/ElearningsCourses";
import { ElearningsWebinars } from "./ElearningsWebinars/ElearningsWebinars";
import { ElearningsWorkshops } from "./ElearningsWorkshops/ElearningsWorkshops";
import { ElearningsHowItWorks } from "./ElearningsHowItWorks/ElearningsHowItWorks";
import messages from "./messages";
import { ElearningsUniversities } from "./ElearningsUniversities/ElearningsUniversities";

type TopSchoolKey = string;
type TopSchoolCounter = number;

export interface ElearningsProps {
	topTenSchools: [TopSchoolKey, TopSchoolCounter][];
}

export const Elearnings = ({ topTenSchools }: ElearningsProps) => {
	const { formatMessage } = useIntl();

	return (
		<SidebarLayout sidebar={<ElearningsSidebar />}>
			<main className="min-h-full w-full p-8">
				<div className="flex w-full flex-col items-center justify-around rounded-xl bg-green-700 p-16 text-center xl:flex-row xl:p-32 xl:text-left">
					<div>
						<h1 className="text-5xl font-extrabold text-white">
							<FormattedMessage {...messages.findYourPerfectElearning} />
						</h1>
						<p className="mt-4 mb-8 text-lg text-gray-100">
							<FormattedMessage {...messages.youCanAccessALotOfCourses} />
						</p>
						<ElearningsQuickSearchForm />
					</div>
					<div className="hidden xl:block">
						<div className="relative mx-auto w-96">
							<Image
								className="object-cover"
								alt={formatMessage(messages.youCanAccessALotOfCourses)}
								src="/elearnings/woman-giving-course.svg"
								layout="responsive"
								width="100%"
								height="100%"
							/>
						</div>
					</div>
				</div>
				<div className="mb-24 w-full space-y-32 p-8 py-16 xl:p-28 xl:py-28 2xl:p-32 2xl:py-32">
					<ElearningsTopSchools topTenSchools={topTenSchools} />

					<ElearningsCourses />

					<ElearningsWebinars />

					<ElearningsWorkshops />

					<ElearningsHowItWorks />

					<ElearningsUniversities />
				</div>
			</main>
		</SidebarLayout>
	);
};
