import * as React from "react";
import { Modal } from "flowbite-react";
import { FormattedMessage } from "react-intl";

import { Button, X } from "@common";
import { useAnalytics, useToggle } from "@libs";

import { BusinessProfileTypes } from "constants/Profiles";

import { OnboardingHeader } from "./OnboardingHeader";
import { OnboardingFooter } from "./OnboardingFooter";

import messages from "./messages";

export interface OnboardingButtonDirectionProps {
	onPress: () => void;
	loading?: boolean;
	disabled?: boolean;
}

export interface OnboardingModalProps {
	modal: {
		title: string;
	};
	stepNames: string[];
	currentIndexActiveStep: number;
	children: JSX.Element | JSX.Element[];
	businessProfileType: BusinessProfileTypes;
	continueButton: OnboardingButtonDirectionProps;
	previousButton: OnboardingButtonDirectionProps;
	finishButton: OnboardingButtonDirectionProps;
	hasReachedTheMaximumCreatedProfiles?: boolean;
}

export const OnboardingModal = ({
	children,
	modal,
	businessProfileType,
	stepNames,
	currentIndexActiveStep,
	continueButton,
	previousButton,
	finishButton,
	hasReachedTheMaximumCreatedProfiles,
}: OnboardingModalProps) => {
	const { event } = useAnalytics();
	const [on, { toggleOff, toggleOn }] = useToggle(false);
	const countOfChildren = React.Children.count(children);

	if (countOfChildren !== stepNames.length) {
		throw Error("There is a mismatch between the number of step names and the amount of children");
	}

	const handleOnOpenModal = async () => {
		toggleOn();

		await event("OPEN_ONBOARDING_" + businessProfileType);
	};

	const handleOnCloseModal = async () => {
		toggleOff();

		await event("CLOSE_ONBOARDING_" + businessProfileType, {
			lastStepShown: stepNames[currentIndexActiveStep],
			lastStepShownIndex: currentIndexActiveStep,
			amountOfSteps: stepNames.length,
		});
	};

	const handleOnFinish = async () => {
		finishButton.onPress();

		await event("FINISH_ONBOARDING_" + businessProfileType);
	};

	return (
		<div className="z-50">
			{hasReachedTheMaximumCreatedProfiles ? (
				<span className="block w-full rounded-xl border border-solid border-neutral-200 py-2 text-center text-neutral-400">
					<FormattedMessage {...messages.maxCreatedProfiles} />
				</span>
			) : (
				<Button
					fluid
					onClick={handleOnOpenModal}
					businessProfileType={businessProfileType}
				>
					{modal.title}
				</Button>
			)}
			<Modal
				position="top-center"
				show={on}
				onClose={handleOnCloseModal}
				size="6xl"
			>
				<div className="flex items-start justify-between rounded-t px-4 pt-3">
					<button
						type="button"
						className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
						onClick={toggleOff}
					>
						<X className="h-6 w-6" />
						<span className="sr-only">Close modal</span>
					</button>
				</div>
				<Modal.Body style={{ padding: 0 }}>
					<div className="w-full border-b border-neutral-100">
						<OnboardingHeader
							stepsNames={stepNames}
							currentIndexActiveStep={currentIndexActiveStep}
						/>
					</div>
				</Modal.Body>
				<Modal.Body style={{ padding: 0 }}>
					<div className="max-h-[70vh] overflow-x-hidden overflow-y-scroll border-neutral-200 p-10">
						{React.Children.toArray(children)[currentIndexActiveStep]}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="flex w-full justify-end">
						<div className="flex w-96 justify-end gap-2">
							<OnboardingFooter
								currentIndexActiveStep={currentIndexActiveStep}
								totalNumberOfSteps={countOfChildren}
								cancelButton={{
									onPress: handleOnCloseModal,
								}}
								continueButton={continueButton}
								previousButton={previousButton}
								finishButton={{
									...finishButton,
									onPress: handleOnFinish,
								}}
								businessProfileType={businessProfileType}
							/>
						</div>
					</div>
				</Modal.Footer>
			</Modal>
		</div>
	);
};
