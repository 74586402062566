import { defineMessages } from "react-intl";

export default defineMessages({
	technicalSkills: {
		defaultMessage: "Technical skills",
	},
	stepDescription: {
		defaultMessage:
			"In order to give you the best experience inside the platform, we recommend you to select some of your technical skills that you feel suit best for you",
	},
	businessSkills: {
		defaultMessage: "Business skills",
	},
	businessSkillsTitleModal: {
		defaultMessage: "Select your business skills",
	},
	accountantSkills: {
		defaultMessage: "Accountant skills",
	},
	accountantSkillsTitleModal: {
		defaultMessage: "Select your accountant skills",
	},
	designAndProductSkills: {
		defaultMessage: "Design and product skills",
	},
	designAndProductSkillsTitleModal: {
		defaultMessage: "Select your design and product skills",
	},
	engineeringAndArchitectureSkills: {
		defaultMessage: "Engineering and architecture skills",
	},
	engineeringAndArchitectureSkillsTitleModal: {
		defaultMessage: "Select your engineering and architecture skills",
	},
	investmentSkills: {
		defaultMessage: "Investment skills",
	},
	investmentSkillsTitleModal: {
		defaultMessage: "Select your investment skills",
	},
	itSkills: {
		defaultMessage: "IT skills",
	},
	itSkillsTitleModal: {
		defaultMessage: "Select your IT skills",
	},
	legalSkills: {
		defaultMessage: "Legal skills",
	},
	legalSkillsTitleModal: {
		defaultMessage: "Select your legal skills",
	},
	salesAndMarketingSkills: {
		defaultMessage: "Sales and marketing skills",
	},
	salesAndMarketingSkillsTitleModal: {
		defaultMessage: "Select your sales and marketing skills",
	},
});
