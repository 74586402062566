import {Field} from "components/Forms/Field/Field";
import {FieldStyle} from "components/Forms/Field/typing";
import {MAX_LENGTH_TALENT_TITLE, MIN_LENGTH_TALENT_TITLE} from "constants/Format";

import {NewTalentTexts} from "i18n/containers/NewTalent";
import {NewTalentTranslation} from "i18n/containers/NewTalent/typing";
import {ValidationsMessageTexts} from "i18n/ValidationsMessage";
import {ValidationMessageTranslation} from "i18n/ValidationsMessage/typing";
import * as React from "react";
import {isLength} from "@utils";
import {useLocales} from "@libs";

const UnitChallengeTenderFieldTitle = (props: {
    title: string;
    validate: (validation: boolean) => void;
    onChange: (newTitle: string) => void;
}) => {
    const {title, validate, onChange} = props;
    const {currentShortLocale} = useLocales();
    const newTalentTranslation: NewTalentTranslation = {
        ...NewTalentTexts[currentShortLocale],
    };
    const validationsTranslations: ValidationMessageTranslation = {
        ...ValidationsMessageTexts[currentShortLocale],
    };
    const validateTitle = (valueToValidate: string): string => {
        const validation = isLength(valueToValidate, {
            min: MIN_LENGTH_TALENT_TITLE,
            max: MAX_LENGTH_TALENT_TITLE,
        })
            ? ""
            : validationsTranslations.checkMinimumAndMaximumCharacters(MIN_LENGTH_TALENT_TITLE, MAX_LENGTH_TALENT_TITLE);

        validate(!Boolean(validation.length));

        return validation;
    };

    return (
        <>
            <h3 className="font-color-grey">{newTalentTranslation.title}</h3>
            <Field
                type={{
                    style: FieldStyle.INPUT,
                    value: title,
                }}
                validate={validateTitle}
                onChange={onChange}
            />
        </>
    );
};

export default React.memo(UnitChallengeTenderFieldTitle);
