import { useState } from "react";

import { useUserProfileQuery } from "@api";
import { useDebounce } from "@libs";
import { useIntl } from "react-intl";
import messages from "./messages";

export const useOnboardingSlugChecker = () => {
	const { formatMessage } = useIntl();
	const [slug, setSlug] = useState("");
	const debouncedSearch = useDebounce(slug, 500);
	const { data, fetchStatus } = useUserProfileQuery(debouncedSearch, {
		enabled: Boolean(debouncedSearch),
	});

	const checkSlug = (slugToCheck: string) => {
		setSlug(slugToCheck);
	};

	return {
		checkSlug,
		slugAlreadyExist: Boolean(data),
		error: Boolean(data?.id) ? formatMessage(messages.profileAlreadyTaken) : undefined,
		isLoading: fetchStatus == "fetching",
	};
};
