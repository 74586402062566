import { useEffect, useReducer } from "react";

import { useOnboardingSlugChecker } from "./useOnboardingSlugChecker";
import dayjs from "dayjs";

export enum OnboardingActionType {
	CHANGE_GENDER = "CHANGE_GENDER",
	CHANGE_COUNTRY = "CHANGE_COUNTRY",
	CHANGE_BIRTHDATE = "CHANGE_BIRTHDATE",
	CHANGE_SLUG = "CHANGE_SLUG",
	CLEAN_ERRORS = "CLEAN_ERRORS",
	ERROR_SLUG = "ERROR_SLUG",
}

export type Gender = "MALE" | "FEMALE" | "OTHERS";

export interface OnboardingReducer {
	gender?: Gender;
	birthdate: Date;
	country: string;
	slug: {
		value: string;
		alreadyExist: boolean;
		isLoading: boolean;
	};
	errors: Record<string, string | undefined>;
}

const initialValues: OnboardingReducer = {
	gender: undefined,
	birthdate: dayjs().subtract(20, "years").toDate(),
	country: "",
	slug: {
		value: "",
		alreadyExist: false,
		isLoading: false,
	},
	errors: {},
};

const onboardingInfoReducer = (
	state: OnboardingReducer,
	action: { type: OnboardingActionType; payload?: any },
): OnboardingReducer => {
	const { type, payload } = action;

	switch (type) {
		case OnboardingActionType.CHANGE_GENDER:
			return { ...state, gender: payload as Gender };
		case OnboardingActionType.CHANGE_BIRTHDATE:
			return { ...state, birthdate: payload as Date };
		case OnboardingActionType.CHANGE_COUNTRY:
			return { ...state, country: payload as string };
		case OnboardingActionType.CHANGE_SLUG:
			return { ...state, slug: { ...state.slug, ...payload } };
		case OnboardingActionType.CLEAN_ERRORS:
			return { ...state, errors: { ...state.errors, [payload]: undefined } };
		case OnboardingActionType.ERROR_SLUG:
			return { ...state, errors: { ...state.errors, slug: payload } };
		default:
			return state;
	}
};

export const useOnboardingReducer = () => {
	const { slugAlreadyExist, checkSlug, error, isLoading } = useOnboardingSlugChecker();
	const [state, dispatch] = useReducer(onboardingInfoReducer, initialValues);

	useEffect(() => {
		dispatch({
			type: OnboardingActionType.CLEAN_ERRORS,
			payload: "slug",
		});
		dispatch({
			type: OnboardingActionType.CHANGE_SLUG,
			payload: {
				alreadyExist: slugAlreadyExist,
				isLoading: isLoading,
			},
		});

		if (error) {
			dispatch({
				type: OnboardingActionType.ERROR_SLUG,
				payload: error,
			});
		}
	}, [error, isLoading, slugAlreadyExist]);

	const changeGender = (gender: Gender) => {
		dispatch({
			type: OnboardingActionType.CLEAN_ERRORS,
			payload: "fullName",
		});
		dispatch({
			type: OnboardingActionType.CHANGE_GENDER,
			payload: gender,
		});
	};
	const changeBirthdate = (birthdate: Date) => {
		dispatch({
			type: OnboardingActionType.CLEAN_ERRORS,
			payload: "url",
		});
		dispatch({
			type: OnboardingActionType.CHANGE_BIRTHDATE,
			payload: birthdate,
		});
	};
	const changeCountry = (value: string) => dispatch({ type: OnboardingActionType.CHANGE_COUNTRY, payload: value });
	const changeSlug = (value: string) => {
		dispatch({
			type: OnboardingActionType.CHANGE_SLUG,
			payload: {
				value,
				alreadyExist: false,
				isLoading: true,
			},
		});

		checkSlug(value);
	};

	const isThereAnyError = (): boolean => {
		const errors = Object.values(state.errors).filter((error) => error != undefined);

		return Boolean(errors.length);
	};

	return {
		infoState: state,
		isThereAnyError,
		changeGender,
		changeBirthdate,
		changeCountry,
		changeSlug,
	};
};
