import React, { ChangeEventHandler, FocusEventHandler, useState } from "react";
import { useIntl } from "react-intl";

import { Filter, InputField } from "@common";

import messages from "../messages";

interface QuantityProps {
	onChangeQuantity: (min?: number, max?: number) => void;
	currentSelectedMarketplaceQuantity: {
		min?: number;
		max?: number;
	};
	filterIdsPrefix: string;
}

export const Quantity = ({ onChangeQuantity, currentSelectedMarketplaceQuantity, filterIdsPrefix }: QuantityProps) => {
	const { formatMessage } = useIntl();
	const { min, max } = currentSelectedMarketplaceQuantity;

	const onChangeMinQuantity: ChangeEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;

		onChangeQuantity(value ? Number(value) : undefined, max);
	};

	const onChangeMaxQuantity: ChangeEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;

		onChangeQuantity(min, value ? Number(value) : undefined);
	};

	const onBlurMaxQuantity: FocusEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;

		if (min && Number(value) < min) {
			onChangeQuantity(Number(value), max);
		}
	};

	const onBlurMinQuantity: FocusEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;

		if (max && Number(value) > max) {
			onChangeQuantity(min, Number(value));
		}
	};

	return (
		<Filter
			id="quantity"
			title={formatMessage(messages.quantity)}
		>
			<div className="flex items-center justify-center gap-4">
				<div>
					<InputField
						type="number"
						name={`${filterIdsPrefix}-min-quantity`}
						id={`${filterIdsPrefix}-min-quantity`}
						placeholder="0"
						required
						value={min}
						onChange={onChangeMinQuantity}
						onBlur={onBlurMinQuantity}
						labelValue={formatMessage(messages.min)}
					/>
				</div>
				<div>
					<InputField
						type="number"
						name={`${filterIdsPrefix}-max-quantity`}
						id={`${filterIdsPrefix}-max-quantity`}
						placeholder="60000"
						required
						value={max}
						onChange={onChangeMaxQuantity}
						onBlur={onBlurMaxQuantity}
						labelValue={formatMessage(messages.max)}
					/>
				</div>
			</div>
		</Filter>
	);
};
