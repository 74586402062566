import { defineMessages } from "react-intl";

export default defineMessages({
	title: {
		defaultMessage: "Universities & Vinglet",
	},
	subTitle: {
		defaultMessage:
			"Universities around the world working together with Vinglet in order to provide the best content and synergy for our community",
	},
});
