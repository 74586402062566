import * as React from "react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";


import { ChallengesTexts } from "i18n/components/Challenges/index";
import { ChallengesTranslation } from "i18n/components/Challenges/typing";
import Link from "next/link";
import {useLocales} from "@libs";

export enum TenderSettingsMenuItem {
	GENERAL = "general",
	IMAGES = "images",
	DOCUMENTS = "documents",
	TEAM = "team",
	STREAMING = "streaming",
	DELETE_TENDER = "deleteTender",
}

const TenderSettingsMenu = (props: { activeSection?: TenderSettingsMenuItem; tenderId: number }) => {
	const { tenderId } = props;
	const { currentShortLocale } = useLocales();
	const activeSection = props.activeSection ? props.activeSection : TenderSettingsMenuItem.GENERAL;
	const challengesTranslations: ChallengesTranslation = {
		...ChallengesTexts[currentShortLocale],
	};
	const getURLSettings = (item: TenderSettingsMenuItem) => {
		return `/tenders/${tenderId}/settings?activeSection=${item}`;
	};

	return (
		<Menu
			className="w-96 shrink-0"
			vertical
		>
			<Link
				href={getURLSettings(TenderSettingsMenuItem.GENERAL)}
				passHref
			>
				<Menu.Item
					as="a"
					name={TenderSettingsMenuItem.GENERAL}
					active={activeSection === TenderSettingsMenuItem.GENERAL}
				>
					<Icon name="table" />
					{challengesTranslations.general}
				</Menu.Item>
			</Link>

			<Link
				href={getURLSettings(TenderSettingsMenuItem.IMAGES)}
				passHref
			>
				<Menu.Item
					as="a"
					name={TenderSettingsMenuItem.IMAGES}
					active={activeSection === TenderSettingsMenuItem.IMAGES}
				>
					<Icon name="image outline" />
					{challengesTranslations.imagenes}
				</Menu.Item>
			</Link>

			<Link
				href={getURLSettings(TenderSettingsMenuItem.DOCUMENTS)}
				passHref
			>
				<Menu.Item
					as="a"
					name={TenderSettingsMenuItem.DOCUMENTS}
					active={activeSection === TenderSettingsMenuItem.DOCUMENTS}
				>
					<Icon name="attach" />
					{challengesTranslations.documents}
				</Menu.Item>
			</Link>

			<Link
				href={getURLSettings(TenderSettingsMenuItem.STREAMING)}
				passHref
			>
				<Menu.Item
					as="a"
					name={TenderSettingsMenuItem.STREAMING}
					active={activeSection === TenderSettingsMenuItem.STREAMING}
				>
					<Icon name="video" />
					Streaming
				</Menu.Item>
			</Link>

			<Link
				href={getURLSettings(TenderSettingsMenuItem.DELETE_TENDER)}
				passHref
			>
				<Menu.Item
					as="a"
					name={TenderSettingsMenuItem.DELETE_TENDER}
					color={"red"}
					active={activeSection === TenderSettingsMenuItem.DELETE_TENDER}
				>
					<Icon name="trash alternate" />
					{challengesTranslations.deleteProposal}
				</Menu.Item>
			</Link>
		</Menu>
	);
};

export default TenderSettingsMenu;
