import { useIntl } from "react-intl";

import { Filter, InputCheckboxField, ShowMoreToggle } from "@common";

import CategoryModel from "models/categories/Category";

import messages from "../messages";

type CategoriesCounter = number;

interface SectorsProps {
	sectors: [CategoryModel, CategoriesCounter][];
	sectorsIdsClicked: Record<number, boolean>;
	onChangeOneSector: (categoryId: number) => void;
	filterIdsPrefix: string;
}

export const Sectors = ({ sectors, sectorsIdsClicked, onChangeOneSector, filterIdsPrefix }: SectorsProps) => {
	const { formatMessage } = useIntl();

	return (
		<Filter
			id="sectors"
			title={formatMessage(messages.sectors)}
		>
			<ShowMoreToggle
				initialValue={false}
				className="flex flex-col gap-2"
				items={sectors.map(([sector, counter]) => {
					const handleOnChangeSector = () => onChangeOneSector(Number(sector.id));

					return (
						<InputCheckboxField
							key={sector.key}
							id={filterIdsPrefix + String(sector.id)}
							name={sector.key}
							checked={sectorsIdsClicked[sector.id as number]}
							onChange={handleOnChangeSector}
							labelValue={
								<p className="flex w-full">
									<span className="flex-1"> {sector.text} </span>
									<span className="shrink-0 text-neutral-400"> ({counter}) </span>
								</p>
							}
						/>
					);
				})}
			/>
		</Filter>
	);
};
