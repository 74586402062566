import { useState } from "react";
import classNames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import { useRouter } from "next/router";
import Image from "next/image";

import { getListFromStringifiedList } from "@libs";

import CategoryModel from "models/categories/Category";

import { StartupsExplorerFilters, StartupsFilters } from "./StartupsExplorerFilters/StartupsExplorerFilters";
import { StartupsExplorerList } from "./StartupsExplorerList/StartupsExplorerList";
import { StartupsExplorerFiltersModal } from "./StartupsExplorerFiltersModal/StartupsExplorerFiltersModal";

import messages from "./messages";

type CategoriesCounter = number;

export interface StartupsExplorerProps {
	topSectors: [CategoryModel, CategoriesCounter][];
	topRegions: [CategoryModel, CategoriesCounter][];
	initialPageNumber: number;
}

export const StartupsExplorer = ({ topRegions, topSectors, initialPageNumber }: StartupsExplorerProps) => {
	const router = useRouter();
	const { formatMessage } = useIntl();
	const { regionsIds, marketsIds } = router.query;
	const [filters, setFilters] = useState<StartupsFilters>({
		sectorsIds: getListFromStringifiedList(marketsIds as string),
		regionsIds: getListFromStringifiedList(regionsIds as string),
		pageNumber: initialPageNumber,
	});

	const onChangeFilters = (newFilters: Omit<StartupsFilters, "pageNumber" | "elearningTypeSearch">) => {
		setFilters(() => ({
			...newFilters,
			pageNumber: 0,
		}));
	};

	return (
		<>
			<div className="z-index-99 fixed bottom-0 w-full border-t border-gray-300 bg-white py-8 px-4 lg:hidden">
				<StartupsExplorerFiltersModal
					topSectors={topSectors}
					topRegions={topRegions}
					onChangeFilters={onChangeFilters}
				/>
			</div>
			<main className="min-h-full w-full pb-36">
				<div
					className={classNames("w-full py-16 text-center lg:py-28 lg:text-left", "px-4 lg:px-10 xl:px-20 2xl:px-44")}
				>
					<div className="flex items-center justify-around">
						<div>
							<h1 className="text-5xl font-extrabold text-gray-900">
								<FormattedMessage {...messages.title} />
							</h1>
							<p className="mt-4 mb-8 text-lg text-gray-600">
								<FormattedMessage {...messages.subTitle} />
							</p>
						</div>
						<div className="hidden lg:block">
							<div className="relative mx-auto w-96">
								<Image
									className="object-cover"
									alt={formatMessage(messages.title)}
									src="/startups/man-creating-startup.svg"
									layout="responsive"
									width="100%"
									height="100%"
								/>
							</div>
						</div>
					</div>
					<section className="mt-10 py-6 lg:py-20">
						<div className="flex items-stretch justify-between">
							<aside className="hidden h-full w-80 shrink-0 lg:block">
								<StartupsExplorerFilters
									topSectors={topSectors}
									topRegions={topRegions}
									onChangeFilters={onChangeFilters}
									filtersIdsPrefix={{
										sectors: "sectors",
										regions: "regions",
									}}
								/>
							</aside>
							<section className="flex-1 pl-0 lg:pl-20">
								<StartupsExplorerList filters={filters} />
							</section>
						</div>
					</section>
				</div>
			</main>
		</>
	);
};
