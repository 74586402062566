import * as React from "react";
import { FormattedMessage } from "react-intl";

import { fetchDocument } from "@api";
import { useToggle } from "@libs";
import { Button, InternalLink, PencilSquare } from "@common";

import ChallengeTenderModel from "models/challenge-tenders/ChallengeTender";

import messages from "./messages";
import { useMentorsPermissionToDownloadDocuments } from "./useMentorsPermissionToDownloadDocuments";
import { useUsersPermissionToDownloadDocuments } from "./useUsersPermissionToDownloadDocuments";

interface TenderDescriptionProps {
	tender: ChallengeTenderModel;
}

export const TenderDocuments = ({ tender }: TenderDescriptionProps) => {
	const [isDownloadingDocuments, { toggleOn, toggleOff }] = useToggle(false);
	const { hasPermission: hasMentorPermission, isLoading: isMentorPermissionLoading } =
		useMentorsPermissionToDownloadDocuments(tender.id, { enabled: !tender.isMine });
	const { hasPermission: hasUserPermission, isLoading: isUserPermissionLoading } =
		useUsersPermissionToDownloadDocuments(tender.id, { enabled: !tender.isMine });

	const downloadAllDocuments = () => {
		const documentsPromises = tender.documents.map((document) => fetchDocument(document)());

		toggleOn();

		Promise.all(documentsPromises).then(toggleOff).catch(toggleOff);
	};
	const noDocuments = tender.documents.length === 0;

	if (!tender.isMine && noDocuments) {
		return <></>;
	}

	const getDownloadDocumentsButton = () => {
		if (noDocuments) {
			return (
				<Button
					color="dark"
					disabled
				>
					<FormattedMessage {...messages.noDocumentsYet} />
				</Button>
			);
		}

		if (tender.isMine || hasMentorPermission || hasUserPermission) {
			return (
				<Button
					color="purple"
					onClick={downloadAllDocuments}
					loading={isDownloadingDocuments}
				>
					<FormattedMessage {...messages.downloadAllDocuments} />
				</Button>
			);
		}

		if (isMentorPermissionLoading || isUserPermissionLoading) {
			return (
				<Button
					color="purple"
					loading
				>
					<FormattedMessage {...messages.downloadAllDocuments} />
				</Button>
			);
		}

		return (
			<Button
				color="dark"
				disabled
			>
				<FormattedMessage {...messages.youDontHavePermissionToDownloadDocuments} />
			</Button>
		);
	};

	return (
		<>
			<h5 className="text-lg">
				<FormattedMessage {...messages.documents} />
			</h5>
			<div className="flex w-full items-center gap-4">
				{getDownloadDocumentsButton()}
				{tender.isMine && (
					<InternalLink href={`/tenders/${tender.id}/settings?activeSection=documents`}>
						<Button
							color="gray"
							loading={isDownloadingDocuments}
						>
							<PencilSquare className="h-6 w-6" />
						</Button>
					</InternalLink>
				)}
			</div>
		</>
	);
};
