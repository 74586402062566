import * as React from "react";
import { FormattedMessage } from "react-intl";

import { Card } from "@common";

import ChallengeTenderModel, { ChallengeTenderSocialType } from "models/challenge-tenders/ChallengeTender";

import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

import messages from "./messages";

interface TenderNetworksProps {
	tender: ChallengeTenderModel;
}

export const TenderNetworks = ({ tender }: TenderNetworksProps) => {
	const googleMeet = tender.socialNetwork[ChallengeTenderSocialType.GOOGLE_MEET];
	const microsoftTeams = tender.socialNetwork[ChallengeTenderSocialType.MICROSOFT_TEAM];
	const zoom = tender.socialNetwork[ChallengeTenderSocialType.ZOOM];
	const trello = tender.socialNetwork[ChallengeTenderSocialType.TRELLO];

	const noNetworks = !googleMeet && !microsoftTeams && !zoom && !trello;

	if (!tender.isMine && noNetworks) {
		return <></>;
	}

	return (
		<Card className="w-full max-w-none p-20">
			<h5 className="text-lg">
				<FormattedMessage {...messages.networkingPlatforms} />
			</h5>

			{noNetworks && (
				<p className="mt-4 text-gray-500">
					<FormattedMessage {...messages.thereAreNoNetworkingPlatforms} />
				</p>
			)}

			<div className="flex items-center">
				{googleMeet && (
					<Button
						as="a"
						rel="noopener noreferrer nofollow"
						href={googleMeet}
						color="google plus"
					>
						<Icon name="google plus" /> Google Meet
					</Button>
				)}
				{microsoftTeams && (
					<Button
						as="a"
						rel="noopener noreferrer nofollow"
						href={microsoftTeams}
						color="violet"
					>
						<Icon name="microsoft" /> Microsoft Teams
					</Button>
				)}
				{zoom && (
					<Button
						as="a"
						rel="noopener noreferrer nofollow"
						href={zoom}
						color="blue"
					>
						<Icon name="video camera" /> Zoom
					</Button>
				)}
				{trello && (
					<Button
						as="a"
						rel="noopener noreferrer nofollow"
						href={trello}
						color="vk"
					>
						<Icon name="trello" /> Trello
					</Button>
				)}
			</div>
		</Card>
	);
};
