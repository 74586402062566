import { defineMessages } from "react-intl";

export default defineMessages({
	findYourPerfectElearning: {
		defaultMessage: "Find your perfect elearning",
	},
	youCanAccessALotOfCourses: {
		defaultMessage: "You can access to a lot of different courses from the most prestigious universities",
	},
});
