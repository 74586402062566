import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useTenderTalentsQuery } from "@api";

import ChallengeTenderModel from "models/challenge-tenders/ChallengeTender";
import TalentModel from "models/talents/Talent";

import { TeamUser } from "./TeamUser/TeamUser";
import { TeamUserLoading } from "./TeamUser/TeamUserLoading";
import { HiredPeopleFromTalent } from "./HiredPeopleFromTalent";
import messages from "./messages";

interface TenderTeamProps {
	tender: ChallengeTenderModel;
}

export const TenderTeam = ({ tender }: TenderTeamProps) => {
	const { formatMessage } = useIntl();
	const { data: tenderTalents, isLoading: isTenderTalentsLoading } = useTenderTalentsQuery(tender.id);
	const profileAdminModel = tender.admin;

	const tenderTalentsModels = tenderTalents?.map((tenderTalent) => TalentModel.generateFromAPI(tenderTalent)) || [];

	return (
		<section className="mt-16">
			<h2 className="text-2xl">
				<FormattedMessage {...messages.knowTheTeam} />
			</h2>
			<div className="grid grid-cols-1 items-stretch gap-4 lg:grid-cols-3">
				{profileAdminModel.info.avatar ? (
					<TeamUser
						key={profileAdminModel.links.link}
						profileLink={profileAdminModel.links.link}
						profileImage={profileAdminModel.info.avatar}
						profileFullName={`${profileAdminModel.info.name} ${profileAdminModel.info.surname}`}
						profileEmblem={profileAdminModel.info.emblem}
						rolName={formatMessage(messages.admin)}
					/>
				) : (
					<TeamUserLoading />
				)}
				{isTenderTalentsLoading
					? [1, 2].map((_, index) => <TeamUserLoading key={index} />)
					: tenderTalentsModels.map((talent) => (
							<HiredPeopleFromTalent
								key={talent.id}
								talentId={Number(talent.id)}
								talentTitle={talent.title}
							/>
					  ))}
			</div>
		</section>
	);
};
