import { defineMessages } from "react-intl";

export default defineMessages({
	type: {
		defaultMessage: "Type of business profile",
	},
	sectors: {
		defaultMessage: "Sectors",
	},
	regions: {
		defaultMessage: "Regions",
	},
	min: {
		defaultMessage: "Minimum",
	},
	max: {
		defaultMessage: "Maximum",
	},
	averageInvestment: {
		defaultMessage: "Average investment",
	},
	averageEquity: {
		defaultMessage: "Average Equity",
	},
	numberOfStartupsToInvest: {
		defaultMessage: "Number of startups to invest",
	},
	languages: {
		defaultMessage: "Languages",
	},
});
