import { defineMessages } from "react-intl";

export default defineMessages({
	editImages: {
		defaultMessage: "Edit images",
	},
	missingImages: {
		defaultMessage: "This proposal has no images",
	},
});
