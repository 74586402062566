import { defineMessages } from "react-intl";

export default defineMessages({
	incorrectCredentials: {
		defaultMessage: "The credentials you have sent are incorrect",
	},
	somethingWentWrong: {
		defaultMessage: "Something went wrong, try again later",
	},
	emailBadFormatted: {
		defaultMessage: "The email that you have introduced is wrong or bad formatted",
	},
	signUp: {
		defaultMessage: "Sign up",
	},
	signIn: {
		defaultMessage: "Sign in",
	},
	signInToYourAccount: {
		defaultMessage: "Sign in to your account",
	},
	undertakeRiskFree: {
		defaultMessage:
			"Undertake risk-free, search for talent, train, validate, sell and attract the investor's attention",
	},
	yourEmail: {
		defaultMessage: "Your email",
	},
	password: {
		defaultMessage: "Password",
	},
	forgotPassword: {
		defaultMessage: "Forgot password?",
	},
	doNotHaveAnAccountYet: {
		defaultMessage: "Don’t have an account yet? {signUp}",
	},
});
