import { useState } from "react";
import classNames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import { useRouter } from "next/router";
import Image from "next/image";

import { TalentTypes } from "@api";
import { getListFromStringifiedList } from "@libs";

import CategoryModel from "models/categories/Category";

import { AdvisersExplorerList } from "./AdvisersExplorerList/AdvisersExplorerList";
import { TalentsExplorerFilters, TalentsFilters } from "./TalentsExplorerFilters/TalentsExplorerFilters";
import { TalentsExplorerList } from "./TalentsExplorerList/TalentsExplorerList";
import { TalentsExplorerFiltersModal } from "./TalentsExplorerFiltersModal/TalentsExplorerFiltersModal";

import messages from "./messages";

type CategoriesCounter = number;

export interface TalentsExplorerProps {
	topSectors: [CategoryModel, CategoriesCounter][];
	topRegions: [CategoryModel, CategoriesCounter][];
	topLanguages: [CategoryModel, CategoriesCounter][];
	topSchools: [CategoryModel, CategoriesCounter][];
	topCountries: [CategoryModel, CategoriesCounter][];
	initialPageNumber: number;
}

export const TalentsExplorer = ({
	topRegions,
	topSectors,
	topLanguages,
	topSchools,
	topCountries,
	initialPageNumber,
}: TalentsExplorerProps) => {
	const router = useRouter();
	const { formatMessage } = useIntl();
	const {
		talentType = TalentTypes.JOBS,
		sectorsIds,
		regionsIds,
		languagesIds,
		schoolsIds,
		countriesIds,
		minAverageSalary,
		maxAverageSalary,
		minAverageEquity,
		maxAverageEquity,
		minCostPerHour,
		maxCostPerHour,
	} = router.query;
	const [filters, setFilters] = useState<TalentsFilters>({
		talentType: talentType as TalentTypes,
		sectorsIds: getListFromStringifiedList(sectorsIds as string),
		regionsIds: getListFromStringifiedList(regionsIds as string),
		languagesIds: getListFromStringifiedList(languagesIds as string),
		schoolsIds: getListFromStringifiedList(schoolsIds as string),
		countriesIds: getListFromStringifiedList(countriesIds as string),
		averageSalary: {
			min: Number.isInteger(minAverageSalary) ? Number(minAverageSalary) : undefined,
			max: Number.isInteger(maxAverageSalary) ? Number(maxAverageSalary) : undefined,
			__TYPE__: "AverageSalary",
		},
		averageEquity: {
			min: Number.isInteger(minAverageEquity) ? Number(minAverageEquity) : undefined,
			max: Number.isInteger(maxAverageEquity) ? Number(maxAverageEquity) : undefined,
			__TYPE__: "AverageEquity",
		},
		costPerHour: {
			min: Number.isInteger(minCostPerHour) ? Number(minCostPerHour) : undefined,
			max: Number.isInteger(maxCostPerHour) ? Number(maxCostPerHour) : undefined,
			__TYPE__: "CostPerHour",
		},
		pageNumber: initialPageNumber,
	});

	const onChangeFilters = (newFilters: Omit<TalentsFilters, "pageNumber">) => {
		setFilters(() => ({
			...newFilters,
			pageNumber: 0,
		}));
	};

	return (
		<>
			<div className="z-index-99 fixed bottom-0 w-full border-t border-gray-300 bg-white py-8 px-4 lg:hidden">
				<TalentsExplorerFiltersModal
					topSectors={topSectors}
					topRegions={topRegions}
					topLanguages={topLanguages}
					topSchools={topSchools}
					topCountries={topCountries}
					onChangeFilters={onChangeFilters}
				/>
			</div>
			<main className="min-h-full w-full pb-36">
				<div
					className={classNames("w-full py-16 text-center lg:py-28 lg:text-left", "px-4 lg:px-10 xl:px-20 2xl:px-44")}
				>
					<div className="flex items-center justify-around">
						<div>
							<h1 className="text-5xl font-extrabold text-gray-900">
								<FormattedMessage {...messages.title} />
							</h1>
							<p className="mt-4 mb-8 text-lg text-gray-600">
								<FormattedMessage {...messages.subTitle} />
							</p>
						</div>
						<div className="hidden lg:block">
							<div className="relative mx-auto w-96">
								<Image
									className="object-cover"
									alt={formatMessage(messages.title)}
									src="/talents/person-looking-for-a-job.svg"
									layout="responsive"
									width="100%"
									height="100%"
								/>
							</div>
						</div>
					</div>
					<section className="mt-10 py-6 lg:py-20">
						<div className="flex items-stretch justify-between">
							<aside className="hidden h-full w-80 shrink-0 lg:block">
								<TalentsExplorerFilters
									topSectors={topSectors}
									topRegions={topRegions}
									topLanguages={topLanguages}
									topSchools={topSchools}
									topCountries={topCountries}
									onChangeFilters={onChangeFilters}
									filtersIdsPrefix={{
										talentType: "talentType",
										sectors: "sectors",
										regions: "regions",
										languages: "languages",
										schools: "schools",
										countries: "countries",
										averageSalary: "averageSalary",
										averageEquity: "averageEquity",
										costPerHour: "costPerHour",
									}}
								/>
							</aside>
							<section className="flex-1 pl-0 lg:pl-20">
								{filters.talentType === TalentTypes.ADVISER ? (
									<AdvisersExplorerList filters={filters} />
								) : (
									<TalentsExplorerList filters={filters} />
								)}
							</section>
						</div>
					</section>
				</div>
			</main>
		</>
	);
};
