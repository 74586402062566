import * as React from "react";
import { Badge } from "flowbite-react";
import { FormattedMessage } from "react-intl";
import Image from "next/image";

import { Beaker, Card, InternalLink, LocationMarker } from "@common";

import TalentModel from "models/talents/Talent";

import messages from "./messages";
import {useLocales} from "@libs";

type TalentCardProps = TalentModel;

const MAX_CATEGORIES_PER_FEATURE = 5;

export const TalentCard = ({
	title,
	getInternalURL,
	getOwnerAvatarSrc,
	getOwnerFullName,
	requirements: { salary, minEquity, maxEquity },
	info: { country, location },
	feature: { sectors },
}: TalentCardProps) => {
	const formatterSalary = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: 0,
	});
	const {currentShortLocale} = useLocales();

	return (
		<Card className="relative h-full w-full max-w-none">
			<div className="mb-5 flex">
				<InternalLink
					href={getInternalURL(currentShortLocale)}
					className="relative block aspect-square h-16 w-16 shrink-0 overflow-hidden rounded-xl border border-gray-100 bg-white shadow-xl"
				>
					<Image
						className="w-full rounded-t-lg"
						src={getOwnerAvatarSrc()}
						alt={title}
						layout="fill"
					/>
				</InternalLink>
				<div className="flex w-full flex-col justify-center pl-4 text-left">
					<h4 className="mb-0 text-xl capitalize tracking-tight text-gray-900 line-clamp-1 dark:text-white">
						{getOwnerFullName()}
					</h4>
					<div className="flex items-center gap-0.5">
						<label className="text-neutral-500">
							<LocationMarker className="h-4 w-4" />
						</label>
						<span className="text-neutral-500">
							{country.text}, {location}
						</span>
					</div>
				</div>
			</div>
			<InternalLink href={getInternalURL(currentShortLocale)}>
				<h5 className="text-left text-lg capitalize tracking-tight text-gray-900 line-clamp-1 hover:text-blue-600 dark:text-white">
					{title}
				</h5>
			</InternalLink>
			<ul className="mt-4 mb-2">
				<li className="mb-1 flex gap-2">
					<label className="text-neutral-500">
						<FormattedMessage {...messages.grossSalary} />:
					</label>
					<span className="font-semibold text-violet-600">{formatterSalary.format(salary)}</span>
				</li>
				<li className="flex gap-2">
					<label className="text-neutral-500">
						<FormattedMessage {...messages.equity} />:
					</label>
					<span className="font-semibold text-emerald-500">
						{minEquity}% - {maxEquity}%
					</span>
				</li>
				<li className="mt-6 flex gap-2">
					<Beaker className="h-6 w-6 shrink-0" />
					<div className="flex flex-wrap gap-2">
						{sectors.slice(0, MAX_CATEGORIES_PER_FEATURE).map((sector) => (
							<Badge key={sector.key}>{sector.text}</Badge>
						))}
						{sectors.length > MAX_CATEGORIES_PER_FEATURE && (
							<span className="text-sm font-semibold"> +{sectors.length - MAX_CATEGORIES_PER_FEATURE} </span>
						)}
					</div>
				</li>
			</ul>
		</Card>
	);
};
