import {Image} from "elements/HeroImage/typing";
import ImagesPickers from "elements/ImagesPicker/ImagesPicker";

import {NewMarketplaceTexts} from "i18n/containers/NewMarketplace";
import {NewElearningTexts} from "i18n/containers/NewElearning";
import {NewMarketplaceTranslation} from "i18n/containers/NewMarketplace/typing";
import * as React from "react";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import {NewElearningTranslation} from "i18n/containers/NewElearning/typing";
import {useLocales} from "@libs";

const MAX_IMAGES_TO_BE_SELECTED = 5;

const UnitChallengeTenderFieldImages = (props: { images: Image[]; onChange: (image: Image[]) => void }) => {
    const {images, onChange} = props;
    const {currentShortLocale} = useLocales();
    const newMarketplaceTranslation: NewMarketplaceTranslation = {
        ...NewMarketplaceTexts[currentShortLocale],
    };
    const newElearningTranslation: NewElearningTranslation = {
        ...NewElearningTexts[currentShortLocale],
    };

    return (
        <Form.Field className="margin-2-bottom">
            <h3 className="font-color-grey">{newMarketplaceTranslation.backgroundImagePlaceholder}</h3>
            <p> {newElearningTranslation.maxImagesDescription} </p>

            <p> {newElearningTranslation.maxImagesSubDescription} </p>
            <ImagesPickers
                images={images}
                maxImagesToBeSelected={MAX_IMAGES_TO_BE_SELECTED}
                dimmeable
                imagePickerProps={{
                    className: "margin-0-top",
                    labelTextButton: newMarketplaceTranslation.changeBackgroundImage,
                    basic: true,
                }}
                onChange={onChange}
            />
        </Form.Field>
    );
};

export default React.memo(UnitChallengeTenderFieldImages);
