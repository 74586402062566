import { defineMessages } from "react-intl";

export default defineMessages({
	acceptTermsAndConditions: {
		defaultMessage: "I accept the {terms}",
	},
	termsAndConditions: {
		defaultMessage: "Terms and Conditions",
	},
	fullName: {
		defaultMessage: "Fullname",
	},
	country: {
		defaultMessage: "Country",
	},
	websiteUrl: {
		defaultMessage: "Website URL",
	},
	selectACountry: {
		defaultMessage: "Select a country",
	},
	profileURL: {
		defaultMessage: "Profile's URL",
	},
	vingletConsent: {
		defaultMessage: "I consent that Vinglet may use my advisor data for certain features of the platform",
	},
});
