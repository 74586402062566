import { defineMessages } from "react-intl";

export default defineMessages({
	costPerHour: {
		defaultMessage: "Cost per hour",
	},
	equity: {
		defaultMessage: "Equity",
	},
});
