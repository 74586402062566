import { defineMessages } from "react-intl";

import { BusinessProfileTypes, InvestorTypes } from "@api";

export const investorTypesMessages = defineMessages<InvestorTypes | "ALL">({
	[BusinessProfileTypes.BUSINESS_ANGEL]: {
		defaultMessage: "Business Angel",
	},
	[BusinessProfileTypes.INCUBATOR]: {
		defaultMessage: "Incubator",
	},
	[BusinessProfileTypes.ACCELERATOR]: {
		defaultMessage: "Accelerator",
	},
	ALL: {
		defaultMessage: "All",
	},
});
