import { useContext } from "react";

import {TenderMentor, useTenderMentorsQuery} from "@api";
import { UserProfileContext } from "@context";
import {UseQueryOptions} from "@tanstack/react-query";

export const useMentorsPermissionToDownloadDocuments = (tenderId: number, options: UseQueryOptions<TenderMentor[]> = {}) => {
	const { data: tenderMentors, isLoading } = useTenderMentorsQuery(tenderId);
	const { businessProfiles } = useContext(UserProfileContext);

	const myMentor = businessProfiles.find((businessProfiles) => businessProfiles.info.type === "adviser");
	const myMentorId = myMentor?.info.id;
	const tenderMentorsIds: number[] = tenderMentors?.map((mentor) => mentor.consultant_profile.id) || [];

	return {
		isLoading: isLoading,
		hasPermission: myMentorId && tenderMentorsIds.includes(myMentorId),
	};
};
