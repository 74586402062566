import * as React from "react";

import { useBusinessProfileQuery } from "@api";

import BusinessProfileModel from "models/business-profiles/BusinessProfile";

import { ProfileInvolved } from "./ProfileInvolved";
import { ProfileInvolvedLoading } from "./ProfileInvolvedLoading";

interface InvitedProfileInvolvedProps {
	invitedProfileSlug: string;
}

export const InvitedProfileInvolved = ({ invitedProfileSlug }: InvitedProfileInvolvedProps) => {
	const { data: invitedProfile, isLoading: isInvitedProfileLoading } = useBusinessProfileQuery(invitedProfileSlug);

	const invitedProfileModel = invitedProfile && BusinessProfileModel.generateFromAPI(invitedProfile);

	if (isInvitedProfileLoading) {
		return <ProfileInvolvedLoading />;
	}

	if (!invitedProfileModel) {
		return <></>;
	}

	return <ProfileInvolved businessProfileModel={invitedProfileModel} />;
};
