import * as React from "react";
import { FormattedMessage } from "react-intl";

import { TeamUserLoading } from "../TeamUser/TeamUserLoading";

import messages from "./messages";

export const TenderMentorsLoading = () => {
	return (
		<section className="margin-4-top margin-3-bottom">
			<h5 className="text-lg">
				<FormattedMessage {...messages.knowTheMentors} />
			</h5>
			<div className="grid grid-cols-2 items-center gap-4 sm:grid-cols-3 lg:grid-cols-6">
				{[1, 2, 3, 4].map((_, index) => (
					<TeamUserLoading key={index} />
				))}
			</div>
		</section>
	);
};
