import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useTenderMentorsQuery } from "@api";
import { Button, InternalLink } from "@common";

import { MicroBusinessProfileTypes } from "constants/Profiles";
import ChallengeTenderModel from "models/challenge-tenders/ChallengeTender";
import BusinessProfileModel from "models/business-profiles/BusinessProfile";

import { TeamUser } from "../TeamUser/TeamUser";
import { TenderMentorsLoading } from "./TenderMentorsLoading";
import messages from "./messages";

interface TenderMentorsProps {
	tender: ChallengeTenderModel;
}

export const TenderMentors = ({ tender }: TenderMentorsProps) => {
	const { formatMessage } = useIntl();
	const { data: mentors, isLoading } = useTenderMentorsQuery(tender.id);

	if (isLoading) {
		return <TenderMentorsLoading />;
	}

	if (!mentors) {
		return <></>;
	}

	const mentorsModels = mentors.map((mentor) => {
		return BusinessProfileModel.generateMiniFromAPI({
			...mentor.consultant_profile,
			type: MicroBusinessProfileTypes.ADVISER,
		});
	});

	return (
		<section className="mt-10 mb-16">
			<h5 className="text-lg">
				<FormattedMessage {...messages.knowTheMentors} />
			</h5>
			<div className="grid grid-cols-2 items-center gap-4 sm:grid-cols-3 lg:grid-cols-6">
				{mentorsModels.map((mentor) => (
					<TeamUser
						key={mentor.info.url}
						profileLink={mentor.info.url}
						profileImage={mentor.info.avatar.src}
						profileFullName={mentor.info.fullName}
						rolName={formatMessage(messages.mentor)}
					/>
				))}
				<Button color="gray">
					<InternalLink href={`/tenders/${tender.id}/team`}>
						<FormattedMessage {...messages.seeMore} />
					</InternalLink>
				</Button>
			</div>
		</section>
	);
};
