import classNames from "classnames";
import { Pagination } from "flowbite-react";
import { useRouter } from "next/router";
import queryString from "query-string";

import { getStringifiedList, useDebounce } from "@libs";
import { FetchInvestorsRequestParams, useInvestorsQuery } from "@api";

import BusinessProfileModel from "models/business-profiles/BusinessProfile";

import { PostulateCardLoading } from "../../PostulateCard/PostulateCardLoading";
import { PostulateCard } from "../../PostulateCard/PostulateCard";
import { PostulateFilters } from "../PostulateExplorerFilters/PostulateExplorerFilters";

export interface PostulateExplorerListProps {
	filters: PostulateFilters;
}

export const PostulateExplorerList = ({
	filters: {
		investorType,
		regionsIds,
		sectorsIds,
		averageInvestment,
		averageEquity,
		numberOfStartupsToInvest,
		languagesIds,
		pageNumber,
	},
}: PostulateExplorerListProps) => {
	const router = useRouter();
	const debouncedQueryParams = useDebounce(
		{
			page: pageNumber ? pageNumber : 1,
			perPage: 12,
			"search[investorType]": investorType,
			"search[regions]": regionsIds,
			"search[sectors]": sectorsIds,
			averageInvestment: {
				"search[min_averageInvestment]": averageInvestment.min,
				"search[max_averageInvestment]": averageInvestment.max,
			},
			averageEquity: {
				"search[min_averageInvestmentPercentage]": averageEquity.min,
				"search[max_averageInvestmentPercentage]": averageEquity.max,
			},
			numberOfStartupsToInvest: {
				"search[min_numberOfStartupsToInvest]": numberOfStartupsToInvest.min,
				"search[max_numberOfStartupsToInvest]": numberOfStartupsToInvest.max,
			},
			"search[languages]": languagesIds,
		} as FetchInvestorsRequestParams,
		1000,
	);
	const { data: investors, isLoading } = useInvestorsQuery(debouncedQueryParams, {
		enabled: Boolean(debouncedQueryParams),
	});

	const investorsModels = investors?.items.map((investor) => BusinessProfileModel.generateFromAPI(investor));

	const handleOnPageChange = async (newPageNumber: number) => {
		await router.push(
			router.pathname +
				`?page=${newPageNumber}&${queryString.stringify(
					{
						investorType,
						sectorsIds: getStringifiedList(sectorsIds),
						regionsIds: getStringifiedList(regionsIds),
						minAverageInvestment: averageInvestment.min,
						maxAverageInvestment: averageInvestment.max,
						minAverageEquity: averageEquity.min,
						maxAverageEquity: averageEquity.max,
						minNumberOfStartupsToInvest: numberOfStartupsToInvest.min,
						maxNumberOfStartupsToInvest: numberOfStartupsToInvest.max,
						languagesIds: getStringifiedList(languagesIds),
					},
					{
						skipEmptyString: true,
						skipNull: true,
					},
				)}`,
		);
	};

	const getTotalPages = () => (investors?.num_pages ? investors?.num_pages : 0);

	return (
		<>
			<ul
				className={classNames(
					"w-full text-center",
					"grid gap-4",
					"grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4",
				)}
			>
				{isLoading
					? [1, 2, 3, 4, 5, 6, 7, 8].map((index) => <PostulateCardLoading key={index} />)
					: investorsModels?.map((investor) => (
							<li
								key={investor.info.id}
								className="h-full w-full flex-1"
								data-testid={`INVESTOR_${investor.info.id}`}
							>
								<PostulateCard {...investor} />
							</li>
					  ))}
			</ul>
			{getTotalPages() > 1 && (
				<div className="mt-24 w-full text-center">
					<Pagination
						currentPage={pageNumber}
						layout={"pagination"}
						totalPages={getTotalPages()}
						onPageChange={handleOnPageChange}
					/>
				</div>
			)}
		</>
	);
};
