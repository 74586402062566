import { defineMessages } from "react-intl";

export default defineMessages({
	adminBy: {
		defaultMessage: "Admin by {administrator}",
	},
	readMore: {
		defaultMessage: "Read more",
	},
});
