import classNames from "classnames";
import { Pagination } from "flowbite-react";
import { useRouter } from "next/router";
import queryString from "query-string";

import { getStringifiedList, useDebounce } from "@libs";
import { CategoryType, FetchTalentsRequestParams, useTalentsQuery } from "@api";

import TalentModel from "models/talents/Talent";
import CategoriesServiceSingleton from "services/categories/CategoriesService";

import { TalentCardLoading } from "../../TalentCard/TalentCardLoading";
import { TalentCard } from "../../TalentCard/TalentCard";
import { TalentsFilters } from "../TalentsExplorerFilters/TalentsExplorerFilters";

export interface TalentsExplorerListProps {
	filters: TalentsFilters;
}

export const TalentsExplorerList = ({
	filters: {
		talentType,
		regionsIds,
		sectorsIds,
		languagesIds,
		schoolsIds,
		countriesIds,
		averageSalary,
		averageEquity,
		costPerHour,
		pageNumber,
	},
}: TalentsExplorerListProps) => {
	const router = useRouter();
	const categoryList = CategoriesServiceSingleton.getList();
	const talentTypeCategoryList = categoryList.filterByCategoryType(CategoryType.TALENT_TYPE, true);
	const selectedTalentType = talentTypeCategoryList.find((talentTypeCategory) => talentTypeCategory.key === talentType);
	const debouncedQueryParams = useDebounce(
		{
			page: pageNumber ? pageNumber : 1,
			perPage: 20,
			"search[type]": selectedTalentType?.id,
			"search[sectors]": sectorsIds,
			"search[schools]": schoolsIds,
			"search[countries]": countriesIds,
			averageSalary: {
				"search[min_averageSalary]": averageSalary.min,
				"search[max_averageSalary]": averageSalary.max,
			},
			averageEquity: {
				"search[min_averageEquity]": averageEquity.min,
				"search[max_averageEquity]": averageEquity.max,
			},
		} as FetchTalentsRequestParams,
		1000,
	);
	const { data: talents, isLoading } = useTalentsQuery(debouncedQueryParams, {
		enabled: Boolean(debouncedQueryParams),
	});

	const talentsModels = talents?.items.map((investor) => TalentModel.generateFromAPI(investor));

	const handleOnPageChange = async (newPageNumber: number) => {
		await router.push(
			router.pathname +
				`?page=${newPageNumber}&${queryString.stringify(
					{
						talentType,
						sectorsIds: getStringifiedList(sectorsIds),
						regionsIds: getStringifiedList(regionsIds),
						languagesIds: getStringifiedList(languagesIds),
						schoolsIds: getStringifiedList(schoolsIds),
						countriesIds: getStringifiedList(countriesIds),
						minAverageSalary: averageSalary.min,
						maxAverageSalary: averageSalary.max,
						minAverageEquity: averageEquity.min,
						maxAverageEquity: averageEquity.max,
						minCostPerHour: costPerHour.min,
						maxCostPerHour: costPerHour.max,
					},
					{
						skipEmptyString: true,
						skipNull: true,
					},
				)}`,
		);
	};

	const getTotalPages = () => (talents?.num_pages ? talents?.num_pages : 0);

	return (
		<>
			<ul
				className={classNames(
					"w-full text-center",
					"grid gap-4",
					"grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4",
				)}
			>
				{isLoading
					? [1, 2, 3, 4, 5, 6, 7, 8].map((index) => <TalentCardLoading key={index} />)
					: talentsModels?.map((talent) => (
							<li
								key={talent.id}
								className="h-full w-full flex-1"
								data-testid={`TALENT_${talent.id}`}
							>
								<TalentCard {...talent} />
							</li>
					  ))}
			</ul>
			{getTotalPages() > 1 && (
				<div className="mt-24 w-full text-center">
					<Pagination
						currentPage={pageNumber}
						layout={"pagination"}
						totalPages={getTotalPages()}
						onPageChange={handleOnPageChange}
					/>
				</div>
			)}
		</>
	);
};
