import * as React from "react";
import { ChangeEventHandler } from "react";
import { FormattedMessage } from "react-intl";
import { Label as FlowbiteLabel } from "flowbite-react";

import { InputSlider } from "@common";

import messages from "./messages";

export interface AdviserStatistic {
	wagePerHour: number;
	percentajePerStartup: number;
}

interface AdviserStatisticFormProps {
	adviserStatistic: AdviserStatistic;
	onUpdateAdviserStatistic: (newAdviserStatistic: AdviserStatistic) => void;
}

export const AdviserStatisticForm = ({ adviserStatistic, onUpdateAdviserStatistic }: AdviserStatisticFormProps) => {
	const { wagePerHour, percentajePerStartup } = adviserStatistic;

	const handleOnChangeWagePerHour: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
		onUpdateAdviserStatistic({
			...adviserStatistic,
			wagePerHour: Number(target.value),
		});
	};

	const handleOnChangePercentajePerStartup: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
		onUpdateAdviserStatistic({
			...adviserStatistic,
			percentajePerStartup: Number(target.value),
		});
	};

	return (
		<div className="mb-8 flex flex-col gap-4 px-20">
			<FlowbiteLabel htmlFor="wagePerHour">
				<div className="mt-2 text-lg font-semibold">
					<FormattedMessage {...messages.wagePerHour} />
				</div>
			</FlowbiteLabel>
			<p className="m-0">
				{" "}
				<b className="font-semibold text-gray-400">US$</b> {wagePerHour}{" "}
			</p>
			<InputSlider
				id="wagePerHour"
				name="wagePerHour"
				defaultValue={wagePerHour}
				step={5}
				min={0}
				max={300}
				onInput={handleOnChangeWagePerHour}
			/>
			<FlowbiteLabel htmlFor="percentajePerStartup">
				<div className="mt-8 text-lg font-semibold">
					<FormattedMessage {...messages.numberOfStartupsPerYear} />
				</div>
			</FlowbiteLabel>
			<p className="m-0">
				{" "}
				{percentajePerStartup} <b className="font-semibold text-gray-400">%</b>{" "}
			</p>
			<InputSlider
				id="percentajePerStartup"
				name="percentajePerStartup"
				defaultValue={percentajePerStartup}
				step={0.5}
				min={0}
				max={100}
				onInput={handleOnChangePercentajePerStartup}
			/>
		</div>
	);
};
