import { defineMessages } from "react-intl";

export default defineMessages({
	elearnings: {
		defaultMessage: "Elearnings",
	},
	overview: {
		defaultMessage: "Overview",
	},
	courses: {
		defaultMessage: "Courses",
	},
	webinars: {
		defaultMessage: "Webinars",
	},
	workshops: {
		defaultMessage: "Workshops",
	},
});
