import { Translation } from "i18n/config";
import { NewTalentTranslation } from "./typing";

export const NewTalentTexts: Translation<NewTalentTranslation> = {
	es: {
		relatedContent: "También te podría interesar...",
		tryAgainMessage: "Ha ocurrido un error, intentelo de nuevo mas tarde.",
		titleCreate: "Crear Talent",
		titleUpdate: "Actualizar Talent",
		title: "Titulo",
		slug: "Te ayudamos con el SEO, customiza la URL de tu anuncio para indexarlo mejor en Google",
		email: "¿Qué correo electrónico le gustaría usar para obtener información actualizada sobre este elearning?",
		url: "Ingrese el link del E-learning, URL Universidad, Drive, Google Docs o Dropbox",
		start: "Comienzo",
		from: "Desde",
		end: "Fin",
		to: "Hasta",
		backgroundImage: "Imagen de Fondo",
		changeBackgroundImage: "Cambiar Imagen de Fondo",
		description: "Descripción",
		schools: "Facultad universitaria",
		markets: "Sectores",
		skills: "Habilidades",
		noSchools: "No hay facultades cargadas.",
		noMarkets: "No hay sectores cargados.",
		noSkills: "No hay habilidades cargadas.",
		language: "Idioma",
		role: "Rol",
		location: "Ciudad",
		tags: "Etiquetas",
		country: "Pais",
		salary: "Salario bruto año (USD)",
		minEquity: "Minimo Porcentaje Accionario (%)",
		maxEquity: "Maximo Porcentaje Accionario (%)",
		minExperience: "Experiencia Minima (Años)",
		published: "Publicar como activo",
		talentType: "Tipo de Talent",
		careerModality: "Modalidad",
		withCertification: "Con certificacion?",
		checkAllFieldsMessage: "Verifique que todos los campos esten completados.",
		errorMessage: "Ha ocurrido un error al procesar tu petición, intente de nuevo mas tarde.",
		seeAppliers: "Ver Aplicantes",
		alreadyApplied: "Ya Aplicado",
		subscribers: "Suscriptores",
		applyToThisTalent: "Aplicar a este talento",
		applyToThisTalentDescription: "Sí usted está interesado en esta oferta, por favor:",
		uploadCVBox: "Haga clic aquí para subir su CV",
		missingText: "Este Talent no tiene descripción",
	},
	en: {
		relatedContent: "Related Content",
		tryAgainMessage: "There was an error, try again later",
		titleCreate: "Create Talent",
		titleUpdate: "Update Talent",
		title: "Title",
		slug: "We help you with SEO, customize the URL of your ad to be better indexed in Google",
		url: "Enter the E-learning link, University's URL, Drive, Google Docs or Dropbox",
		email: "What email would you like to using to get updated info about this elearning?",
		start: "Start",
		from: "From",
		end: "End",
		to: "To",
		backgroundImage: "Background Image",
		changeBackgroundImage: "Change Background Image",
		description: "Description",
		schools: "Schools",
		markets: "Sectors",
		skills: "Skills",
		noSchools: "No schools loaded.",
		noMarkets: "No sectors loaded.",
		noSkills: "No skills loaded.",
		language: "Language",
		role: "Role",
		location: "City",
		tags: "Tags",
		country: "Country",
		salary: "Gross Salary Year (USD)",
		minEquity: "Minimum Equity (%)",
		maxEquity: "Maximum Equity (%)",
		minExperience: "Minimum Experience (Years)",
		published: "Publish as an asset",
		careerModality: "Career Modality",
		talentType: "Talent Type",
		withCertification: "With Certification",
		checkAllFieldsMessage: "Check that all fields are completed",
		errorMessage: "We have an error while we were processing your request, try again later",
		seeAppliers: "See Appliers",
		alreadyApplied: "Already Applied",
		subscribers: "Subscribers",
		applyToThisTalent: "Apply to this talent",
		applyToThisTalentDescription: "If you are interested in this offer, please:",
		uploadCVBox: "Click here to upload your CV",
		missingText: "This Talent does not have any description",
	},
	ca: {
		relatedContent: "También te podría interesar...",
		tryAgainMessage: "Hi ha hagut un error, intenteu de nou més tard.",
		titleCreate: "Crear Talent",
		titleUpdate: "Actualitzar Talent",
		title: "Títol",
		slug: "T'ajudem amb el SEO, customitza la URL del teu anunci per a indexar millor a Google",
		email: "Quin correu electrònic voldríeu utilitzar per obtenir informació actualitzada sobre aquest tema?",
		url: "Introduïu el link d'e-learning, URL universitat, Drive, Google Docs o Dropbox",
		start: "Començament",
		from: "Des de",
		end: "Fi",
		to: "Fins",
		backgroundImage: "Imatge de Fons",
		changeBackgroundImage: "Canviar Imatge de fons",
		description: "Descripció",
		schools: "Facultats",
		markets: "Sectors",
		skills: "Skills",
		noSchools: "No hay facultades cargadas.",
		noMarkets: "No hay sectores cargados.",
		noSkills: "No hay habilidades cargadas.",
		language: "Idioma",
		role: "Rol",
		location: "Ciutat",
		tags: "Etiquetas",
		country: "País",
		salary: "Salari brut any (USD)",
		minEquity: "Minimum Equity (%)",
		maxEquity: "Maximum Equity (%)",
		minExperience: "Experiència Minima (Anys)",
		published: "Publicar com a actiu",
		careerModality: "Modalitat de la cursa",
		talentType: "Tipus Talent",
		withCertification: "Amb certificació?",
		checkAllFieldsMessage: "Verifiqueu que tots els camps estiguin completats.",
		errorMessage: "Hi ha hagut un error en processar la teva petició, proveu de nou més tard.",
		seeAppliers: "Ver Aplicantes",
		alreadyApplied: "Ya Aplicado",
		subscribers: "Suscriptores",
		applyToThisTalent: "Apliqueu-vos a aquest talent",
		applyToThisTalentDescription: "Sí vostè està interessat en aquesta oferta, per favor:",
		uploadCVBox: "Feu clic aquí per penjar el vostre currículum",
		missingText: "Este Talent no tiene descripción",
	},
	pt: {
		relatedContent: "Você também pode se interessar...",
		tryAgainMessage: "Ocorreu um erro, tente novamente mais tarde.",
		titleCreate: "Criar Talento",
		titleUpdate: "Atualizar talento",
		title: "Título",
		slug: "Ajudamos você com SEO, personalizamos o URL do seu anúncio para melhor indexá-lo no Google",
		email: "Qual e-mail você gostaria de usar para obter informações atualizadas sobre este Elearning?",
		url: "Digite o link E-learning, URL da universidade, Drive, Google Docs ou Dropbox",
		start: "Começar",
		from: "A partir de",
		end: "Fim",
		to: "Até",
		backgroundImage: "Imagem de fundo",
		changeBackgroundImage: "Alterar imagem de fundo",
		description: "Descrição",
		schools: "Faculdade universitaria",
		markets: "Setores",
		skills: "Habilidades",
		noSchools: "Nenhuma habilidade carregada.",
		noMarkets: "Nenhum setor carregado.",
		noSkills: "Nenhuma habilidade carregada.",
		language: "Idioma",
		role: "Função",
		location: "Cidade",
		tags: "Tag",
		country: "País",
		salary: "Ano de salário bruto (USD)",
		minEquity: "Porcentagem Mínima de Ações (%)",
		maxEquity: "Porcentagem Máxima de Ações (%)",
		minExperience: "Experiência Mínima (Anos)",
		published: "Postar como ativo",
		talentType: "Tipo de talento",
		careerModality: "Modalidade",
		withCertification: "Com certificação?",
		checkAllFieldsMessage: "Verifique se todos os campos estão preenchidos.",
		errorMessage: "Ocorreu um erro ao processar sua solicitação, tente novamente mais tarde.",
		seeAppliers: "Ver Candidatos",
		alreadyApplied: "Já aplicado",
		subscribers: "Assinantes",
		applyToThisTalent: "Candidate-se a este talento",
		applyToThisTalentDescription: "Se você está interessado nesta oferta, por favor:",
		uploadCVBox: "Clique aqui para carregar o seu CV",
		missingText: "Este Talento não tem descrição",
	},
};
