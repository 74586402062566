import { useIntl } from "react-intl";

import { Filter, InputCheckboxField, ShowMoreToggle } from "@common";

import CategoryModel from "models/categories/Category";

import messages from "../messages";

type CategoriesCounter = number;

interface RegionsProps {
	regions: [CategoryModel, CategoriesCounter][];
	regionsIdsClicked: Record<number, boolean>;
	onChangeOneRegion: (categoryId: number) => void;
	filterIdsPrefix: string;
}

export const Regions = ({ regions, regionsIdsClicked, onChangeOneRegion, filterIdsPrefix }: RegionsProps) => {
	const { formatMessage } = useIntl();

	return (
		<Filter
			id="regions"
			title={formatMessage(messages.regions)}
		>
			<ShowMoreToggle
				initialValue={false}
				className="flex flex-col gap-2"
				items={regions.map(([region, counter]) => {
					const handleOnChangeRegion = () => onChangeOneRegion(Number(region.id));

					return (
						<InputCheckboxField
							key={region.key}
							id={filterIdsPrefix + String(region.id)}
							name={region.key}
							checked={regionsIdsClicked[region.id as number]}
							onChange={handleOnChangeRegion}
							labelValue={
								<p className="flex w-full">
									<span className="flex-1"> {region.text} </span>
									<span className="shrink-0 text-neutral-400"> ({counter}) </span>
								</p>
							}
						/>
					);
				})}
			/>
		</Filter>
	);
};
