import { Translation } from "i18n/config";
import { TalentDashboardTranslation } from "./typing";
import * as React from "react";
import { ProductKeys } from "containers/Pricing/products";
import Link from "next/link";

export const TalentDashboardTexts: Translation<TalentDashboardTranslation> = {
	es: {
		youHaveAPartnership: "Eres Partner",
		featureForHavingAPartner: `
			Alguno de los perfiles jurídicos que tienen firmado un acuerdo con nosotros, te ha seleccionado como su Partner. 
			Por eso mismo puedes acceder a crear 4 ofertas extras de Talent.
		`,
		reachLimitOfCreatedTalents: (
			<>
				Has llegado al límite de Talentos Creados, para poder adquirir más Talentos puedes acceder a:{" "}
				<Link
					href={`/pricing?product=${ProductKeys.Talents}`}
					passHref
				>
					<a>Adquirir Talentos</a>
				</Link>
				.
			</>
		),
		createdTalents: "Talentos creados",
		acquireMore: "Adquirir más",
		vingletFriend: "Amigo de Vinglet",
		featureForBeingVingletFriend: (
			<>
				Por haber firmado el convenio con nosotros, te consideramos <b>&quot;Amigo de Vinglet&quot;</b>, por eso mismo,
				te ofrecemos crear talentos ilimitadamente.
			</>
		),
		tryAgainMessage: "Ha ocurrido un error, intentelo de nuevo mas tarde.",
		noTalents: "El usuario no tiene ningun Talent creado todavia.",
		talentDashboard: "Panel de Talentos",
	},
	en: {
		youHaveAPartnership: "You are a Partner",
		featureForHavingAPartner: `
			Some of the business profiles that have signed an agreement with us have selected you as their Partner.
			That is why you can access to create 4 extra Talent offers.
		`,
		reachLimitOfCreatedTalents: (
			<>
				You have reached the limit of Created Talents, in order to acquire more Talents you can access:{" "}
				<Link
					href={`/pricing?product=${ProductKeys.Talents}`}
					passHref
				>
					<a>Acquire Talents</a>
				</Link>
				.
			</>
		),
		createdTalents: "Created talents",
		acquireMore: "Buy more",
		vingletFriend: "Friend of Vinglet",
		featureForBeingVingletFriend: (
			<>
				For having signed the agreement with us, we consider you <b>&quot;Friend of Vinglet&quot;</b>, For this reason,
				we offer you to create unlimited talents.
			</>
		),
		tryAgainMessage: "There was an error, try again later",
		noTalents: "This user doesn't have any Talent created yet.",
		talentDashboard: "Talents' Dashboard",
	},
	ca: {
		youHaveAPartnership: "Ets Partner",
		featureForHavingAPartner: `
			Alguns dels perfils empresarials que han signat un acord amb nosaltres us han seleccionat com a soci.
			És per això que pots accedir per crear 4 ofertes de Talent addicionals.
		`,
		reachLimitOfCreatedTalents: (
			<>
				Has llegado al límite de Talentos Creados, para poder adquirir más Talentos puedes acceder a:{" "}
				<Link
					href={`/pricing?product=${ProductKeys.Talents}`}
					passHref
				>
					<a>Adquirir Talentos</a>
				</Link>
				.
			</>
		),
		createdTalents: "Talentos creados",
		acquireMore: "Adquirir más",
		vingletFriend: "Amigo de Vinglet",
		featureForBeingVingletFriend: (
			<>
				Por haber firmado el convenio con nosotros, te consideramos <b>&quot;Amigo de Vinglet&quot;</b>, por eso mismo,
				te ofrecemos crear talentos ilimitadamente.
			</>
		),
		tryAgainMessage: "Hi ha hagut un error, intenteu de nou més tard.",
		noTalents: "L'usuari no té cap Talent creat encara.",
		talentDashboard: "Tauler de taula de Talents",
	},
	pt: {
		youHaveAPartnership: "Você é um Partner",
		featureForHavingAPartner: `
			Alguns dos perfis de negócios que assinaram um contrato conosco selecionaram você como seu Partner.
			É por isso que você pode acessar para criar 4 ofertas extras de Talentos.
		`,
		reachLimitOfCreatedTalents: (
			<>
				Você atingiu o limite de Talentos Criados, para adquirir mais Talentos que você pode acessar:{" "}
				<Link
					href={`/pricing?product=${ProductKeys.Talents}`}
					passHref
				>
					<a>Adquira Talentos</a>
				</Link>
				.
			</>
		),
		createdTalents: "Talentos criados",
		acquireMore: "Compre mais",
		vingletFriend: "Amigo da Vinglet",
		featureForBeingVingletFriend: (
			<>
				Por ter assinado o acordo conosco, consideramos você <b>&quot;Amigo de Vinglet&quot;</b>, por esta razão,
				oferecemos-lhe a criação de talentos ilimitados.
			</>
		),
		tryAgainMessage: "Ocorreu um erro, tente novamente mais tarde.",
		noTalents: "O usuário ainda não tem nenhum Talento criado.",
		talentDashboard: "Painel de Talentos",
	},
};
