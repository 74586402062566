import { FormattedMessage, useIntl } from "react-intl";
import * as React from "react";
import { ChangeEventHandler, useContext } from "react";
import { Label as FlowbiteLabel } from "flowbite-react";

import { Button, Select } from "@common";
import { UserProfileContext } from "@context";

import { CategoryType } from "api/CategoriesAPI/urls";
import CategoriesServiceSingleton from "services/categories/CategoriesService";
import InternalSingleDatepickerModern from "elements/InternalDatepicker/InternalSingleDatepickerModern";

import { Gender, OnboardingReducer } from "../../onboarding-reducer";

import messages, { genderMessages } from "./messages";
import dayjs from "dayjs";

interface PersonalInfoFormProps {
	info: OnboardingReducer;
	errors: Record<string, string | undefined>;
	onChangeGender?: (gender: Gender) => void;
	onChangeBirthdate?: (birthdate: Date) => void;
	onChangeCountry?: (country: string) => void;
}

const six_teen = 16;
const minApprovedAgeForUsingThePlatform = dayjs().subtract(six_teen, "year");

export const ProfileInfoForm = ({
	info,
	errors,
	onChangeGender,
	onChangeBirthdate,
	onChangeCountry,
}: PersonalInfoFormProps) => {
	const { formatMessage } = useIntl();
	const { user } = useContext(UserProfileContext);

	const categoryList = CategoriesServiceSingleton.getList();
	const countries = categoryList.filterByCategoryType(CategoryType.COUNTRY, true);

	const handleOnChangeGender = (newGender: Gender) => () => onChangeGender && onChangeGender(newGender);
	const handleOnChangeBirthdate = (newBirthdateEpoch: number) => {
		const birthdate = dayjs(newBirthdateEpoch).toDate();

		birthdate.setHours(0, 0, 0, 0);

		return onChangeBirthdate && onChangeBirthdate(birthdate);
	};
	const handleOnChangeCountry: ChangeEventHandler<HTMLSelectElement> = ({ target }) =>
		onChangeCountry && onChangeCountry(target.value);

	return (
		<div className="mb-8 flex flex-col gap-4 px-10 md:px-20">
			<h2 className="m-0 pt-12 text-4xl">
				<FormattedMessage
					{...messages.welcomeUser}
					values={{
						name: user?.first_name,
					}}
				/>
			</h2>
			<h3 className="font-regular mt-0 text-neutral-500">
				<FormattedMessage {...messages.tellUsAboutYourself} />
			</h3>
			<div className="w-full">
				<Select
					name="country"
					id="country"
					required
					sizing="lg"
					placeholder={formatMessage(messages.country)}
					labelValue={formatMessage(messages.country)}
					value={info.country || "none"}
					color={errors["country"] ? "failure" : "gray"}
					onChange={handleOnChangeCountry}
				>
					<option
						key="none"
						value="none"
						disabled
					>
						<FormattedMessage {...messages.selectACountry} />
					</option>
					{countries.map((country) => (
						<option
							key={country.id}
							value={Number(country.id)}
						>
							{country.text}
						</option>
					))}
				</Select>
			</div>
			<div>
				<div className="mb-2 block">
					<div className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
						<FlowbiteLabel
							htmlFor={"birthdate"}
							value={"Birthdate"}
						/>
					</div>
				</div>
				<InternalSingleDatepickerModern
					id="birthdate"
					className="min-w-full"
					selectedDate={info.birthdate}
					maxDate={minApprovedAgeForUsingThePlatform.toDate()}
					onChange={handleOnChangeBirthdate}
				/>
			</div>
			<div>
				<div className="mb-2 block">
					<div className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
						<FlowbiteLabel
							htmlFor={"onboarding-personal-info-birthdate"}
							value={formatMessage(messages.gender)}
						/>
					</div>
				</div>
				<div className="mb-4 grid w-full grid-cols-2 gap-4">
					<Button
						color={info.gender === "MALE" ? "dark" : "gray"}
						size="xl"
						onClick={handleOnChangeGender("MALE")}
						fluid
					>
						<div className="flex items-center justify-center gap-2">
							<span className="text-2xl"> &#128104; </span>
							<span>
								<FormattedMessage {...genderMessages["MALE"]} />
							</span>
						</div>
					</Button>
					<Button
						color={info.gender === "FEMALE" ? "dark" : "gray"}
						size="xl"
						onClick={handleOnChangeGender("FEMALE")}
						fluid
					>
						<div className="flex items-center justify-center gap-2">
							<span className="text-2xl"> &#128105; </span>
							<span>
								<FormattedMessage {...genderMessages["FEMALE"]} />
							</span>
						</div>
					</Button>
				</div>
				<Button
					color={info.gender === "OTHERS" ? "dark" : "gray"}
					size="xl"
					onClick={handleOnChangeGender("OTHERS")}
					fluid
				>
					<div className="flex items-center justify-center gap-2">
						<span>
							<FormattedMessage {...genderMessages["OTHERS"]} />
						</span>
					</div>
				</Button>
			</div>
		</div>
	);
};
