import { defineMessages } from "react-intl";

export default defineMessages({
	howItWorks: {
		defaultMessage: "How it works?",
	},
	subTitle: {
		defaultMessage: "All courses made by professionals universities and institutions",
	},
	findTheCourse: {
		defaultMessage: "Find the course, webinar or workshop from worshipful universities and institutions",
	},
	goToTheElearningsWebsite: {
		defaultMessage: "Go directly to the elearning's website quickly and securely",
	},
	startLearning: {
		defaultMessage: "That's it! Start learning right away",
	},
});
