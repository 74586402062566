import { FormattedMessage } from "react-intl";
import * as React from "react";

import { Button } from "@common";

import { OnboardingProps } from "./Onboarding";
import messages from "./messages";

type OnboardingFooterProps = {
	currentIndexActiveStep: number;
	totalNumberOfSteps: number;
} & Pick<OnboardingProps, "continueButton" | "previousButton" | "finishButton">;

export const OnboardingFooter = ({
	currentIndexActiveStep,
	totalNumberOfSteps,
	previousButton,
	continueButton,
	finishButton,
}: OnboardingFooterProps) => {
	if (currentIndexActiveStep === 0) {
		return (
			<>
				<Button
					color="purple"
					size="xl"
					onClick={continueButton.onPress}
					loading={continueButton.loading}
					disabled={continueButton.disabled}
					fluid
				>
					<FormattedMessage {...messages.continue} />
				</Button>
			</>
		);
	}

	if (currentIndexActiveStep < totalNumberOfSteps - 1) {
		return (
			<>
				<Button
					color="purple"
					size="xl"
					onClick={continueButton.onPress}
					loading={continueButton.loading}
					disabled={continueButton.disabled}
					fluid
				>
					<FormattedMessage {...messages.continue} />
				</Button>
				<Button
					color="gray"
					size="xl"
					onClick={previousButton.onPress}
					disabled={previousButton.disabled}
					fluid
				>
					<FormattedMessage {...messages.previous} />
				</Button>
			</>
		);
	}

	return (
		<>
			<Button
				size="xl"
				gradientDuoTone="purpleToBlue"
				onClick={finishButton.onPress}
				loading={finishButton.loading}
				disabled={finishButton.disabled}
				fluid
			>
				<FormattedMessage {...messages.finish} />
			</Button>
			<Button
				color="gray"
				size="xl"
				onClick={previousButton.onPress}
				disabled={previousButton.disabled}
				fluid
			>
				<FormattedMessage {...messages.previous} />
			</Button>
		</>
	);
};
