import { ChangeEventHandler } from "react";
import { useIntl } from "react-intl";

import { Filter, InputCheckboxField, ShowMoreToggle } from "@common";

import CategoryModel from "models/categories/Category";

import messages from "../messages";

type CategoriesCounter = number;

interface SchoolsProps {
	schools: [CategoryModel, CategoriesCounter][];
	schoolsIdsClicked: Record<number, boolean>;
	onChangeOneSchool: (categoryId: number) => void;
	filterIdsPrefix: string;
}

export const Schools = ({ schools, schoolsIdsClicked, onChangeOneSchool, filterIdsPrefix }: SchoolsProps) => {
	const { formatMessage } = useIntl();

	return (
		<Filter
			id="schools"
			title={formatMessage(messages.schools)}
		>
			<ShowMoreToggle
				initialValue={false}
				className="flex flex-col gap-2"
				items={schools.map(([school, counter]) => {
					const handleOnChangeSchool = () => onChangeOneSchool(Number(school.id));

					return (
						<InputCheckboxField
							key={school.key}
							id={filterIdsPrefix + String(school.id)}
							name={school.key}
							checked={schoolsIdsClicked[school.id as number]}
							onChange={handleOnChangeSchool}
							labelValue={
								<p className="flex w-full">
									<span className="flex-1"> {school.text} </span>
									<span className="shrink-0 text-neutral-400"> ({counter}) </span>
								</p>
							}
						/>
					);
				})}
			/>
		</Filter>
	);
};
