export namespace ObjectUtils {
	export interface ObjectProps {
		value: string;
		text: string;
	}

	export const convertObjectToArray = (obj: any): ObjectProps[] => {
		const array: any[] = [];

		Object.keys(obj).map(function (key, index) {
			array.push({
				value: key,
				text: obj[key],
			});
		});

		return array;
	};
}
