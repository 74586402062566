import { useState } from "react";
import { useRouter } from "next/router";

import { getListFromStringifiedList } from "@libs";

import CategoryModel from "models/categories/Category";

import * as Filters from "./Filters";

type CategoriesCounter = number;

export type StartupsFilters = {
	sectorsIds: number[];
	regionsIds: number[];
	pageNumber: number;
};

export interface StartupsExplorerFiltersProps {
	topSectors: [CategoryModel, CategoriesCounter][];
	topRegions: [CategoryModel, CategoriesCounter][];
	onChangeFilters: (filters: Omit<StartupsFilters, "pageNumber">) => void;
	filtersIdsPrefix: {
		sectors: string;
		regions: string;
	};
}

export const StartupsExplorerFilters = ({
	topSectors,
	topRegions,
	onChangeFilters,
	filtersIdsPrefix,
}: StartupsExplorerFiltersProps) => {
	const router = useRouter();
	const { regionsIds, sectorsIds } = router.query;
	const initCategoriesIdsClickedFromRouterQuery = (categoriesIds: string[]): Record<number, boolean> => {
		return categoriesIds.reduce((acc, categoryId) => {
			return {
				...acc,
				[Number(categoryId)]: true,
			};
		}, {});
	};

	const [sectorsIdsClicked, setSectorsIdsClicked] = useState<Record<number, boolean>>(
		initCategoriesIdsClickedFromRouterQuery(getListFromStringifiedList(sectorsIds as string)),
	);
	const [regionsIdsClicked, setRegionsIdsClicked] = useState<Record<number, boolean>>(
		initCategoriesIdsClickedFromRouterQuery(getListFromStringifiedList(regionsIds as string)),
	);

	const handleOnChangeFilters = (
		sectorsIdsClicked: Record<number, boolean>,
		regionsIdsClicked: Record<number, boolean>,
	) => {
		const sectorsIds = Object.keys(sectorsIdsClicked)
			.filter((sectorId) => sectorsIdsClicked[Number(sectorId)])
			.map((sectorId) => Number(sectorId));
		const regionsIds = Object.keys(regionsIdsClicked)
			.filter((regionId) => regionsIdsClicked[Number(regionId)])
			.map((regionId) => Number(regionId));

		onChangeFilters({
			sectorsIds: sectorsIds,
			regionsIds,
		});
	};

	const handleOnCheckedRegion = (categoryId: number) => {
		const newRegionsIdsClicked = {
			...regionsIdsClicked,
			[categoryId]: !regionsIdsClicked[categoryId],
		};

		setRegionsIdsClicked(newRegionsIdsClicked);
		handleOnChangeFilters(sectorsIdsClicked, newRegionsIdsClicked);
	};
	const handleOnCheckedSector = (categoryId: number) => {
		const newSectorsIdsClicked = {
			...sectorsIdsClicked,
			[categoryId]: !sectorsIdsClicked[categoryId],
		};

		setSectorsIdsClicked(newSectorsIdsClicked);
		handleOnChangeFilters(newSectorsIdsClicked, regionsIdsClicked);
	};

	return (
		<>
			<Filters.Regions
				regions={topRegions}
				regionsIdsClicked={regionsIdsClicked}
				onChangeOneRegion={handleOnCheckedRegion}
				filterIdsPrefix={filtersIdsPrefix.regions}
			/>
			<Filters.Sectors
				sectors={topSectors}
				sectorsIdsClicked={sectorsIdsClicked}
				onChangeOneSector={handleOnCheckedSector}
				filterIdsPrefix={filtersIdsPrefix.sectors}
			/>
		</>
	);
};
