import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import CategorySelectableModel from "models/categories/CategorySelectable";

import { Skills } from "./Skills";
import messages from "./messages";

interface TechnicalSkillsProps {
	technicalSkills: {
		[key: string]: CategorySelectableModel[];
	};
	onChangeTechnicalSkills: (newTechnicalSkills: { [key: string]: CategorySelectableModel[] }) => void;
}

export const TechnicalSkills = ({ technicalSkills, onChangeTechnicalSkills }: TechnicalSkillsProps) => {
	const { formatMessage } = useIntl();

	const handleOnChangeTechnicalSkills = (keyName: string) => (skills: CategorySelectableModel[]) => {
		onChangeTechnicalSkills({
			...technicalSkills,
			[keyName]: skills,
		});
	};

	return (
		<div className="mb-8 flex flex-col gap-4 px-10 md:px-20">
			<h2 className="m-0 pt-12 text-4xl">
				<FormattedMessage {...messages.technicalSkills} />
			</h2>
			<h3 className="font-regular mt-0 text-neutral-500">
				<FormattedMessage {...messages.stepDescription} />
			</h3>
			<div className="grid w-full grid-cols-1 items-baseline gap-4 md:grid-cols-2 md:gap-8">
				<Skills
					title={formatMessage(messages.businessSkills)}
					titleModal={formatMessage(messages.businessSkillsTitleModal)}
					skills={technicalSkills.businessSkills}
					onChangeSkills={handleOnChangeTechnicalSkills("businessSkills")}
				/>

				<Skills
					title={formatMessage(messages.accountantSkills)}
					titleModal={formatMessage(messages.accountantSkillsTitleModal)}
					skills={technicalSkills.accountantSkills}
					onChangeSkills={handleOnChangeTechnicalSkills("accountantSkills")}
				/>

				<Skills
					title={formatMessage(messages.designAndProductSkills)}
					titleModal={formatMessage(messages.designAndProductSkillsTitleModal)}
					skills={technicalSkills.designAndProductSkills}
					onChangeSkills={handleOnChangeTechnicalSkills("designAndProductSkills")}
				/>

				<Skills
					title={formatMessage(messages.engineeringAndArchitectureSkills)}
					titleModal={formatMessage(messages.engineeringAndArchitectureSkillsTitleModal)}
					skills={technicalSkills.engineeringAndArchitectureSkills}
					onChangeSkills={handleOnChangeTechnicalSkills("engineeringAndArchitectureSkills")}
				/>

				<Skills
					title={formatMessage(messages.investmentSkills)}
					titleModal={formatMessage(messages.investmentSkillsTitleModal)}
					skills={technicalSkills.investmentSkills}
					onChangeSkills={handleOnChangeTechnicalSkills("investmentSkills")}
				/>

				<Skills
					title={formatMessage(messages.itSkills)}
					titleModal={formatMessage(messages.itSkillsTitleModal)}
					skills={technicalSkills.itSkills}
					onChangeSkills={handleOnChangeTechnicalSkills("itSkills")}
				/>

				<Skills
					title={formatMessage(messages.legalSkills)}
					titleModal={formatMessage(messages.legalSkillsTitleModal)}
					skills={technicalSkills.legalSkills}
					onChangeSkills={handleOnChangeTechnicalSkills("legalSkills")}
				/>

				<Skills
					title={formatMessage(messages.salesAndMarketingSkills)}
					titleModal={formatMessage(messages.salesAndMarketingSkillsTitleModal)}
					skills={technicalSkills.salesAndMarketingSkills}
					onChangeSkills={handleOnChangeTechnicalSkills("salesAndMarketingSkills")}
				/>
			</div>
		</div>
	);
};
