import * as React from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import queryString from "query-string";

import { CategoryType } from "@api";
import { getStringifiedList } from "@libs";

import CategoriesServiceSingleton from "services/categories/CategoriesService";

import { ElearningsProps } from "../Elearnings";

import { ElearningsTopSchoolCard } from "./ElearningsTopSchoolCard";
import messages from "./messages";

type ElearningsTopSchoolsProps = Pick<ElearningsProps, "topTenSchools">;

export const ElearningsTopSchools = ({ topTenSchools }: ElearningsTopSchoolsProps) => {
	const categoryList = CategoriesServiceSingleton.getList();
	const schoolsCategoriesModels = categoryList.filterByCategoryType(CategoryType.SCHOOL, true);

	return (
		<div>
			<div>
				<h2 className="text-4xl font-extrabold">
					<FormattedMessage {...messages.topSchool} />
				</h2>
				<p className="text-sm font-medium text-neutral-600">
					<FormattedMessage {...messages.topSchoolSubParagraph} />
				</p>
			</div>
			<ul
				className={classNames(
					"mt-6 grid w-full items-center justify-between gap-4 text-center",
					"grid-cols-3 xl:grid-cols-6 2xl:grid-cols-8",
					"[&>*:nth-child(-n+5)]:block xl:[&>*:nth-child(-n+6)]:block 2xl:[&>*:nth-child(-n+8)]:block",
				)}
			>
				{topTenSchools.map(([schoolKey, schoolCounter]) => {
					const schoolCategoryModel = schoolsCategoriesModels.find((school) => school.key === schoolKey);

					if (!schoolCategoryModel) {
						return <></>;
					}

					const schoolLink = `/elearnings/explorer/courses?${queryString.stringify(
						{
							schoolsIds: getStringifiedList([schoolCategoryModel.id]),
						},
						{
							skipEmptyString: true,
							skipNull: true,
						},
					)}`;

					return (
						<ElearningsTopSchoolCard
							key={schoolKey}
							className="hidden flex-1"
							schoolName={schoolCategoryModel.text}
							schoolCounter={schoolCounter}
							schoolLink={schoolLink}
						/>
					);
				})}
			</ul>
		</div>
	);
};
