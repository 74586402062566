import { ChangeEventHandler } from "react";
import { useIntl } from "react-intl";

import { Filter, InputSlider } from "@common";

import messages from "../messages";

interface DiscountProps {
	onChangeDiscount: (minDiscount?: number | undefined) => void;
	currentSelectedMarketplaceDiscount: number | undefined;
	filterIdsPrefix: string;
}

export const Discount = ({ onChangeDiscount, currentSelectedMarketplaceDiscount, filterIdsPrefix }: DiscountProps) => {
	const { formatMessage } = useIntl();

	const handleOnChangeDiscount: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
		onChangeDiscount(target.value ? Number(target.value) : undefined);
	};

	return (
		<Filter
			id="discount"
			title={formatMessage(messages.discount)}
		>
			<InputSlider
				name={`${filterIdsPrefix}-discount`}
				id={`${filterIdsPrefix}-discount`}
				value={currentSelectedMarketplaceDiscount || 0}
				min={0}
				max={100}
				onInput={handleOnChangeDiscount}
				labelValue={formatMessage(messages.currentDiscountFilter, {
					discount: currentSelectedMarketplaceDiscount || 0,
				})}
			/>
		</Filter>
	);
};
