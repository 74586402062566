import * as React from "react";
import { InternalSingleDatepickerProps } from "./typing";
import dayjs from "dayjs";
import { BrowserUtils, DateUtils } from "@utils";
import classNames from "classnames";

const ReactModernDatepicker = require("react-modern-datepicker").default;

const InternalSingleDatepickerModern = (props: InternalSingleDatepickerProps) => {
	const { id, className, selectedDate, minDate, maxDate, disabled, onChange } = props;
	const [datePickerState, setDatePickerState] = React.useState<{
		selectedDate: dayjs.Dayjs;
	}>({
		selectedDate: dayjs(selectedDate),
	});
	const handleOnDatesChange = (newDate: string) => {
		const newSelectedDate = DateUtils.getEPOCHFromDefaultFormat(newDate);

		setDatePickerState({
			...datePickerState,
			selectedDate: dayjs(newSelectedDate),
		});
		onChange(newSelectedDate);
	};
	const getMinDate = () => {
		return minDate ? dayjs(minDate).toDate() : null;
	};

	return (
		<button
			className={classNames("clean-button relative z-20 w-full p-10 md:w-96", className)}
			disabled={disabled}
		>
			<ReactModernDatepicker
				id={id}
				date={datePickerState.selectedDate.toDate()}
				className={"ModernDatepicker"}
				minDate={getMinDate()}
				maxDate={maxDate}
				format={"DD-MM-YYYY"}
				showBorder
				onChange={handleOnDatesChange}
				allowEdit={BrowserUtils.isCypress()}
			/>
		</button>
	);
};

export default InternalSingleDatepickerModern;
