import { defineMessages } from "react-intl";

export default defineMessages({
	addFilters: {
		defaultMessage: "Add filters",
	},
	showResults: {
		defaultMessage: "Show results",
	},
});
