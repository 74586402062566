import { defineMessages } from "react-intl";

export default defineMessages({
	title: {
		defaultMessage: "Welcome to the fourth industrial revolution: Startups",
	},
	subTitle: {
		defaultMessage:
			"Recruit talent, validate or sell a product or service in our marketplace at a national and international level",
	},
});
