import { defineMessages } from "react-intl";

export default defineMessages({
	wagePerHour: {
		defaultMessage: "Wage per hour",
	},
	numberOfStartupsPerYear: {
		defaultMessage: "Taking equity",
	},
});
