import { defineMessages } from "react-intl";

export default defineMessages({
	business: {
		defaultMessage: "Business",
	},
	salesAndMarketing: {
		defaultMessage: "Sales and Marketing",
	},
	investment: {
		defaultMessage: "Investment",
	},
	desingAndProduct: {
		defaultMessage: "Design and Product",
	},
	it: {
		defaultMessage: "IT",
	},
	legal: {
		defaultMessage: "Legal",
	},
	accountant: {
		defaultMessage: "Accountant",
	},
	engineeringAndArchitecture: {
		defaultMessage: "Engineering and Architecture",
	},
	medicineAndPsychology: {
		defaultMessage: "Medicine and Psychology",
	},
});
