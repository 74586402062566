import { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { InternalLink, TenderTalentsList } from "@common";

import TalentModel from "models/talents/Talent";

import { TenderTalentsLoading } from "./TenderTalentsLoading";
import messages from "./messages";

type TenderTalentsProps = {
	tenderId: number;
	talents: TalentModel[];
	isLoading: boolean;
};

export const TenderTalents = ({ tenderId, talents, isLoading }: TenderTalentsProps) => {
	const getContainer = (children: ReactNode) => {
		return (
			<div className="flex items-center justify-around bg-white pt-10 pb-12 dark:bg-gray-800">
				<div className="z-20 w-1/2 py-12 px-4 text-start sm:px-6 lg:py-16 lg:px-8">
					<h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
						<span className="block">
							<FormattedMessage {...messages.wantJoinTheTeam} />
						</span>
						<span className="block text-indigo-500">
							<FormattedMessage {...messages.applyToTalentOffers} />
						</span>
					</h2>
					<div className="lg:mt-0 lg:flex-shrink-0">
						<InternalLink
							href={`/tenders/${tenderId}/talents`}
							className="mt-4 inline-flex rounded-md shadow"
						>
							<button
								type="button"
								className="w-full rounded-lg  bg-indigo-600 py-4 px-6 text-center text-base font-semibold text-white shadow-md transition duration-200 ease-in hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2  focus:ring-offset-indigo-200 "
							>
								<FormattedMessage {...messages.viewAllTalentOffers} />
							</button>
						</InternalLink>
					</div>
				</div>
				{children}
			</div>
		);
	};

	if (isLoading) {
		return getContainer(<TenderTalentsLoading />);
	}

	if (talents.length === 0) {
		return <></>;
	}

	return getContainer(
		<div>
			<TenderTalentsList talents={talents} />
		</div>,
	);
};
