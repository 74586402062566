import React, { ChangeEvent, ChangeEventHandler, FormEvent, FormEventHandler, useState } from "react";
import { AxiosError } from "axios";
import { FormattedMessage, useIntl } from "react-intl";

import Image from "next/image";
import Link from "next/link";

import { Button, InputCheckboxField, InputField } from "@common";
import { useSignUserUpMutation } from "@api";
import { isValidEmail } from "@utils";
import { useAnalytics, useLocales, useNotifications } from "@libs";

import messages from "./messages";

type SignupFieldState = {
	error: boolean;
	value: string;
};

const Signup = () => {
	const { currentShortLocale } = useLocales();
	const { event } = useAnalytics();
	const { formatMessage } = useIntl();
	const { addErrorNotification } = useNotifications();
	const { mutate, isLoading } = useSignUserUpMutation({
		onSuccess: async (_, variables) => {
			location.reload();
			await event("SIGN_UP_FINISH", variables);
		},
		onError: async (error: AxiosError, variables) => {
			await event("SIGN_UP_FINISH_WITH_ERROR", {
				...variables,
				error: error.response?.status,
			});

			if (error.response?.status === 401 || error.response?.status === 400) {
				addErrorNotification(formatMessage(messages.theEmailIsAlreadyTaken));
				return;
			}

			addErrorNotification(formatMessage(messages.somethingWentWrong));
		},
	});
	const [email, setEmail] = useState<SignupFieldState>({
		error: false,
		value: "",
	});
	const [password, setPassword] = useState<SignupFieldState>({
		error: false,
		value: "",
	});
	const [firstName, setFirstName] = useState<SignupFieldState>({
		error: false,
		value: "",
	});
	const [lastName, setLastName] = useState<SignupFieldState>({
		error: false,
		value: "",
	});

	const signup: FormEventHandler<HTMLFormElement> = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (!isValidEmail(email.value)) {
			addErrorNotification(formatMessage(messages.emailBadFormatted));

			return;
		}

		mutate({
			first_name: firstName.value,
			last_name: lastName.value,
			email: email.value,
			password: password.value,
		});
	};

	const handleOnChangeFirstName: ChangeEventHandler<HTMLInputElement> = (event: ChangeEvent<HTMLInputElement>) => {
		setFirstName((prevState) => {
			return {
				...prevState,
				value: event.target.value,
			};
		});
	};

	const handleOnChangeLastName: ChangeEventHandler<HTMLInputElement> = (event: ChangeEvent<HTMLInputElement>) => {
		setLastName((prevState) => {
			return {
				...prevState,
				value: event.target.value,
			};
		});
	};

	const handleOnChangeEmail: ChangeEventHandler<HTMLInputElement> = (event: ChangeEvent<HTMLInputElement>) => {
		setEmail((prevState) => {
			return {
				...prevState,
				value: event.target.value,
			};
		});
	};

	const handleOnChangePassword: ChangeEventHandler<HTMLInputElement> = (event: ChangeEvent<HTMLInputElement>) => {
		setPassword((prevState) => {
			return {
				...prevState,
				value: event.target.value,
			};
		});
	};

	return (
		<section
			className="bg-gray-50 dark:bg-gray-900"
			data-testid="SIGNUP_FORM"
		>
			<Image
				src="/color-smoke-signup.jpg"
				alt="Singup Background"
				className="opacity-20"
				layout="fill"
			/>
			<div className="relative z-20 mx-auto flex min-h-screen flex-col items-center justify-start px-6 py-20">
				<div className="w-full rounded-lg bg-white shadow-2xl dark:border dark:border-gray-700 dark:bg-gray-800 sm:max-w-xl md:mt-0 xl:p-0">
					<div className="relative mt-6 hidden h-28 w-full items-center text-2xl font-semibold text-gray-900 dark:text-white md:flex">
						<Image
							className="object-contain object-center"
							src="/logo-complete.png"
							layout="fill"
							alt="Vinglet Logo"
						/>
					</div>
					<div className="space-y-4 p-6 sm:p-8 md:space-y-6">
						<div className="space-y-0">
							<h1 className="mb-2 text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl">
								<FormattedMessage {...messages.createANewAccount} />
							</h1>
							<p className="text-gray-500">
								<FormattedMessage {...messages.platformForPhysical} />
							</p>
						</div>
						<form
							className="space-y-4 md:space-y-6"
							onSubmit={signup}
						>
							<div className="flex items-center gap-4">
								<div className="w-full">
									<InputField
										type="text"
										name="name"
										id="name"
										placeholder="Vin"
										min={1}
										required
										labelValue={formatMessage(messages.yourName)}
										value={firstName.value}
										color={firstName.error ? "failure" : "gray"}
										autoComplete="name"
										onChange={handleOnChangeFirstName}
									/>
								</div>
								<div className="w-full">
									<InputField
										type="text"
										name="surname"
										id="surname"
										placeholder="Glet"
										min={1}
										required
										labelValue={formatMessage(messages.yourSurname)}
										value={lastName.value}
										color={lastName.error ? "failure" : "gray"}
										autoComplete="family-name"
										onChange={handleOnChangeLastName}
									/>
								</div>
							</div>
							<div>
								<InputField
									type="email"
									name="email"
									id="email"
									placeholder="name@company.com"
									required
									labelValue={formatMessage(messages.yourEmail)}
									value={email.value}
									color={email.error ? "failure" : "gray"}
									autoComplete="email"
									onChange={handleOnChangeEmail}
								/>
							</div>
							<div>
								<InputField
									type="password"
									name="password"
									id="password"
									min={8}
									max={20}
									placeholder="••••••••"
									required
									labelValue={formatMessage(messages.password)}
									value={password.value}
									color={password.error ? "failure" : "gray"}
									autoComplete={"current-password"}
									onChange={handleOnChangePassword}
								/>
							</div>
							<div className="flex items-start">
								<InputCheckboxField
									id="terms"
									name="terms"
									aria-describedby="terms"
									required
									labelValue={
										<FormattedMessage
											{...messages.acceptTermsAndConditions}
											values={{
												terms: (
													<a
														className="text-primary-600 dark:text-primary-500 font-medium hover:underline"
														rel="noreferrer"
														target="_blank"
														href="https://legal.vinglet.com/TyC.pdf"
													>
														<FormattedMessage {...messages.termsAndConditions} />
													</a>
												),
											}}
										/>
									}
								/>
							</div>
							<Button
								aria-label={formatMessage(messages.signUp)}
								size="lg"
								type="submit"
								color="purple"
								fluid
								loading={isLoading}
							>
								<FormattedMessage {...messages.signUp} />
							</Button>
							<p className="text-sm font-light text-gray-500 dark:text-gray-400">
								<FormattedMessage
									{...messages.iAlreadyHaveAnAccount}
									values={{
										logInLink: (
											<Link
												href="/login"
												locale={currentShortLocale}
												passHref
											>
												<a className="text-primary-600 dark:text-primary-500 font-medium hover:underline">
													<FormattedMessage {...messages.logIn} />
												</a>
											</Link>
										),
									}}
								/>
							</p>
						</form>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Signup;
