import { useIntl } from "react-intl";
import * as React from "react";
import { Badge, Card, Modal } from "flowbite-react";

import { Button, LabelListProfile, Plus } from "@common";
import { useToggle } from "@libs";

import CategorySelectableModel from "models/categories/CategorySelectable";

interface SkillsProps {
	title: string;
	titleModal: string;
	skills: CategorySelectableModel[];
	onChangeSkills: (newSkills: CategorySelectableModel[]) => void;
}

export const Skills = ({ title, titleModal, skills, onChangeSkills }: SkillsProps) => {
	const { formatMessage } = useIntl();
	const [open, { toggleOn, toggleOff }] = useToggle();
	const selectedSkills = skills.filter((skill) => skill.selected);

	return (
		<>
			<div
				className="cursor-pointer transition-shadow hover:shadow-xl"
				onClick={toggleOn}
			>
				<Card>
					<div className="flex items-center justify-between">
						<h4 className="m-0 text-2xl font-semibold dark:text-white">{title}</h4>
						<Button
							size="xs"
							color="gray"
							onClick={toggleOn}
						>
							<Plus className="h-6 w-6" />
						</Button>
					</div>
					{selectedSkills.length > 0 && (
						<ul className={"flex flex-wrap items-center gap-2"}>
							{selectedSkills.map((selectedSkill) => (
								<Badge
									key={selectedSkill.key}
									color="success"
									size="sm"
								>
									{selectedSkill.text}
								</Badge>
							))}
						</ul>
					)}
				</Card>
			</div>
			<Modal show={open}>
				<Modal.Body>
					<h3 className="text-3xl font-semibold dark:text-white">{titleModal}</h3>
					<div className="mt-14 mb-10">
						<LabelListProfile
							gridClassName="flex flex-wrap"
							labelsTextClassName="text-lg"
							basic
							showAll
							editable
							color="blue"
							items={skills}
							onUpdate={onChangeSkills}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="w-full">
						<Button
							fluid
							size="xl"
							color="light"
							onClick={toggleOff}
						>
							Continue
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
};
