import * as React from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import { Button } from "@common";
import { useToggle } from "@libs";

import {
	ElearningsExplorerFilters,
	ElearningsExplorerFiltersProps,
} from "../ElearningsExplorerFilters/ElearningsExplorerFilters";

import messages from "./messages";

type ElearningsExplorerFiltersModalProps = Omit<ElearningsExplorerFiltersProps, "filtersIdsPrefix">;

export const ElearningsExplorerFiltersModal = ({
	topModalities,
	topSchools,
	topLanguages,
	topMarkets,
	onChangeFilters,
}: ElearningsExplorerFiltersModalProps) => {
	const [open, { toggleOn, toggleOff }] = useToggle();

	return (
		<>
			<Button
				fluid
				size="xl"
				color="success"
				onClick={toggleOn}
			>
				<FormattedMessage {...messages.addFilters} />
			</Button>
			<div
				className={classNames("z-index-99 fixed top-0 left-0 h-full w-full bg-white", open ? "" : "hidden")}
				aria-modal="true"
			>
				<div className="h-full w-full overflow-y-scroll py-4 px-8 pb-36">
					<ElearningsExplorerFilters
						topModalities={topModalities}
						topSchools={topSchools}
						topLanguages={topLanguages}
						topMarkets={topMarkets}
						onChangeFilters={onChangeFilters}
						filtersIdsPrefix={{
							modalities: "modalities-mobile",
							schools: "schools-mobile",
							languages: "languages-mobile",
							markets: "markets-mobile",
						}}
					/>
				</div>
				<div className="sticky bottom-0 border-t border-gray-300 bg-white py-8 px-4">
					<div className="w-full">
						<Button
							fluid
							size="xl"
							color="purple"
							onClick={toggleOff}
						>
							<FormattedMessage {...messages.showResults} />
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};
