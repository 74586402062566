import {CommonModalTexts} from "i18n/containers/Modals/CommonModal";
import {CommonModalTranslation} from "i18n/containers/Modals/CommonModal/typing";
import * as React from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import dynamic from "next/dynamic";
import {useLocales} from "@libs";

const DynamicModal = dynamic(() => import("semantic-ui-react/dist/commonjs/modules/Modal"));
const DynamicModalContent = dynamic(() => import("semantic-ui-react/dist/commonjs/modules/Modal/ModalContent"));
const DynamicModalActions = dynamic(() => import("semantic-ui-react/dist/commonjs/modules/Modal/ModalActions"));


const TendersTalentDashboard = (props: { open: boolean; onCancel: () => void; onDelete: () => void }) => {
    const {open, onCancel, onDelete} = props;
    const {currentShortLocale} = useLocales();
    const adviseTranslations: CommonModalTranslation = {
        ...CommonModalTexts[currentShortLocale],
    };

    return (
        <DynamicModal
            size="mini"
            open={open}
        >
            <DynamicModalContent>
                <p> {adviseTranslations.adviseDelete} </p>
            </DynamicModalContent>
            <DynamicModalActions>
                <Button
                    negative
                    disabled={false}
                    loading={false}
                    onClick={onCancel}
                >
                    {adviseTranslations.negativeDelete}
                </Button>
                <Button
                    positive
                    disabled={false}
                    loading={false}
                    icon="checkmark"
                    labelPosition="right"
                    content={adviseTranslations.affirmativeDelete}
                    onClick={onDelete}
                />
            </DynamicModalActions>
        </DynamicModal>
    );
};

export default React.memo(TendersTalentDashboard);
