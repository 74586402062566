import { defineMessages } from "react-intl";

export default defineMessages({
	title: {
		defaultMessage: "Here you will find the best talent for your future",
	},
	subTitle: {
		defaultMessage: "Talent search, co-founders for your start-up and advisors to achieve all your goals",
	},
});
