import * as React from "react";
import Image from "next/image";

import { useChallengeQuery } from "@api";
import { Breadcrumbs, BreadcrumbsItemProps, SidebarLayout } from "@common";

import ChallengeTenderModel from "models/challenge-tenders/ChallengeTender";
import ChallengeModel from "models/challenges/Challenge";

import { ChallengeTenderSidebar } from "./Sidebar/ChallengeTenderSidebar";
import { ChallengeTenderGeneral } from "./General/ChallengeTenderGeneral";
import { ChallengeTenderTalents } from "./Talents/ChallengeTenderTalents";
import { ChallengeTenderTeam } from "./Team/ChallengeTenderTeam";
import { TenderSettings } from "./Settings/TenderSettings";
import {useLocales} from "@libs";

export type TendersSection = "general" | "talents" | "teams" | "settings";

export interface ChallengeTenderProps {
	tender: ChallengeTenderModel;
	section: TendersSection;
}

export const ChallengeTender = ({ tender, section }: ChallengeTenderProps) => {
	const { data: challenge } = useChallengeQuery(String(tender.challenge.slug));
	const {currentShortLocale} = useLocales();

	if (section === "settings") {
		return <TenderSettings tender={tender} />;
	}

	const challengeModel = challenge ? ChallengeModel.generateFromAPI(challenge) : null;

	const breadCrumbLists: BreadcrumbsItemProps[] = [
		{
			text: challengeModel?.title || "Loading challenge...",
			href: challengeModel?.getInternalURL(currentShortLocale),
		},
		{
			text: tender.title,
		},
	];

	return (
		<SidebarLayout
			sidebar={
				<ChallengeTenderSidebar
					idTender={tender.id}
					isMine={tender.isMine}
					activeSection={section}
				/>
			}
		>
			<main>
				<header>
					<Breadcrumbs
						ariaLabel=""
						items={breadCrumbLists}
					/>
				</header>
				<div className="mx-auto max-w-5xl pb-20 pt-20">
					<section className="w-full px-20">
						<div className="relative aspect-video overflow-hidden rounded-2xl shadow-lg">
							<Image
								className="object-cover"
								src={tender.getFormattedCoverImage().src}
								alt={tender.title}
								layout="fill"
							/>
						</div>
					</section>
					{section === "general" && <ChallengeTenderGeneral tender={tender} />}
					{section === "talents" && <ChallengeTenderTalents tender={tender} />}
					{section === "teams" && <ChallengeTenderTeam tender={tender} />}
				</div>
			</main>
		</SidebarLayout>
	);
};
