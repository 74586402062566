import { defineMessages } from "react-intl";

export default defineMessages({
	title: {
		defaultMessage: "Explore our wide selection of courses",
	},
	subTitle: {
		defaultMessage: "Choose among all the courses to excel your skills",
	},
	exploreAll: {
		defaultMessage: "Explore all courses",
	},
});
