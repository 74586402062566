import { useState } from "react";
import classNames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import { useRouter } from "next/router";
import Image from "next/image";

import { Breadcrumbs, BreadcrumbsItemProps } from "@common";
import { ElearningTypeSearch } from "@api";
import { getListFromStringifiedList } from "@libs";

import CategoryModel from "models/categories/Category";

import messages from "../Elearnings/messages";

import { ElearningsExplorerFilters, ElearningsFilters } from "./ElearningsExplorerFilters/ElearningsExplorerFilters";
import { ElearningsExplorerList } from "./ElearningsExplorerList/ElearningsExplorerList";
import { ElearningsExplorerFiltersModal } from "./ElearningsExplorerFiltersModal/ElearningsExplorerFiltersModal";

import { elearningTypesMessages, headersMessages } from "./messages";

type CategoriesCounter = number;

export interface ElearningsExplorerProps {
	topModalities?: [CategoryModel, CategoriesCounter][];
	topSchools: [CategoryModel, CategoriesCounter][];
	topLanguages: [CategoryModel, CategoriesCounter][];
	topMarkets: [CategoryModel, CategoriesCounter][];
	initialPageNumber: number;
	elearningTypeSearch: ElearningTypeSearch;
}

export const ElearningsExplorer = ({
	topModalities,
	topSchools,
	topLanguages,
	topMarkets,
	initialPageNumber,
	elearningTypeSearch,
}: ElearningsExplorerProps) => {
	const { formatMessage } = useIntl();
	const router = useRouter();
	const { schoolsIds, languagesIds, marketsIds, modalitiesIds } = router.query;
	const [filters, setFilters] = useState<ElearningsFilters>({
		modalitiesIds: getListFromStringifiedList(modalitiesIds as string),
		schoolsIds: getListFromStringifiedList(schoolsIds as string),
		languagesIds: getListFromStringifiedList(languagesIds as string),
		marketsIds: getListFromStringifiedList(marketsIds as string),
		pageNumber: initialPageNumber,
		elearningTypeSearch: elearningTypeSearch,
	});

	const breadCrumbLists: BreadcrumbsItemProps[] = [
		{
			text: formatMessage(headersMessages[elearningTypeSearch].elearningsHome),
			href: "/elearnings",
		},
		{
			text: formatMessage(elearningTypesMessages[elearningTypeSearch]),
		},
	];

	const onChangeFilters = (newFilters: Omit<ElearningsFilters, "pageNumber" | "elearningTypeSearch">) => {
		setFilters((prevState) => ({
			...newFilters,
			pageNumber: 0,
			elearningTypeSearch: prevState.elearningTypeSearch,
		}));
	};

	return (
		<>
			<div className="z-index-99 fixed bottom-0 w-full border-t border-gray-300 bg-white py-8 px-4 lg:hidden">
				<ElearningsExplorerFiltersModal
					topModalities={topModalities}
					topSchools={topSchools}
					topLanguages={topLanguages}
					topMarkets={topMarkets}
					onChangeFilters={onChangeFilters}
				/>
			</div>
			<main className="min-h-full w-full pb-36">
				<Breadcrumbs
					ariaLabel=""
					items={breadCrumbLists}
				/>
				<div
					className={classNames("w-full py-16 text-center lg:py-28 lg:text-left", "px-4 lg:px-10 xl:px-20 2xl:px-44")}
				>
					<div className="flex items-center justify-around">
						<div>
							<h1 className="text-5xl font-extrabold text-gray-900">
								<FormattedMessage {...headersMessages[elearningTypeSearch].title} />
							</h1>
							<p className="mt-4 mb-8 text-lg text-gray-600">
								<FormattedMessage {...headersMessages[elearningTypeSearch].subTitle} />
							</p>
						</div>
						<div className="hidden lg:block">
							<div className="relative mx-auto w-96">
								<Image
									className="object-cover"
									alt={formatMessage(messages.youCanAccessALotOfCourses)}
									src="/elearnings/woman-getting-certificate.svg"
									layout="responsive"
									width="100%"
									height="100%"
								/>
							</div>
						</div>
					</div>
					<section className="mt-10 py-6 lg:py-20">
						<div className="flex items-stretch justify-between">
							<aside className="hidden h-full w-80 shrink-0 lg:block">
								<ElearningsExplorerFilters
									topModalities={topModalities}
									topSchools={topSchools}
									topLanguages={topLanguages}
									topMarkets={topMarkets}
									onChangeFilters={onChangeFilters}
									filtersIdsPrefix={{
										modalities: "modalities",
										schools: "schools",
										languages: "languages",
										markets: "markets",
									}}
								/>
							</aside>
							<section className="flex-1 pl-0 lg:pl-20">
								<ElearningsExplorerList filters={filters} />
							</section>
						</div>
					</section>
				</div>
			</main>
		</>
	);
};
