import { defineMessages } from "react-intl";

export default defineMessages({
	thereAreNoTendersCreatedYet: {
		defaultMessage: "There are no tenders created yet for this challenge",
	},
	proposals: {
		defaultMessage: "Challenge's proposals",
	},
	createdUntilNow: {
		defaultMessage: "Created until now",
	},
	numberOfProposals: {
		defaultMessage: "{number, plural, one {# proposal} other {# proposals}}",
	},
});
