import { useState } from "react";
import classNames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import { useRouter } from "next/router";
import Image from "next/image";

import { MarketplaceOffer, MarketplaceType } from "@api";
import { getListFromStringifiedList } from "@libs";

import CategoryModel from "models/categories/Category";

import {
	MarketplacesExplorerFilters,
	MarketplacesFilters,
} from "./MarketplacesExplorerFilters/MarketplacesExplorerFilters";
import { MarketplacesExplorerList } from "./MarketplacesExplorerList/MarketplacesExplorerList";
import { MarketplacesExplorerFiltersModal } from "./MarketplacesExplorerFiltersModal/MarketplacesExplorerFiltersModal";

import messages from "./messages";

type CategoriesCounter = number;

export interface MarketplacesExplorerProps {
	topSectors: [CategoryModel, CategoriesCounter][];
	topRegions: [CategoryModel, CategoriesCounter][];
	initialPageNumber: number;
}

export const MarketplacesExplorer = ({ topRegions, topSectors, initialPageNumber }: MarketplacesExplorerProps) => {
	const router = useRouter();
	const { formatMessage } = useIntl();
	const {
		regionsIds,
		sectorsIds,
		marketplaceType,
		marketplaceOffer,
		minQuantity,
		maxQuantity,
		minPrice,
		maxPrice,
		minDiscount,
	} = router.query;
	const [filters, setFilters] = useState<MarketplacesFilters>({
		marketplaceType: marketplaceType as MarketplaceType | undefined,
		marketplaceOffer: marketplaceOffer as MarketplaceOffer | undefined,
		sectorsIds: getListFromStringifiedList(sectorsIds as string),
		regionsIds: getListFromStringifiedList(regionsIds as string),
		quantity: {
			min: Number.isInteger(minQuantity) ? Number(minQuantity) : undefined,
			max: Number.isInteger(maxQuantity) ? Number(maxQuantity) : undefined,
		},
		price: {
			min: Number.isInteger(minPrice) ? Number(minPrice) : undefined,
			max: Number.isInteger(maxPrice) ? Number(maxPrice) : undefined,
		},
		discount: {
			min: Number.isInteger(minDiscount) ? Number(minDiscount) : undefined,
		},
		pageNumber: initialPageNumber,
	});

	const onChangeFilters = (newFilters: Omit<MarketplacesFilters, "pageNumber" | "elearningTypeSearch">) => {
		setFilters(() => ({
			...newFilters,
			pageNumber: 0,
		}));
	};

	return (
		<>
			<div className="z-index-99 fixed bottom-0 w-full border-t border-gray-300 bg-white py-8 px-4 lg:hidden">
				<MarketplacesExplorerFiltersModal
					topSectors={topSectors}
					topRegions={topRegions}
					onChangeFilters={onChangeFilters}
				/>
			</div>
			<main className="min-h-full w-full pb-36">
				<div
					className={classNames("w-full py-16 text-center lg:py-28 lg:text-left", "px-4 lg:px-10 xl:px-20 2xl:px-44")}
				>
					<div className="flex items-center justify-around">
						<div>
							<h1 className="text-5xl font-extrabold text-gray-900">
								<FormattedMessage {...messages.title} />
							</h1>
							<p className="mt-4 mb-8 text-lg text-gray-600">
								<FormattedMessage {...messages.subTitle} />
							</p>
						</div>
						<div className="hidden lg:block">
							<div className="relative mx-auto w-96">
								<Image
									className="object-cover"
									alt={formatMessage(messages.title)}
									src="/marketplaces/woman-looking-for-products.svg"
									layout="responsive"
									width="100%"
									height="100%"
								/>
							</div>
						</div>
					</div>
					<section className="mt-10 py-6 lg:py-20">
						<div className="flex items-stretch justify-between">
							<aside className="hidden h-full w-80 shrink-0 lg:block">
								<MarketplacesExplorerFilters
									topSectors={topSectors}
									topRegions={topRegions}
									onChangeFilters={onChangeFilters}
									filtersIdsPrefix={{
										marketplaceType: "marketplaceType",
										sectors: "sectors",
										regions: "regions",
										quantity: "quantity",
										price: "price",
										discount: "discount",
									}}
								/>
							</aside>
							<section className="flex-1 pl-0 lg:pl-20">
								<MarketplacesExplorerList filters={filters} />
							</section>
						</div>
					</section>
				</div>
			</main>
		</>
	);
};
