import React, { ChangeEventHandler, FocusEventHandler } from "react";
import { useIntl } from "react-intl";

import { Filter, InputField } from "@common";

import messages from "../messages";

interface AverageSalaryProps {
	onChangeAverageSalary: (min?: number, max?: number) => void;
	currentSelectedTalentsAverageSalary: {
		min?: number;
		max?: number;
	};
	filterIdsPrefix: string;
}

export const AverageSalary = ({
	onChangeAverageSalary,
	currentSelectedTalentsAverageSalary,
	filterIdsPrefix,
}: AverageSalaryProps) => {
	const { formatMessage } = useIntl();
	const { min, max } = currentSelectedTalentsAverageSalary;

	const onChangeMinAverageSalary: ChangeEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;

		onChangeAverageSalary(value ? Number(value) : undefined, max);
	};

	const onChangeMaxAverageSalary: ChangeEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;

		onChangeAverageSalary(min, value ? Number(value) : undefined);
	};

	const onBlurMaxAverageSalary: FocusEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;

		if (min && Number(value) < min) {
			onChangeAverageSalary(Number(value), max);
		}
	};

	const onBlurMinAverageSalary: FocusEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;

		if (max && Number(value) > max) {
			onChangeAverageSalary(min, Number(value));
		}
	};

	return (
		<Filter
			id="averageSalary"
			title={formatMessage(messages.averageSalary)}
		>
			<div className="flex items-center justify-center gap-4">
				<div className="w-full">
					<InputField
						type="number"
						name={`${filterIdsPrefix}-min-averageSalary`}
						id={`${filterIdsPrefix}-min-averageSalary`}
						placeholder="$0"
						required
						value={min}
						onChange={onChangeMinAverageSalary}
						onBlur={onBlurMinAverageSalary}
						labelValue={formatMessage(messages.min)}
					/>
				</div>
				<div className="w-full">
					<InputField
						type="number"
						name={`${filterIdsPrefix}-max-averageSalary`}
						id={`${filterIdsPrefix}-max-averageSalary`}
						placeholder="$5000000"
						required
						value={max}
						onChange={onChangeMaxAverageSalary}
						onBlur={onBlurMaxAverageSalary}
						labelValue={formatMessage(messages.max)}
					/>
				</div>
			</div>
		</Filter>
	);
};
