import * as React from "react";
import Image from "next/image";
import { FormattedMessage, useIntl } from "react-intl";

import { UniversitiesList } from "@common";

import BusinessProfileModel from "models/business-profiles/BusinessProfile";

import messages from "./messages";

interface UniversitiesProps {
	universitiesModels: BusinessProfileModel[];
}

export const Universities = ({ universitiesModels }: UniversitiesProps) => {
	const { formatMessage } = useIntl();

	return (
		<div className="px-20 pb-52">
			<div className="mb-20 flex w-full flex-col items-center justify-between gap-4">
				<div className="relative mb-12 h-96 w-full shadow-sm">
					<Image
						className="w-full object-fill"
						alt={formatMessage(messages.title)}
						src="/illustrations/329.svg"
						layout="fill"
					/>
				</div>
				<div className="mx-auto w-full max-w-xl text-center">
					<h1 className="text-2xl font-extrabold xl:text-4xl">
						<FormattedMessage {...messages.title} />
					</h1>
					<p className="text-sm font-medium text-neutral-600">
						<FormattedMessage {...messages.subTitle} />
					</p>
				</div>
			</div>
			<UniversitiesList
				universities={universitiesModels}
				loading={false}
			/>
		</div>
	);
};
