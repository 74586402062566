import { ChangeEventHandler } from "react";
import slugify from "react-slugify";
import * as React from "react";

import { InputField } from "@common";
import { PLATFORM_URL, PROD_URL } from "@api";
import { isValidUrl } from "@utils";

import { OnboardingInfoReducer } from "../../onboarding-reducer";

import messages from "./messages";
import { useIntl } from "react-intl";

interface InitialProfileForm {
	info: OnboardingInfoReducer;
	errors: Record<string, string | undefined>;
	onChangeSlug?: (slug: string) => void;
}

export const Slug = ({ info, errors, onChangeSlug }: InitialProfileForm) => {
	const { formatMessage } = useIntl();

	const handleOnChangeSlug: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
		const fullURL = PLATFORM_URL + value;

		if (isValidUrl(fullURL)) {
			let newSlug = value && slugify(value.toLowerCase());
			newSlug = newSlug.replace("-", "");

			if (onChangeSlug) {
				onChangeSlug(newSlug);
			}
		}
	};

	const getColorSlugField = () => {
		if (errors["slug"]) {
			return "failure";
		}

		if (info.slug.value.length > 0 && !info.slug.isLoading) {
			return "success";
		}

		return "gray";
	};

	return (
		<InputField
			type="text"
			name="profile-url"
			id="profile-url"
			sizing="md"
			placeholder="a-vinglet-name"
			required
			labelValue={formatMessage(messages.profileURL) + " *"}
			addon={PLATFORM_URL + "/"}
			value={info.slug.value}
			color={getColorSlugField()}
			errorMessage={errors["slug"]}
			loading={info.slug.isLoading}
			onChange={handleOnChangeSlug}
		/>
	);
};
