import { FormattedMessage } from "react-intl";
import * as React from "react";

import { Button } from "@common";

import { OnboardingButtonDirectionProps, OnboardingModalProps } from "./OnboardingModal";
import messages from "./messages";

type OnboardingFooterProps = {
	currentIndexActiveStep: number;
	totalNumberOfSteps: number;
	cancelButton: OnboardingButtonDirectionProps;
} & Pick<OnboardingModalProps, "continueButton" | "previousButton" | "finishButton" | "businessProfileType">;
export const OnboardingFooter = ({
	currentIndexActiveStep,
	totalNumberOfSteps,
	cancelButton,
	previousButton,
	continueButton,
	finishButton,
	businessProfileType,
}: OnboardingFooterProps) => {
	if (currentIndexActiveStep === 0) {
		return (
			<>
				<Button
					color="gray"
					size="xl"
					onClick={cancelButton.onPress}
					fluid
				>
					<FormattedMessage {...messages.cancel} />
				</Button>
				<Button
					size="xl"
					onClick={continueButton.onPress}
					loading={continueButton.loading}
					disabled={continueButton.disabled}
					fluid
				>
					<FormattedMessage {...messages.continue} />
				</Button>
			</>
		);
	}

	if (currentIndexActiveStep < totalNumberOfSteps - 1) {
		return (
			<>
				<Button
					color="gray"
					size="xl"
					onClick={previousButton.onPress}
					disabled={previousButton.disabled}
					fluid
				>
					<FormattedMessage {...messages.previous} />
				</Button>
				<Button
					size="xl"
					onClick={continueButton.onPress}
					loading={continueButton.loading}
					disabled={continueButton.disabled}
					fluid
				>
					<FormattedMessage {...messages.continue} />
				</Button>
			</>
		);
	}

	return (
		<>
			<Button
				color="gray"
				size="xl"
				onClick={previousButton.onPress}
				disabled={previousButton.disabled}
				fluid
			>
				<FormattedMessage {...messages.previous} />
			</Button>
			<Button
				size="xl"
				businessProfileType={businessProfileType}
				onClick={finishButton.onPress}
				loading={finishButton.loading}
				disabled={finishButton.disabled}
				fluid
			>
				<FormattedMessage {...messages.finish} />
			</Button>
		</>
	);
};
