import { defineMessages } from "react-intl";

import { MicroBusinessProfileTypes } from "constants/Profiles";

export default defineMessages({
	typeOfBusinessProfile: {
		defaultMessage: "Type of business profile",
	},
	averageInvestment: {
		defaultMessage: "Average investment",
	},
	averageEquity: {
		defaultMessage: "Average equity",
	},
	numberOfStartupsToInvest: {
		defaultMessage: "Number of start-ups to invest",
	},
});

export const investorTypesMessages = defineMessages<
	Extract<
		MicroBusinessProfileTypes,
		| MicroBusinessProfileTypes.BUSINESS_ANGEL
		| MicroBusinessProfileTypes.INCUBATOR
		| MicroBusinessProfileTypes.ACCELERATOR
	>
>({
	[MicroBusinessProfileTypes.BUSINESS_ANGEL]: {
		defaultMessage: "Business Angel",
	},
	[MicroBusinessProfileTypes.INCUBATOR]: {
		defaultMessage: "Incubator",
	},
	[MicroBusinessProfileTypes.ACCELERATOR]: {
		defaultMessage: "Accelerator",
	},
});
