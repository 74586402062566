import * as React from "react";
import Image from "next/image";
import dayjs from "dayjs";
import { FormattedDate, FormattedMessage } from "react-intl";
import { Spinner } from "flowbite-react";

import { useElearningLikesQuery } from "@api";
import { AcademicCap, Calendar, Card, Clock, HeroHeart, InternalLink } from "@common";

import messages from "./messages";

interface ElearningsCardProps {
	elearningId: number;
	title: string;
	elearningType: string;
	elearningModality: string;
	href: string;
	imgSrc: string;
	startDate: dayjs.Dayjs;
	endDate: dayjs.Dayjs;
	businessProfile: {
		name: string;
		href: string;
		imgSrc: string;
	};
}

export const ElearningsCard = ({
	elearningId,
	title,
	elearningType,
	elearningModality,
	href,
	imgSrc,
	startDate,
	endDate,
	businessProfile,
}: ElearningsCardProps) => {
	const { data: elearningLikes, isLoading: isElearningLikesLoading } = useElearningLikesQuery(elearningId, {
		staleTime: 1_000_000,
	});

	const getDurationOfTheElearning = () => {
		const diffInMonths = endDate.diff(startDate, "months");
		const diffInDays = endDate.diff(startDate, "days");
		const diffInHours = endDate.diff(startDate, "hours");

		if (diffInMonths > 0) {
			return (
				<FormattedMessage
					{...messages.diffInMonths}
					values={{
						months: diffInMonths,
					}}
				/>
			);
		}

		if (diffInDays > 0) {
			return (
				<FormattedMessage
					{...messages.diffInDays}
					values={{
						days: diffInDays,
					}}
				/>
			);
		}

		if (diffInHours == 0) {
			return (
				<FormattedMessage
					{...messages.diffInHours}
					values={{
						hours: 1,
					}}
				/>
			);
		}

		return (
			<FormattedMessage
				{...messages.diffInHours}
				values={{
					hours: diffInHours,
				}}
			/>
		);
	};

	const getStartDateOfTheElearning = () => {
		const currentYear = dayjs().year();

		if (currentYear != startDate.year()) {
			return (
				<FormattedMessage
					{...messages.startOn}
					values={{
						date: (
							<FormattedDate
								value={startDate.toDate()}
								year="numeric"
								month="long"
								day="2-digit"
							/>
						),
					}}
				/>
			);
		}

		return (
			<FormattedMessage
				{...messages.startOn}
				values={{
					date: (
						<FormattedDate
							value={startDate.toDate()}
							month="long"
							day="2-digit"
						/>
					),
				}}
			/>
		);
	};

	return (
		<Card
			className="h-full w-full max-w-none"
			imgAlt={title}
			imgSrc={imgSrc}
			imgHref={href}
		>
			<div className="flex h-full flex-col justify-between text-left">
				<InternalLink
					href={href}
					className="mb-3 block"
				>
					<h5 className="mt-0 text-lg font-semibold capitalize tracking-tight text-gray-900 line-clamp-2 hover:text-blue-600 dark:text-white">
						{title}
					</h5>
				</InternalLink>
				<ul className="mb-6 flex w-full flex-1 flex-col gap-4 text-neutral-500">
					<li className="flex gap-2">
						<HeroHeart className="h-6 w-6 text-red-600" />
						<span className="text-neutral-500">
							{isElearningLikesLoading ? (
								<div className="opacity-40">
									<Spinner
										aria-label="Extra small spinner example"
										color="gray"
										size="xs"
									/>
								</div>
							) : (
								elearningLikes?.totalLikes || 0
							)}
						</span>
					</li>

					<li className="flex gap-2">
						<AcademicCap className="h-6 w-6" />
						<span>
							{elearningType} - {elearningModality}
						</span>
					</li>

					<li className="flex gap-2">
						<Clock className="h-6 w-6" />
						<span>
							<FormattedMessage
								{...messages.duration}
								values={{ duration: getDurationOfTheElearning() }}
							/>
						</span>
					</li>

					<li className="flex gap-2">
						<Calendar className="h-6 w-6" />
						<span>{getStartDateOfTheElearning()}</span>
					</li>
				</ul>
				<div className="flex items-center space-x-4">
					<div className="relative h-10 w-10 shrink-0 rounded-full border border-2 border-green-100">
						<InternalLink href={businessProfile.href}>
							<Image
								className="rounded-full"
								src={businessProfile.imgSrc}
								alt={businessProfile.name}
								layout="fill"
							/>
						</InternalLink>
					</div>
					<div className="font-medium text-neutral-700 dark:text-white">
						<InternalLink href={businessProfile.href}>
							<div className="capitalize">{businessProfile.name}</div>
						</InternalLink>
					</div>
				</div>
			</div>
		</Card>
	);
};
