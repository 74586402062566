import * as React from "react";
import { FileUpoaderProps } from "./typing";
import { useDropzone } from "react-dropzone";

// We need to declare the styles like this in order to follow the documentation of React Dropzone
const baseStyle = {
	flex: 1,
	height: "inherit",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "20px",
	borderWidth: 2,
	borderRadius: 2,
	borderColor: "#eeeeee",
	borderStyle: "dashed",
	backgroundColor: "#fafafa",
	color: "#bdbdbd",
	outline: "none",
	transition: "border .24s ease-in-out",
	cursor: "pointer",
};

const activeStyle = {
	borderColor: "#2196f3",
};

const acceptStyle = {
	borderColor: "#00e676",
};

const rejectStyle = {
	borderColor: "#ff1744",
};

const FileUploader = (props: FileUpoaderProps) => {
	const { acceptFiles, maxFiles, uploading, helpText, onChangeAcceptedFiles } = props;
	const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
		accept: acceptFiles,
		disabled: uploading,
		maxFiles,
	});
	const style = React.useMemo(
		() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isDragActive, isDragReject, isDragAccept],
	) as React.CSSProperties;

	React.useEffect(() => {
		onChangeAcceptedFiles(acceptedFiles);
	}, [acceptedFiles]);

	return (
		<section className="FileUploader">
			<div {...getRootProps({ style })}>
				<input {...getInputProps()} />
				<p> {helpText} </p>
			</div>
		</section>
	);
};

export default FileUploader;
