import * as React from "react";
import Image from "next/image";
import { FormattedMessage } from "react-intl";

import { Button, Card, InternalLink, PencilSquare } from "@common";

import ChallengeTenderModel from "models/challenge-tenders/ChallengeTender";

import messages from "./messages";

interface ChallengeTenderGeneralProps {
	tender: ChallengeTenderModel;
}

export const TenderImages = ({ tender }: ChallengeTenderGeneralProps) => {
	const noImagesYet = tender.images.length === 0;

	if (noImagesYet && !tender.isMine) {
		return <></>;
	}

	if (noImagesYet) {
		return (
			<Card className="w-full max-w-none p-20">
				<div>
					<p className="margin-2-y text-gray-500">
						<FormattedMessage {...messages.missingImages} />
					</p>
					<InternalLink href={`/tenders/${tender.id}/settings?activeSection=images`}>
						<Button color="gray">
							<div className="flex w-full w-full items-center gap-2 text-lg">
								<PencilSquare className="relative -top-0.5 h-6 w-6" />
								<span>
									<FormattedMessage {...messages.editImages} />
								</span>
							</div>
						</Button>
					</InternalLink>
				</div>
			</Card>
		);
	}

	return (
		<Card className="w-full max-w-none p-20">
			<div>
				<div className="grid grid-cols-3 gap-4">
					{tender.images &&
						tender.getFormatedImages().map((image) => (
							<div
								className="relative aspect-square w-full"
								key={image.src}
							>
								<Image
									className="h-full w-full object-cover"
									layout="fill"
									alt={image.alt}
									src={image.src}
								/>
							</div>
						))}
					{tender.isMine && (
						<InternalLink
							className="flex h-full w-full flex-col justify-center"
							href={`/tenders/${tender.id}/settings?activeSection=images`}
						>
							<Button
								fluid
								color="gray"
							>
								<div className="flex w-full w-full items-center gap-2 text-lg">
									<PencilSquare className="relative -top-0.5 h-6 w-6" />
									<span>
										<FormattedMessage {...messages.editImages} />
									</span>
								</div>
							</Button>
						</InternalLink>
					)}
				</div>
			</div>
		</Card>
	);
};
