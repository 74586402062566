import * as React from "react";

import { useAnalytics } from "@libs";
import { SwitchLanguage } from "@common";

import { OnboardingHeader } from "./OnboardingHeader";
import { OnboardingFooter } from "./OnboardingFooter";

export interface OnboardingButtonDirectionProps {
	onPress: () => void;
	loading?: boolean;
	disabled?: boolean;
}

export interface OnboardingProps {
	stepNames: string[];
	currentIndexActiveStep: number;
	children: JSX.Element | JSX.Element[];
	continueButton: OnboardingButtonDirectionProps;
	previousButton: OnboardingButtonDirectionProps;
	finishButton: OnboardingButtonDirectionProps;
}

export const Onboarding = ({
	children,
	stepNames,
	currentIndexActiveStep,
	continueButton,
	previousButton,
	finishButton,
}: OnboardingProps) => {
	const { event } = useAnalytics();
	const countOfChildren = React.Children.count(children);

	if (countOfChildren !== stepNames.length) {
		throw Error("There is a mismatch between the number of step names and the amount of children");
	}

	const handleOnContinue = async () => {
		continueButton.onPress();

		await event("PASS_STEP_ONBOARDING_REGULAR_PROFILE", {
			step: stepNames[currentIndexActiveStep],
			stepIndex: currentIndexActiveStep,
			amountOfSteps: stepNames.length,
		});
	};

	const handleOnPrevious = async () => {
		previousButton.onPress();

		await event("PREVIOUS_STEP_ONBOARDING_REGULAR_PROFILE", {
			step: stepNames[currentIndexActiveStep],
			stepIndex: currentIndexActiveStep,
			amountOfSteps: stepNames.length,
		});
	};

	const handleOnFinish = async () => {
		finishButton.onPress();

		await event("FINISH_ONBOARDING_REGULAR_PROFILE");
	};

	return (
		<div className="mx-auto mb-20 max-w-7xl">
			<header>
				<div className="w-full">
					<OnboardingHeader
						stepsNames={stepNames}
						currentIndexActiveStep={currentIndexActiveStep}
					/>
				</div>
			</header>
			<section>{React.Children.toArray(children)[currentIndexActiveStep]}</section>
			<footer className="relative">
				<div className="flex w-full justify-center">
					<div className="mt-6 flex w-72 flex-col justify-center gap-4">
						<OnboardingFooter
							currentIndexActiveStep={currentIndexActiveStep}
							totalNumberOfSteps={countOfChildren}
							continueButton={{
								...continueButton,
								onPress: handleOnContinue,
							}}
							previousButton={{
								...previousButton,
								onPress: handleOnPrevious,
							}}
							finishButton={{
								...finishButton,
								onPress: handleOnFinish,
							}}
						/>
					</div>
				</div>
			</footer>
			<div className="mt-12 flex justify-center text-center md:justify-end md:text-left">
				<div className="w-44">
					<SwitchLanguage />
				</div>
			</div>
		</div>
	);
};
