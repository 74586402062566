import { FormattedMessage, useIntl } from "react-intl";
import { Alert } from "flowbite-react";

import { useTendersByChallengeIdQuery } from "@api";
import { Breadcrumbs, BreadcrumbsItemProps, Card, Container, InformationCircle } from "@common";

import ChallengeModel from "models/challenges/Challenge";
import ChallengeTenderModel from "models/challenge-tenders/ChallengeTender";
import ProfileModel from "models/profiles/Profile";

import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";

import { TenderCard } from "./TenderCard/TenderCard";
import messages from "./messages";
import {useLocales} from "@libs";

export interface ChallengeTendersDashboardProps {
	challenge: ChallengeModel;
}

export const ChallengesTendersDashboard = ({ challenge }: ChallengeTendersDashboardProps) => {
	const { formatMessage } = useIntl();
	const { data: challengeTenders, isLoading } = useTendersByChallengeIdQuery(challenge.id);
	const {currentShortLocale} = useLocales();

	if (isLoading) {
		return (
			<Loader
				className="margin-4-top block"
				active
				inline={"centered"}
			/>
		);
	}

	const challengeTendersModels =
		challengeTenders?.map((tender) => {
			const challengeTenderModel = ChallengeTenderModel.generateFromAPI(tender);
			const admin = ProfileModel.generateEmpty();

			admin.id = String(tender.admin.id);
			admin.info.name = tender.admin.name;
			admin.links.link = tender.admin.slug;

			challengeTenderModel.setAdmin(admin);

			return challengeTenderModel;
		}) || [];

	const breadCrumbLists: BreadcrumbsItemProps[] = [
		{
			text: challenge.title,
			href: challenge.getInternalURL(currentShortLocale),
		},
		{
			text: formatMessage(messages.proposals),
		},
	];

	return (
		<section>
			<header>
				<Breadcrumbs
					items={breadCrumbLists}
					ariaLabel=""
				/>
				<Container className="flex flex-col items-center justify-between gap-8 py-20 lg:flex-row">
					<div className="flex-1 text-center lg:text-left">
						<sup className="text-lg font-bold uppercase text-neutral-400">
							<FormattedMessage {...messages.proposals} />
						</sup>
						<h1 className="mt-0">{challenge.title}</h1>
					</div>
					<Card className="p-4 text-center">
						<span className="text-md font-bold">
							<FormattedMessage {...messages.createdUntilNow} />
						</span>
						<p className="text-xl font-semibold text-indigo-800">
							<FormattedMessage
								{...messages.numberOfProposals}
								values={{
									number: challengeTendersModels.length,
								}}
							/>
						</p>
					</Card>
				</Container>
			</header>
			<Container className="pb-32">
				{challengeTendersModels.length === 0 ? (
					<Alert
						color="warning"
						icon={InformationCircle}
					>
						<FormattedMessage {...messages.thereAreNoTendersCreatedYet} />
					</Alert>
				) : (
					<ul className="columns-1 gap-4 lg:columns-2 xl:columns-3">
						{challengeTendersModels.map((tender) => (
							<li
								className="break-inside-avoid break-inside-avoid-page break-inside-avoid-column"
								key={tender.id}
							>
								<TenderCard
									displayExtraContent
									tender={tender}
								/>
							</li>
						))}
					</ul>
				)}
			</Container>
		</section>
	);
};
