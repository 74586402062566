import { Translation } from "i18n/config";
import { SocialProfileModalTranslation } from "./typing";

export const SocialProfileModalTexts: Translation<SocialProfileModalTranslation> = {
	es: {
		header: "Social",
		description: "",
		customLink: "mi-enlace",
	},
	en: {
		header: "Social",
		description: "",
		customLink: "my-custom-link",
	},
	ca: {
		header: "Social",
		description: "",
		customLink: "meu-enllaç",
	},
	pt: {
		header: "Social",
		description: "",
		customLink: "meu-link",
	},
};
