import { defineMessages } from "react-intl";

export default defineMessages({
	knowTheTeam: {
		defaultMessage: "Know the team",
	},
	admin: {
		defaultMessage: "Administrator",
	},
	seeMore: {
		defaultMessage: "See more",
	},
});
