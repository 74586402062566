import { defineMessages } from "react-intl";

export default defineMessages({
	type: {
		defaultMessage: "Type",
	},
	offer: {
		defaultMessage: "Offer type",
	},
	sectors: {
		defaultMessage: "Sectors",
	},
	regions: {
		defaultMessage: "Regions",
	},
	quantity: {
		defaultMessage: "Quantity",
	},
	min: {
		defaultMessage: "Minimum",
	},
	max: {
		defaultMessage: "Maximum",
	},
	price: {
		defaultMessage: "Price (USD)",
	},
	discount: {
		defaultMessage: "Discount",
	},
	currentDiscountFilter: {
		defaultMessage: "From {discount}%",
	},
});
