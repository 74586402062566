import {ButtonsTexts} from "i18n/components/Buttons";
import {ButtonsTranslation} from "i18n/components/Buttons/typing";
import * as React from "react";
import ChallengeTenderModel from "models/challenge-tenders/ChallengeTender";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import List from "semantic-ui-react/dist/commonjs/elements/List";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import {CookiesUtils} from "@utils";
import FileUploader from "elements/FileUpoader/FileUpoader";
import {fetchDocument} from "api/FileAPI";
import {URL_VINGLET_DOCUMENT} from "constants/URLs";
import {ChallengesTexts} from "i18n/components/Challenges/index";
import {ChallengesTranslation} from "i18n/components/Challenges/typing";
import {useLocales} from "@libs";

const MAX_DOCUMENTS = 5;

const UnitChallengeTendersFieldFiles = (props: {
    tender: ChallengeTenderModel;
    requestLoading: boolean;
    setTender: (tender: ChallengeTenderModel) => void;
    patchChallengeTender: (tenderData: any) => void;
}) => {
    const {tender, requestLoading, setTender, patchChallengeTender} = props;
    const {currentShortLocale} = useLocales();
    const challengesTranslations: ChallengesTranslation = {
        ...ChallengesTexts[currentShortLocale],
    };

    const buttonTranslation: ButtonsTranslation = {
        ...ButtonsTexts[currentShortLocale],
    };
    const [files, setFiles] = React.useState([] as File[]);
    const [isUploading, setIsUploading] = React.useState(false);
    const handleOnChangeFile = (files: File[]) => {
        setFiles(files);
    };
    const handleOnSaveFilesChanges = () => {
        setIsUploading(true);

        const accessToken = CookiesUtils.getAccessTokenFromCookie();
        const filePromises: Promise<any>[] = [];

        files.forEach((file) => {
            const formData = new FormData();
            formData.append("file", file);

            filePromises.push(fetchDocument(formData, file.type, accessToken)());
        });

        Promise.all(filePromises)
            .then((files) => {
                const flatFiles = files.map((file) => file[0]);
                const newTenderObject = ChallengeTenderModel.generateFromObject({
                    ...tender,
                    documents: [...flatFiles, ...tender.documents],
                });

                setTender(newTenderObject);
                setFiles([]);
                patchChallengeTender(newTenderObject.exportToAPIRequest());
            })
            .finally(() => {
                setIsUploading(false);
            });
    };
    const handleOnRemoveFile = (documentName: string) => () => {
        const filteredFiles = tender.documents.filter((document) => document !== documentName);
        const newTenderObject = ChallengeTenderModel.generateFromObject({
            ...tender,
            documents: filteredFiles,
        });

        setTender(newTenderObject);
        patchChallengeTender(newTenderObject.exportToAPIRequest());
    };
    const isAValidDocument = (document?: string) => Boolean(document);
    const getNumberOfSuccesfullyUploadedFiles = () => {
        return tender.documents && Array.isArray(tender.documents)
            ? tender.documents.filter((document) => isAValidDocument(document)).length
            : 0;
    };
    const isUpperLimitExcedeed = files.length + getNumberOfSuccesfullyUploadedFiles() > MAX_DOCUMENTS;

    return (
        <>
            <section className="full-width container">
                <h3 className="font-color-grey">Documentos Anexos</h3>
                <List
                    divided
                    relaxed
                >
                    {tender.documents &&
                        tender.documents.map((document, index) => (
                            <List.Item key={document}>
                                <List.Content floated="right">
                                    {isAValidDocument(document) ? (
                                        <Button
                                            onClick={handleOnRemoveFile(document)}>{challengesTranslations.remove}</Button>
                                    ) : (
                                        <Button
                                            disabled
                                            basic
                                            color={"red"}
                                        >
                                            {challengesTranslations.errorUploadingFiles}
                                        </Button>
                                    )}
                                </List.Content>
                                <List.Icon
                                    name="file"
                                    size="large"
                                    verticalAlign="middle"
                                />
                                <List.Content>
                                    <List.Header
                                        as="a"
                                        href={URL_VINGLET_DOCUMENT + document}
                                        target="__blank"
                                    >
                                        Documento Adjunto {index + 1}
                                    </List.Header>
                                </List.Content>
                            </List.Item>
                        ))}
                </List>
                <p>{challengesTranslations.addDocumentsDescription}</p>
                <p>
                    <b>
                        {" "}
                        - {challengesTranslations.max} {MAX_DOCUMENTS} {challengesTranslations.files}{" "}
                    </b>
                </p>
                <p>
                    <b> - {challengesTranslations.pdfFormat} </b>
                </p>
                <p>
                    <b> - {challengesTranslations.limit5MB} </b>
                </p>
                <p>{challengesTranslations.importantResponsability}</p>
                <FileUploader
                    helpText={challengesTranslations.dragPDF}
                    acceptFiles={{
                        "application/pdf": [".pdf"],
                        "application/vnd.ms-excel": [".xls", ".xlsx"],
                        "application/vnd.ms-powerpoint": [".ppt", ".pptx"],
                        "application/msword": [".doc", ".docx"],
                    }}
                    maxFiles={MAX_DOCUMENTS}
                    uploading={isUploading}
                    onChangeAcceptedFiles={handleOnChangeFile}
                />
                {files &&
                    files.map((file) => (
                        <aside
                            key={file.lastModified}
                            className="margin-1-y"
                        >
                            <b>{challengesTranslations.selectedFile}</b>
                            <li>- {file.name}</li>
                        </aside>
                    ))}
            </section>
            {isUpperLimitExcedeed && (
                <Message
                    color="yellow"
                    header={challengesTranslations.maxDocsTitle}
                    content={
                        challengesTranslations.maxDocsDescFirstPart + MAX_DOCUMENTS + challengesTranslations.maxDocsDescSecondPart
                    }
                />
            )}
            <Button
                className="padding-3-x margin-1-top"
                color="green"
                disabled={requestLoading || files.length === 0 || isUpperLimitExcedeed}
                loading={requestLoading}
                onClick={handleOnSaveFilesChanges}
            >
                <Icon name="save"/>
                {buttonTranslation.save}
            </Button>
        </>
    );
};

export default UnitChallengeTendersFieldFiles;
