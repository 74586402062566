import * as React from "react";
import Image from "next/image";
import { FormattedMessage } from "react-intl";
import { Badge } from "flowbite-react";

import { Adviser, CategoryType } from "@api";
import { Beaker, Briefcase, Card, InternalLink, LocationMarker } from "@common";

import BusinessProfileModel from "models/business-profiles/BusinessProfile";

import messages from "./messages";
import CategoriesServiceSingleton from "../../../../app__Legacy/services/categories/CategoriesService";

type AdviserCardProps = Adviser;

const MAX_CATEGORIES_PER_FEATURE = 5;

export const AdviserCard = ({ profile, percentage, cost, categories }: AdviserCardProps) => {
	const formatterSalary = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: 0,
	});
	const businessProfile = BusinessProfileModel.generateFromAPI(profile);

	const categoryList = CategoriesServiceSingleton.getList();
	const adviserCategoryList = categoryList.filterByCategoryType(CategoryType.ADVISER_SKILLS, true);
	const selectedAdviserSkillsNames = categories?.map((category) => category.name);
	const adviserSkills = adviserCategoryList.filter((adviserCategoryModel) =>
		selectedAdviserSkillsNames.includes(adviserCategoryModel.key),
	);

	return (
		<Card
			imgAlt={businessProfile.info.fullName}
			imgSrc={businessProfile.info.avatar.src}
			imgHref={businessProfile.info.url}
			imgClassName="aspect-square w-44 mx-auto -mt-16 rounded-xl shadow-lg overflow-hidden bg-white border border-neutral-100"
			className="relative h-full w-full max-w-none"
		>
			<div className="flex w-full flex-col justify-center text-left">
				<InternalLink href={businessProfile.info.url}>
					<h4 className="mb-0 text-xl capitalize tracking-tight text-gray-900 line-clamp-1 dark:text-white">
						{businessProfile.info.fullName}
					</h4>
				</InternalLink>
				<div className="flex items-center gap-0.5">
					<label className="text-neutral-500">
						<LocationMarker className="h-4 w-4" />
					</label>
					<span className="text-neutral-500">{businessProfile.info.country.text}</span>
				</div>
			</div>
			<ul className="mt-4 mb-2">
				<li className="mb-1 flex gap-2">
					<label className="text-neutral-500">
						<FormattedMessage {...messages.costPerHour} />:
					</label>
					<span className="font-semibold text-violet-600">{formatterSalary.format(cost)}</span>
				</li>
				<li className="flex gap-2">
					<label className="text-neutral-500">
						<FormattedMessage {...messages.equity} />:
					</label>
					<span className="font-semibold text-emerald-500">{percentage}%</span>
				</li>
				{adviserSkills.length > 0 && (
					<li className="mt-6 flex gap-2">
						<Beaker className="h-6 w-6 shrink-0" />
						<div className="flex flex-wrap gap-2">
							{adviserSkills?.slice(0, MAX_CATEGORIES_PER_FEATURE).map((adviserSkill) => (
								<Badge
									key={adviserSkill.key}
									color="indigo"
								>
									{adviserSkill.text}
								</Badge>
							))}
						</div>
					</li>
				)}
			</ul>
		</Card>
	);
};
