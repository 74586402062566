import { defineMessages } from "react-intl";

export default defineMessages({
	seeMore: {
		defaultMessage: "See more",
	},
	knowTheMentors: {
		defaultMessage: "Know the mentors",
	},
	mentor: {
		defaultMessage: "Mentor",
	},
});
