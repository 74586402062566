import classNames from "classnames";
import { Pagination } from "flowbite-react";
import { useRouter } from "next/router";
import queryString from "query-string";

import { getStringifiedList, useDebounce } from "@libs";
import { useStartupsQuery } from "@api";

import BusinessProfileModel from "models/business-profiles/BusinessProfile";

import { StartupCardLoading } from "../../StartupCard/StartupCardLoading";
import { StartupCard } from "../../StartupCard/StartupCard";
import { StartupsFilters } from "../StartupsExplorerFilters/StartupsExplorerFilters";

export interface StartupsExplorerListProps {
	filters: StartupsFilters;
}

export const StartupsExplorerList = ({
	filters: { regionsIds, sectorsIds, pageNumber },
}: StartupsExplorerListProps) => {
	const router = useRouter();
	const debouncedQueryParams = useDebounce(
		{
			page: pageNumber ? pageNumber : 1,
			perPage: 12,
			"search[regions]": regionsIds,
			"search[markets]": sectorsIds,
		},
		1000,
	);
	const { data: startups, isLoading } = useStartupsQuery(debouncedQueryParams, {
		enabled: Boolean(debouncedQueryParams),
	});

	const startupsModels = startups?.items.map((startup) => BusinessProfileModel.generateFromAPI(startup));

	const handleOnPageChange = async (newPageNumber: number) => {
		await router.push(
			router.pathname +
				`?page=${newPageNumber}&${queryString.stringify(
					{
						sectorsIds: getStringifiedList(sectorsIds),
						regionsIds: getStringifiedList(regionsIds),
					},
					{
						skipEmptyString: true,
						skipNull: true,
					},
				)}`,
		);
	};

	const getTotalPages = () => (startups?.num_pages ? startups?.num_pages : 0);

	return (
		<>
			<ul
				className={classNames(
					"mt-12 w-full text-center",
					"grid gap-4 gap-y-16",
					"grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4",
				)}
			>
				{isLoading
					? [1, 2, 3, 4, 5, 6, 7, 8].map((index) => <StartupCardLoading key={index} />)
					: startupsModels?.map((startup) => (
							<li
								key={startup.info.id}
								className="h-full w-full flex-1"
								data-testid={`STARTUP_${startup.info.id}`}
							>
								<StartupCard {...startup} />
							</li>
					  ))}
			</ul>
			{getTotalPages() > 1 && (
				<div className="mt-24 w-full text-center">
					<Pagination
						currentPage={pageNumber}
						layout={"pagination"}
						totalPages={getTotalPages()}
						onPageChange={handleOnPageChange}
					/>
				</div>
			)}
		</>
	);
};
