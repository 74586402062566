import { ChangeEventHandler } from "react";
import { useIntl } from "react-intl";

import { Filter, InputCheckboxField, ShowMoreToggle } from "@common";

import CategoryModel from "models/categories/Category";

import messages from "../messages";

type CategoriesCounter = number;

interface ModalitiesProps {
	modalities: [CategoryModel, CategoriesCounter][];
	modalitiesIdsClicked: Record<number, boolean>;
	onChangeOneModality: (categoryId: number) => void;
	filterIdsPrefix: string;
}

export const Modalities = ({
	modalities,
	modalitiesIdsClicked,
	onChangeOneModality,
	filterIdsPrefix,
}: ModalitiesProps) => {
	const { formatMessage } = useIntl();

	return (
		<Filter
			id="modalities"
			title={formatMessage(messages.modalities)}
		>
			<ShowMoreToggle
				initialValue={false}
				className="flex flex-col gap-2"
				items={modalities.map(([modality, counter]) => {
					const handleOnChangeModality = () => onChangeOneModality(Number(modality.id));

					return (
						<InputCheckboxField
							key={modality.key}
							id={filterIdsPrefix + String(modality.id)}
							name={modality.key}
							checked={modalitiesIdsClicked[modality.id as number]}
							onChange={handleOnChangeModality}
							labelValue={
								<p className="flex w-full">
									<span className="flex-1"> {modality.text} </span>
									<span className="shrink-0 text-neutral-400"> ({counter}) </span>
								</p>
							}
						/>
					);
				})}
			/>
		</Filter>
	);
};
