import * as React from "react";
import Image from "next/image";

import { InternalLink } from "@common";

interface TeamUserProps {
	profileLink: string;
	profileImage: string;
	profileFullName: string;
	rolName: string;
}

export const TeamUser = ({ profileLink, profileImage, profileFullName, rolName }: TeamUserProps) => {
	return (
		<div className="p-4">
			<div className="flex  flex-col items-center justify-center">
				<div className="flex-shrink-0">
					<InternalLink
						className="relative mx-auto block h-20 w-20"
						href={`/profile/${profileLink}`}
					>
						<Image
							className="rounded-full object-cover"
							layout="fill"
							src={profileImage}
							alt={profileFullName}
						/>
					</InternalLink>
				</div>
				<div className="mt-2 flex flex-col text-center">
					<InternalLink
						href={`/profile/${profileLink}`}
						className="text-lg font-medium text-gray-600 dark:text-white"
					>
						{profileFullName}
					</InternalLink>
					<span className="text-xs text-gray-400">{rolName}</span>
				</div>
			</div>
		</div>
	);
};
