import { defineMessages } from "react-intl";

export default defineMessages({
	personalSkills: {
		defaultMessage: "Personal skills",
	},
	selectSkills: {
		defaultMessage: "Select the skills you feel that identify better to you",
	},
});
