import { useEffect, useState } from "react";
import { Modal } from "flowbite-react";
import Image from "next/image";
import { FormattedMessage } from "react-intl";

import { useAddMentorToTenderMutation, useRemoveMentorFromTenderMutation } from "@api";
import { Button, InternalLink } from "@common";
import { useToggle } from "@libs";

import BusinessProfileModel from "models/business-profiles/BusinessProfile";

import messages from "./messages";

export const TenderMentorsSelectorModal = ({
	tenderId,
	challengeSlug,
	challengeMentors,
	selectedConsultantsIds = [],
}: {
	tenderId: number;
	challengeSlug: string;
	challengeMentors: BusinessProfileModel[];
	selectedConsultantsIds: number[];
}) => {
	const [open, { toggleOn, toggleOff }] = useToggle();
	const [newSelectedConsultantsIds, setNewSelectedConsultantsIds] = useState(selectedConsultantsIds);
	const { mutate: addMentorToTenderMutation, isLoading: isAddingMentorLoading } = useAddMentorToTenderMutation(
		tenderId,
		challengeSlug,
	);
	const { mutate: removeMentorFromTenderMutation, isLoading: isRemovingMentorLoading } =
		useRemoveMentorFromTenderMutation(tenderId);
	const handleOnApply = () => {
		const mentorsToRemove = challengeMentors.filter(
			(challengeMentors) =>
				!newSelectedConsultantsIds.includes(challengeMentors.info.id) &&
				selectedConsultantsIds.includes(challengeMentors.info.id),
		);
		const mentorsToAdd = challengeMentors.filter(
			(challengeMentors) =>
				newSelectedConsultantsIds.includes(challengeMentors.info.id) &&
				!selectedConsultantsIds.includes(challengeMentors.info.id),
		);

		mentorsToAdd.forEach((mentor) => {
			addMentorToTenderMutation(mentor.info.slug.value);
		});

		mentorsToRemove.forEach((mentor) => {
			removeMentorFromTenderMutation(mentor.info.id);
		});

		toggleOff();
	};

	const getMentorItem = (mentor: BusinessProfileModel) => {
		const mentorId = mentor.info.id;
		const isMentorSelected = newSelectedConsultantsIds.includes(mentorId);

		const handleOnSelectMentor = () => {
			setNewSelectedConsultantsIds([...newSelectedConsultantsIds, mentorId]);
		};
		const handleUnselectMentor = () => {
			const consultantsIdsWithoutCurrentMentorId = newSelectedConsultantsIds.filter(
				(newSelectedConsultantsId) => newSelectedConsultantsId !== mentorId,
			);
			setNewSelectedConsultantsIds(consultantsIdsWithoutCurrentMentorId);
		};

		return (
			<li key={mentor.info.id}>
				<div className="w-full rounded-lg border-2 border-gray-200 bg-white p-5 text-gray-500 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:text-gray-300">
					<div className="flex w-full flex-col justify-between gap-4 text-center">
						<div className="flex items-center justify-around">
							<InternalLink
								className="relative ml-2 block h-20 w-20 shrink-0"
								href={`/profile/${mentor.info.url}`}
							>
								<Image
									className="rounded-full object-cover"
									layout="fill"
									src={mentor.info.avatar.src}
									alt={mentor.info.fullName}
								/>
							</InternalLink>
							<div className="flex-1">
								<InternalLink
									href={mentor.info.url}
									className="w-full text-lg font-semibold"
								>
									{mentor.info.fullName}
								</InternalLink>
							</div>
						</div>
						{isMentorSelected ? (
							<Button
								fluid
								color="warning"
								onClick={handleUnselectMentor}
							>
								<FormattedMessage {...messages.mentorAlreadySelected} />
							</Button>
						) : (
							<Button
								fluid
								color="gray"
								onClick={handleOnSelectMentor}
							>
								<FormattedMessage {...messages.selectMentor} />
							</Button>
						)}
					</div>
				</div>
			</li>
		);
	};

	useEffect(() => {
		setNewSelectedConsultantsIds(selectedConsultantsIds);
	}, [selectedConsultantsIds]);

	return (
		<>
			<Button
				color="purple"
				onClick={toggleOn}
				loading={isAddingMentorLoading || isRemovingMentorLoading}
				disabled={isAddingMentorLoading || isRemovingMentorLoading}
				size="xl"
			>
				<FormattedMessage {...messages.addNewMentors} />
			</Button>
			<Modal
				show={open}
				position="center"
				size="3xl"
				onClose={toggleOff}
			>
				<Modal.Header>
					<FormattedMessage {...messages.addNewMentors} />
				</Modal.Header>
				<Modal.Body>
					<ul className="grid max-h-[35rem] grid-cols-2 gap-4 overflow-y-scroll px-4">
						{challengeMentors.map((mentor) => getMentorItem(mentor))}
					</ul>
				</Modal.Body>
				<Modal.Footer>
					<div className="flex w-full justify-end gap-2">
						<Button
							color="purple"
							size="xl"
							loading={isAddingMentorLoading || isRemovingMentorLoading}
							disabled={isAddingMentorLoading || isRemovingMentorLoading}
							onClick={handleOnApply}
						>
							<FormattedMessage {...messages.applyNewMentors} />
						</Button>
						<Button
							color="gray"
							size="xl"
							loading={isAddingMentorLoading || isRemovingMentorLoading}
							disabled={isAddingMentorLoading || isRemovingMentorLoading}
							onClick={toggleOff}
						>
							<FormattedMessage {...messages.cancel} />
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
};
