import { defineMessages } from "react-intl";

import { ElearningType } from "constants/Elearning";
import { ElearningTypeSearch } from "@api";

export const elearningTypesMessages = defineMessages<ElearningType>({
	[ElearningType.Course]: {
		defaultMessage: "Courses",
	},
	[ElearningType.Degree]: {
		defaultMessage: "Degrees",
	},
	[ElearningType.DoctorDegree]: {
		defaultMessage: "Doctor's Degrees",
	},
	[ElearningType.Master]: {
		defaultMessage: "Masters",
	},
	[ElearningType.PostgraduateDegree]: {
		defaultMessage: "Postgraduate's Degrees",
	},
	[ElearningType.Webinar]: {
		defaultMessage: "Webinars",
	},
	[ElearningType.Workshop]: {
		defaultMessage: "Workshops",
	},
});

const courseHeaderMessage = defineMessages({
	title: {
		defaultMessage: "Find your perfect course",
	},
	subTitle: {
		defaultMessage: "Choose among all the courses to excel your skills",
	},
	courses: {
		defaultMessage: "Courses",
	},
	elearningsHome: {
		defaultMessage: "Elearnings Home",
	},
});

const webinarHeaderMessage = defineMessages({
	title: {
		defaultMessage: "Webinars prepared by professionals around the world",
	},
	subTitle: {
		defaultMessage: "Choose among all the webinars to excel your skills",
	},
	courses: {
		defaultMessage: "Webinars",
	},
	elearningsHome: {
		defaultMessage: "Elearnings Home",
	},
});

const workshopHeaderMessage = defineMessages({
	title: {
		defaultMessage: "Workshops to master new skills and exercise your talents",
	},
	subTitle: {
		defaultMessage: "Choose the best workshops",
	},
	courses: {
		defaultMessage: "Workshops",
	},
	elearningsHome: {
		defaultMessage: "Elearnings Home",
	},
});

export const headersMessages: Record<ElearningTypeSearch, any> = {
	Course: courseHeaderMessage,
	Webinar: webinarHeaderMessage,
	Workshop: workshopHeaderMessage,
};
