import { defineMessages } from "react-intl";

export default defineMessages({
	investByYear: {
		defaultMessage: "Startup investment per year",
	},
	numberOfStartupsPerYear: {
		defaultMessage: "Taking equity",
	},
	averageYear: {
		defaultMessage: "Average Startups to invest",
	},
});
