import { defineMessages } from "react-intl";

export default defineMessages({
	adminBy: {
		defaultMessage: "Admin by {administrator}",
	},
	missingDescription: {
		defaultMessage: "This proposal has no description",
	},
	challengesDescription: {
		defaultMessage: "Enter the description of your proposal right here",
	},
	relatedProfiles: {
		defaultMessage: "Involved Profiles",
	},
	documents: {
		defaultMessage: "Documents",
	},
	downloadAllDocuments: {
		defaultMessage: "Download all documents",
	},
	youDontHavePermissionToDownloadDocuments: {
		defaultMessage: "You don't have permissions to download the documents",
	},
	noDocumentsYet: {
		defaultMessage: "There are no uploaded documents yet",
	},
});
