import { ChangeEventHandler } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { MarketplaceOffer } from "@api";
import { Tag, Filter, Select } from "@common";

import messages from "../messages";

import { marketplaceOfferMessages } from "./messages";

interface OfferProps {
	onChangeOffer: (marketplaceOffer?: MarketplaceOffer) => void;
	currentSelectedMarketplaceOffer?: MarketplaceOffer;
	filterIdsPrefix: string;
}

export const Offer = ({ onChangeOffer, currentSelectedMarketplaceOffer, filterIdsPrefix }: OfferProps) => {
	const { formatMessage } = useIntl();
	const marketplaceOptions: MarketplaceOffer[] = ["SELL", "VALIDATE"];

	const onChangeMarketplaceOffer: ChangeEventHandler<HTMLSelectElement> = (event) => {
		if (marketplaceOptions.includes(event.target.value as MarketplaceOffer)) {
			onChangeOffer(event.target.value as MarketplaceOffer);
			return;
		}

		onChangeOffer();
	};

	return (
		<Filter
			id="offer"
			title={formatMessage(messages.offer)}
		>
			<Select
				name="marketplace-offer"
				id={`${filterIdsPrefix}-marketplace-offer`}
				sizing="lg"
				icon={Tag}
				onChange={onChangeMarketplaceOffer}
				shadow
				value={currentSelectedMarketplaceOffer || "ALL"}
			>
				<option
					key={"ALL"}
					value={"ALL"}
				>
					<FormattedMessage {...marketplaceOfferMessages["ALL"]} />
				</option>
				{marketplaceOptions.map((marketplaceOption) => (
					<option
						key={marketplaceOption}
						value={marketplaceOption}
					>
						<FormattedMessage {...marketplaceOfferMessages[marketplaceOption]} />
					</option>
				))}
			</Select>
		</Filter>
	);
};
