import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, InternalLink } from "@common";

import ChallengeTenderModel from "models/challenge-tenders/ChallengeTender";
import TalentModel from "models/talents/Talent";

import { TeamUserLoading } from "./TeamUser/TeamUserLoading";
import { TeamUser } from "./TeamUser/TeamUser";
import messages from "./messages";
import { HiredPeopleFromTalent } from "./HiredPeopleFromTalent";

interface TenderTeamProps {
	tender: ChallengeTenderModel;
	talents: TalentModel[];
	isTenderTalentsLoading: boolean;
}

export const TenderTeam = ({ tender, talents, isTenderTalentsLoading }: TenderTeamProps) => {
	const { formatMessage } = useIntl();
	const profileAdminModel = tender.admin;

	return (
		<section className="mt-16">
			<h5 className="text-lg">
				<FormattedMessage {...messages.knowTheTeam} />
			</h5>
			<div className="grid grid-cols-2 items-center gap-4 sm:grid-cols-3 lg:grid-cols-6">
				<TeamUser
					key={profileAdminModel.links.link}
					profileLink={profileAdminModel.links.link}
					profileImage={profileAdminModel.info.avatar}
					profileFullName={`${profileAdminModel.info.name} ${profileAdminModel.info.surname}`}
					rolName={formatMessage(messages.admin)}
				/>
				{isTenderTalentsLoading && [1, 2].map((_, index) => <TeamUserLoading key={index} />)}
				{talents.map((talent) => (
					<HiredPeopleFromTalent
						key={talent.id}
						talentId={Number(talent.id)}
						talentTitle={talent.title}
					/>
				))}
				<Button color="gray">
					<InternalLink href={`/tenders/${tender.id}/team`}>
						<FormattedMessage {...messages.seeMore} />
					</InternalLink>
				</Button>
			</div>
		</section>
	);
};
