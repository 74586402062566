import { defineMessages } from "react-intl";

export default defineMessages({
	title: {
		defaultMessage: "Workshops to master new skills and exercise your talents",
	},
	subTitle: {
		defaultMessage: "Choose the best workshops",
	},
	exploreAll: {
		defaultMessage: "Explore all workshops",
	},
});
