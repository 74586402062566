import { defineMessages } from "react-intl";

export default defineMessages({
	typeOfBusinessProfile: {
		defaultMessage: "Type of business profile",
	},
	grossSalary: {
		defaultMessage: "Salary per year (USD)",
	},
	equity: {
		defaultMessage: "Equity",
	},
	minimumExperience: {
		defaultMessage: "Minimum experience",
	},
	country: {
		defaultMessage: "Location",
	},
});
