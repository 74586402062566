import { defineMessages } from "react-intl";

export default defineMessages({
	title: {
		defaultMessage: "Learn from the best universities",
	},
	subTitle: {
		defaultMessage: "Plenty of universities work with us to give our users the best elearnings",
	},
	exploreAll: {
		defaultMessage: "Explore all universities",
	},
});
