import { defineMessages } from "react-intl";

import { Gender } from "../../onboarding-reducer";

export default defineMessages({
	welcomeUser: {
		defaultMessage: "Welcome {name} to Vinglet!",
	},
	tellUsAboutYourself: {
		defaultMessage: "Tell us about yourself",
	},
	gender: {
		defaultMessage: "Gender",
	},
	birthdate: {
		defaultMessage: "Birthdate",
	},
	country: {
		defaultMessage: "Country",
	},
	selectACountry: {
		defaultMessage: "Select a country",
	},
	profileURL: {
		defaultMessage: "Profile's URL",
	},
	selectAGender: {
		defaultMessage: "Select your gender or if you do not want to specify it set this field as 'Others'",
	},
});

export const genderMessages = defineMessages<Gender>({
	MALE: {
		defaultMessage: "Male",
	},
	FEMALE: {
		defaultMessage: "Female",
	},
	OTHERS: {
		defaultMessage: "Rather not say",
	},
});
