import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useChallengeQuery, useTenderMentorsQuery } from "@api";

import { MicroBusinessProfileTypes } from "constants/Profiles";
import ChallengeTenderModel from "models/challenge-tenders/ChallengeTender";
import BusinessProfileModel from "models/business-profiles/BusinessProfile";
import ChallengeModel from "models/challenges/Challenge";

import { TeamUser } from "../TeamUser/TeamUser";
import { TeamUserLoading } from "../TeamUser/TeamUserLoading";

import { TenderMentorsSelectorModal } from "./TenderMentorsSelectorModal";
import messages from "./messages";

interface TenderMentorsProps {
	tender: ChallengeTenderModel;
}

export const TenderMentors = ({ tender }: TenderMentorsProps) => {
	const { formatMessage } = useIntl();
	const { data: challenge } = useChallengeQuery(tender.challenge.slug, {
		enabled: false,
	});
	const { data: mentors, isLoading: isMentorsLoading } = useTenderMentorsQuery(tender.id);

	const mentorsModels =
		mentors?.map((mentor) => {
			return BusinessProfileModel.generateMiniFromAPI({
				...mentor.consultant_profile,
				type: MicroBusinessProfileTypes.ADVISER,
			});
		}) || [];

	const challengeModel = (challenge && ChallengeModel.generateFromAPI(challenge)) || ChallengeModel.generateEmpty();

	return (
		<section className="mb-16">
			<h2 className="flex mb-6 items-center justify-between text-2xl">
				<FormattedMessage {...messages.knowTheMentors} />
				{tender.isMine && (
					<TenderMentorsSelectorModal
						tenderId={tender.id}
						challengeSlug={tender.challenge.slug}
						challengeMentors={challengeModel.consultants}
						selectedConsultantsIds={mentorsModels.map((mentor) => mentor.info.id)}
					/>
				)}
			</h2>
			<p className="text-lg mb-12">
				<FormattedMessage {...messages.knowTheMentorsDescription} />
			</p>
			<div className="grid grid-cols-1 items-center gap-4 lg:grid-cols-3">
				{isMentorsLoading
					? [1, 2, 3, 4].map((_, index) => <TeamUserLoading key={index} />)
					: mentorsModels.map((mentor) => (
							<TeamUser
								key={mentor.info.url}
								profileLink={mentor.info.url}
								profileImage={mentor.info.avatar.src}
								profileFullName={mentor.info.fullName}
								profileEmblem={mentor.info.emblem}
								rolName={formatMessage(messages.mentor)}
							/>
					  ))}
			</div>
		</section>
	);
};
