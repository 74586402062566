import { ElearningType } from "constants/Elearning";
import { Translation } from "i18n/config";
import { NewElearningTranslation } from "./typing";

export const NewElearningTexts: Translation<NewElearningTranslation> = {
	es: {
		oneDay: "Un solo día",
		calendar: "Calendario",
		tryAgainMessage: "Ha ocurrido un error, intentelo de nuevo mas tarde.",
		titleCreate: (type: ElearningType) => {
			let text = type as string;

			switch (type) {
				case ElearningType.Course:
					text = "Curso";
					break;
				case ElearningType.Degree:
					text = "Grado";
					break;
				case ElearningType.DoctorDegree:
					text = "Doctorado";
					break;
				case ElearningType.Master:
					text = "Master";
					break;
				case ElearningType.PostgraduateDegree:
					text = "Posgrado";
					break;
				case ElearningType.Webinar:
					text = "Webinar";
					break;
				case ElearningType.Workshop:
					text = "Taller";
					break;
			}

			return `Crear ${text}`;
		},
		titleUpdate: (title: string) => `Actualizar "${title}"`,
		title: "Titulo",
		slug: "Link con el cual quieres acceder a este Elearning dentro de Vinglet",
		email: "¿Qué correo electrónico le gustaría usar para obtener información actualizada sobre este elearning?",
		url: "Ingrese el link del E-learning, URL Universidad, Drive, Google Docs o Dropbox",
		start: "Comienzo",
		from: "Desde",
		end: "Fin",
		to: "Hasta",
		backgroundImage: "Imagen de Fondo",
		changeBackgroundImage: "Cambiar Imagen de Fondo",
		description: "Descripción",
		schools: "Facultad universitaria",
		markets: "Sectores",
		language: "Idioma",
		careerModality: "Modalidad",
		withCertification: "Con certificacion?",
		checkAllFieldsMessage: "Verifique que todos los campos esten completados.",
		errorMessage: "Ha ocurrido un error al procesar tu petición, intente de nuevo mas tarde.",
		addImageDescription:
			"Agrega una image que represente claramente tu propuesta. Esta imagen aparecera en la pagina principal de tu propuesta como tambien en los sitios restantes dentro de Vinglet donde se visualice tu propuesta.",
		maxImagesDescription:
			"Este apartado es para que puedas mostrar 5 imágenes ilustrativas o fotografías que representen tu propuesta para resolver el reto, una historia, un elemento de contenido, categoría o módulo.",
		maxImagesSubDescription:
			"Trata de evitar imágenes con banners, insignias o textos. Así como de dimensiones pequeñas que se vuelven ilegibles ya que perjudican claramente tus posibilidades de realizar un mejor reto",
	},
	en: {
		oneDay: "One day",
		calendar: "Schedule",
		tryAgainMessage: "There was an error, try again later",
		titleCreate: (type: ElearningType) => {
			let text = type as string;

			switch (type) {
				case ElearningType.Course:
					text = "Course";
					break;
				case ElearningType.Degree:
					text = "Degree";
					break;
				case ElearningType.DoctorDegree:
					text = "Doctor's Degree";
					break;
				case ElearningType.Master:
					text = "Master";
					break;
				case ElearningType.PostgraduateDegree:
					text = "Postgraduate's Degree";
					break;
				case ElearningType.Webinar:
					text = "Webinar";
					break;
				case ElearningType.Workshop:
					text = "Workshop";
					break;
			}

			return `Create ${text}`;
		},
		titleUpdate: (title: string) => `Update "${title}"`,
		title: "Title",
		slug: "Vinglet's Link for this Elearning",
		url: "Enter the E-learning link, University's URL, Drive, Google Docs or Dropbox",
		email: "What email would you like to using to get updated info about this elearning?",
		start: "Start",
		from: "From",
		end: "End",
		to: "To",
		backgroundImage: "Background Image",
		changeBackgroundImage: "Change Background Image",
		description: "Description",
		schools: "Schools",
		markets: "Sectors",
		language: "Language",
		careerModality: "Career Modality",
		withCertification: "With Certification",
		checkAllFieldsMessage: "Check that all fields are completed",
		errorMessage: "We have an error while we were processing your request, try again later",
		addImageDescription:
			"Add an image that clearly represents your proposal. This image will appear on the main page of your proposal as well as in the remaining sites within Vinglet where your proposal is displayed. ",
		maxImagesDescription:
			"This section is so that you can show 5 illustrative images or photographs that represent your proposal to solve the challenge, a story, a content element, category or module. ",
		maxImagesSubDescription:
			"Try to avoid images with banners, badges or texts. As well as small dimensions that become illegible since they clearly harm your chances of performing a better challenge ",
	},
	ca: {
		oneDay: "Un solo día",
		calendar: "Calendario",
		tryAgainMessage: "Hi ha hagut un error, intenteu de nou més tard.",
		titleCreate: (type: ElearningType) => {
			let text = type as string;

			switch (type) {
				case ElearningType.Course:
					text = "Curs";
					break;
				case ElearningType.Degree:
					text = "Grau";
					break;
				case ElearningType.DoctorDegree:
					text = "Doctorat";
					break;
				case ElearningType.Master:
					text = "Màster";
					break;
				case ElearningType.PostgraduateDegree:
					text = "Postgrau";
					break;
				case ElearningType.Webinar:
					text = "Webinar";
					break;
				case ElearningType.Workshop:
					text = "Taller";
					break;
			}

			return `Crear ${text}`;
		},
		titleUpdate: (title: string) => `Actualitzar & quot; ${title} & quot;`,
		title: "Títol",
		slug: "Link con el cual quieres acceder a este Elearning dentro de Vinglet",
		email: "Quin correu electrònic voldríeu utilitzar per obtenir informació actualitzada sobre aquest tema?",
		url: "Introduïu el link d'e-learning, URL universitat, Drive, Google Docs o Dropbox",
		start: "Començament",
		from: "Des de",
		end: "Fi",
		to: "Fins",
		backgroundImage: "Imatge de Fons",
		changeBackgroundImage: "Canviar Imatge de fons",
		description: "Descripció",
		schools: "Facultats",
		markets: "Sectors",
		language: "Idioma",
		careerModality: "Modalitat de la cursa",
		withCertification: "Amb certificació?",
		checkAllFieldsMessage: "Verifiqueu que tots els camps estiguin completats.",
		errorMessage: "Hi ha hagut un error en processar la teva petició, proveu de nou més tard.",
		addImageDescription:
			"Afegeix una image que representi clarament la teva proposta. Aquesta imatge apareixerà a la pàgina principal de la teva proposta com també en els llocs restants dins Vinglet on es visualitzi la teva proposta.",
		maxImagesDescription:
			"Aquest apartat és perquè puguis mostra 5 imatges il·lustratives o fotografies que representin la teva proposta per resoldre el repte, una història, un element de contingut, categoria o mòdul.",
		maxImagesSubDescription:
			"Aquest apartat és perquè puguis mostra 5 imatges il·lustratives o fotografies que representin la teva proposta per resoldre el repte, una història, un element de contingut, categoria o mòdul.",
	},
	pt: {
		oneDay: "Um único dia",
		calendar: "Calendário",
		tryAgainMessage: "Ocorreu um erro, tente novamente mais tarde Ocorreu um erro, tente novamente mais tarde.",
		titleCreate: (type: ElearningType) => {
			let text = type as string;

			switch (type) {
				case ElearningType.Course:
					text = "Curso";
					break;
				case ElearningType.Degree:
					text = "Grado";
					break;
				case ElearningType.DoctorDegree:
					text = "Doutorado";
					break;
				case ElearningType.Master:
					text = "Mestre";
					break;
				case ElearningType.PostgraduateDegree:
					text = "Pós-graduação";
					break;
				case ElearningType.Webinar:
					text = "Webinar";
					break;
				case ElearningType.Workshop:
					text = "Seminário";
					break;
			}

			return `Crear ${text}`;
		},
		titleUpdate: (title: string) => `Actualizar "${title}"`,
		title: "Título",
		slug: "Link com o qual você deseja acessar este Elearning no Vinglet",
		email: "Qual e-mail você gostaria de usar para obter informações atualizadas sobre este Elearning?",
		url: "Digite o link E-learning, URL da universidade, Drive, Google Docs ou Dropbox",
		start: "Começar",
		from: "A partir de",
		end: "Fim",
		to: "Até",
		backgroundImage: "Imagem de fundo",
		changeBackgroundImage: "Alterar imagem de fundo",
		description: "Descrição",
		schools: "Faculdade universitaria",
		markets: "Setores",
		language: "Idioma",
		careerModality: "Modalidade",
		withCertification: "Com certificação?",
		checkAllFieldsMessage: "Verifique se todos os campos estão preenchidos.",
		errorMessage: "Ocorreu um erro ao processar sua solicitação, tente novamente mais tarde.",
		addImageDescription:
			"Adicione uma imagem que represente claramente sua proposta. Esta imagem aparecerá na página principal de sua proposta, bem como nos demais sites do Vinglet onde sua proposta é exibida.",
		maxImagesDescription:
			"Esta seção é para você mostrar 5 imagens ou fotografias ilustrativas que representam sua proposta para resolver o desafio, uma história, um elemento de conteúdo, categoria ou módulo.",
		maxImagesSubDescription:
			"Tente evitar imagens com banners, crachás ou texto. Assim como pequenas dimensões que se tornam ilegíveis, pois prejudicam claramente suas chances de realizar um desafio melhor",
	},
};
