import { useEffect, useReducer } from "react";
import { useOnboardingValidator } from "./useOnboardingValidator";
import { useOnboardingSlugChecker } from "./useOnboardingSlugChecker";

export enum OnboardingInfoActionType {
	CHANGE_FULL_NAME = "CHANGE_FULL_NAME",
	CHANGE_EMBLEM = "CHANGE_EMBLEM",
	CHANGE_URL = "CHANGE_URL",
	CHANGE_COUNTRY = "CHANGE_COUNTRY",
	CHANGE_SLUG = "CHANGE_SLUG",
	CHANGE_EMAIL = "CHANGE_EMAIL",
	CHANGE_AVATAR = "CHANGE_AVATAR",
	CHANGE_BACKGROUND_IMAGE = "CHANGE_BACKGROUND_IMAGE",
	ACCEPT_TERMS_AND_CONDITIONS = "ACCEPT_TERMS_AND_CONDITIONS",
	REJECT_TERMS_AND_CONDITIONS = "REJECT_TERMS_AND_CONDITIONS",
	ACCEPT_AGREEMENT = "ACCEPT_AGREEMENT",
	REJECT_AGREEMENT = "REJECT_AGREEMENT",
	FINISH_INFO_FORM = "FINISH_INFO_FORM",
	CLEAN_ERRORS = "CLEAN_ERRORS",
	ERROR_FULL_NAME = "ERROR_FULL_NAME",
	ERROR_URL = "ERROR_URL",
	ERROR_EMAIL = "ERROR_EMAIL",
	ERROR_SLUG = "ERROR_SLUG",
}

export interface OnboardingInfoReducer {
	fullName: string;
	emblem: string;
	url: string;
	country: string;
	slug: {
		value: string;
		alreadyExist: boolean;
		isLoading: boolean;
	};
	email: string;
	avatar: {
		src: string;
		alt: string;
	};
	backgroundImage: {
		src: string;
		alt: string;
	};
	termsAndCondition: boolean;
	agreementAccepted: boolean;
	hasInfoFormReady: boolean;
	errors: Record<string, string | undefined>;
}

const initialValues: OnboardingInfoReducer = {
	fullName: "",
	emblem: "",
	url: "",
	country: "",
	slug: {
		value: "",
		alreadyExist: false,
		isLoading: false,
	},
	email: "",
	avatar: {
		src: "",
		alt: "",
	},
	backgroundImage: {
		src: "",
		alt: "",
	},
	termsAndCondition: false,
	agreementAccepted: false,
	hasInfoFormReady: false,
	errors: {},
};

const onboardingInfoReducer = (
	state: OnboardingInfoReducer,
	action: { type: OnboardingInfoActionType; payload?: any },
): OnboardingInfoReducer => {
	const { type, payload } = action;

	switch (type) {
		case OnboardingInfoActionType.CHANGE_FULL_NAME:
			return { ...state, fullName: payload as string };
		case OnboardingInfoActionType.CHANGE_EMBLEM:
			return { ...state, emblem: payload as string };
		case OnboardingInfoActionType.CHANGE_URL:
			return { ...state, url: payload as string };
		case OnboardingInfoActionType.CHANGE_COUNTRY:
			return { ...state, country: payload as string };
		case OnboardingInfoActionType.CHANGE_SLUG:
			return { ...state, slug: { ...state.slug, ...payload } };
		case OnboardingInfoActionType.CHANGE_EMAIL:
			return { ...state, email: payload as string };
		case OnboardingInfoActionType.CHANGE_AVATAR:
			return { ...state, avatar: { ...payload } };
		case OnboardingInfoActionType.CHANGE_BACKGROUND_IMAGE:
			return { ...state, backgroundImage: { ...payload } };
		case OnboardingInfoActionType.ACCEPT_TERMS_AND_CONDITIONS:
			return { ...state, termsAndCondition: true };
		case OnboardingInfoActionType.REJECT_TERMS_AND_CONDITIONS:
			return { ...state, termsAndCondition: false };
		case OnboardingInfoActionType.ACCEPT_AGREEMENT:
			return { ...state, agreementAccepted: true };
		case OnboardingInfoActionType.REJECT_AGREEMENT:
			return { ...state, agreementAccepted: false };
		case OnboardingInfoActionType.FINISH_INFO_FORM:
			return { ...state, hasInfoFormReady: payload };
		case OnboardingInfoActionType.CLEAN_ERRORS:
			return { ...state, errors: { ...state.errors, [payload]: undefined } };
		case OnboardingInfoActionType.ERROR_FULL_NAME:
			return { ...state, errors: { ...state.errors, fullName: payload } };
		case OnboardingInfoActionType.ERROR_URL:
			return { ...state, errors: { ...state.errors, url: payload } };
		case OnboardingInfoActionType.ERROR_EMAIL:
			return { ...state, errors: { ...state.errors, email: payload } };
		case OnboardingInfoActionType.ERROR_SLUG:
			return { ...state, errors: { ...state.errors, slug: payload } };
		default:
			return state;
	}
};

export const useOnboardingInfoReducer = () => {
	const { validateFullName, validateEmail, validateURL } = useOnboardingValidator();
	const { slugAlreadyExist, checkSlug, error, isLoading } = useOnboardingSlugChecker();
	const [state, dispatch] = useReducer(onboardingInfoReducer, initialValues);

	useEffect(() => {
		dispatch({
			type: OnboardingInfoActionType.CLEAN_ERRORS,
			payload: "slug",
		});
		dispatch({
			type: OnboardingInfoActionType.CHANGE_SLUG,
			payload: {
				alreadyExist: slugAlreadyExist,
				isLoading: isLoading,
			},
		});

		if (error) {
			dispatch({
				type: OnboardingInfoActionType.ERROR_SLUG,
				payload: error,
			});
		}
	}, [error, isLoading, slugAlreadyExist]);

	const changeFullName = (value: string) => {
		dispatch({
			type: OnboardingInfoActionType.CLEAN_ERRORS,
			payload: "fullName",
		});
		dispatch({
			type: OnboardingInfoActionType.CHANGE_FULL_NAME,
			payload: value,
		});

		if (validateFullName(value)) {
			dispatch({
				type: OnboardingInfoActionType.ERROR_FULL_NAME,
				payload: validateFullName(value),
			});
		}
	};
	const changeEmblem = (value: string) => dispatch({ type: OnboardingInfoActionType.CHANGE_EMBLEM, payload: value });
	const changeUrl = (value: string) => {
		dispatch({
			type: OnboardingInfoActionType.CLEAN_ERRORS,
			payload: "url",
		});
		dispatch({ type: OnboardingInfoActionType.CHANGE_URL, payload: value });

		if (validateURL(value)) {
			dispatch({
				type: OnboardingInfoActionType.ERROR_URL,
				payload: validateURL(value),
			});
		}
	};
	const changeCountry = (value: string) => dispatch({ type: OnboardingInfoActionType.CHANGE_COUNTRY, payload: value });
	const changeSlug = (value: string) => {
		dispatch({
			type: OnboardingInfoActionType.CHANGE_SLUG,
			payload: {
				value,
				alreadyExist: false,
				isLoading: false,
			},
		});

		checkSlug(value);
	};
	const changeEmail = (value: string) => {
		dispatch({
			type: OnboardingInfoActionType.CLEAN_ERRORS,
			payload: "email",
		});
		dispatch({ type: OnboardingInfoActionType.CHANGE_EMAIL, payload: value });

		if (validateEmail(value)) {
			dispatch({
				type: OnboardingInfoActionType.ERROR_EMAIL,
				payload: validateEmail(value),
			});
		}
	};

	const changeAvatar = (value: { src: string; alt: string }) =>
		dispatch({ type: OnboardingInfoActionType.CHANGE_AVATAR, payload: value });
	const changeBackgroundImage = (value: { src: string; alt: string }) =>
		dispatch({
			type: OnboardingInfoActionType.CHANGE_BACKGROUND_IMAGE,
			payload: value,
		});

	const acceptTermsAndConditions = () => dispatch({ type: OnboardingInfoActionType.ACCEPT_TERMS_AND_CONDITIONS });
	const rejectTermsAndConditions = () => dispatch({ type: OnboardingInfoActionType.REJECT_TERMS_AND_CONDITIONS });

	const acceptAgreement = () => dispatch({ type: OnboardingInfoActionType.ACCEPT_AGREEMENT });
	const rejectAgreement = () => dispatch({ type: OnboardingInfoActionType.REJECT_AGREEMENT });

	const isInfoFormFinished = (value: boolean) =>
		dispatch({
			type: OnboardingInfoActionType.FINISH_INFO_FORM,
			payload: value,
		});

	const isThereAnyError = (): boolean => {
		const errors = Object.values(state.errors).filter((error) => error != undefined);

		return Boolean(errors.length);
	};

	return {
		infoState: state,
		isThereAnyError,
		changeFullName,
		changeEmblem,
		changeUrl,
		changeCountry,
		changeSlug,
		changeEmail,
		changeAvatar,
		changeBackgroundImage,
		acceptTermsAndConditions,
		rejectTermsAndConditions,
		acceptAgreement,
		rejectAgreement,
		isInfoFormFinished,
	};
};
