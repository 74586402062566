import { ChangeEventHandler } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { BusinessProfileTypes, InvestorTypes } from "@api";
import { Cube, Filter, Select } from "@common";

import messages from "../messages";

import { investorTypesMessages } from "./messages";

interface TypeProps {
	onChangeType: (investorType?: InvestorTypes) => void;
	currentSelectedInvestorType?: InvestorTypes;
	filterIdsPrefix: string;
}

export const InvestorType = ({ onChangeType, currentSelectedInvestorType, filterIdsPrefix }: TypeProps) => {
	const { formatMessage } = useIntl();
	const investorOptions: InvestorTypes[] = [
		BusinessProfileTypes.BUSINESS_ANGEL,
		BusinessProfileTypes.INCUBATOR,
		BusinessProfileTypes.ACCELERATOR,
	];

	const onChangeInvestorType: ChangeEventHandler<HTMLSelectElement> = (event) => {
		if (investorOptions.includes(event.target.value as InvestorTypes)) {
			onChangeType(event.target.value as InvestorTypes);
			return;
		}

		onChangeType();
	};

	return (
		<Filter
			id="type"
			title={formatMessage(messages.type)}
		>
			<Select
				name="investor-type"
				id={`${filterIdsPrefix}-investor-type`}
				sizing="lg"
				icon={Cube}
				onChange={onChangeInvestorType}
				shadow
				value={currentSelectedInvestorType || "ALL"}
			>
				<option
					key={"ALL"}
					value={"ALL"}
				>
					<FormattedMessage {...investorTypesMessages["ALL"]} />
				</option>
				{investorOptions.map((investorOption) => (
					<option
						key={investorOption}
						value={investorOption}
					>
						<FormattedMessage {...investorTypesMessages[investorOption]} />
					</option>
				))}
			</Select>
		</Filter>
	);
};
