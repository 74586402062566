import * as React from "react";
import classNames from "classnames";

import { Card } from "@common";

type ElearningsTopSchoolCardProps = {
	schoolName: string;
	schoolLink: string;
	schoolCounter: number;
	className: string;
};

export const ElearningsTopSchoolCard = ({
	schoolName,
	schoolLink,
	schoolCounter,
	className,
}: ElearningsTopSchoolCardProps) => (
	<li className={classNames(className)}>
		<Card href={schoolLink}>
			<div className="flex h-20 w-full flex-col items-center justify-around">
				<h5 className="text-md mb-0 font-semibold tracking-tight text-gray-900 dark:text-white">{schoolName}</h5>
				<span className="text-xs text-neutral-600">{schoolCounter} elearnings</span>
			</div>
		</Card>
	</li>
);
