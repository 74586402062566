import { AxiosError } from "axios";
import React, { ChangeEvent, ChangeEventHandler, FormEvent, FormEventHandler, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Image from "next/image";
import Link from "next/link";

import { Button, InputField } from "@common";
import { useLogUserInMutation } from "@api";
import { useAnalytics, useLocales, useNotifications } from "@libs";
import { isValidEmail } from "@utils";

import messages from "./messages";

type LoginFieldState = {
	error: boolean;
	value: string;
};

const Login = () => {
	const { currentShortLocale } = useLocales();
	const { event } = useAnalytics();
	const { formatMessage } = useIntl();
	const { addErrorNotification } = useNotifications();
	const { mutate, isLoading } = useLogUserInMutation({
		onSuccess: async (_, variables) => {
			await event("LOG_IN_FINISH", variables);
			location.reload();
		},
		onError: async (error: AxiosError, variables) => {
			await event("LOG_IN_FINISH_WITH_ERROR", {
				...variables,
				error: error.response?.status,
			});

			if (error.response?.status === 401) {
				addErrorNotification(formatMessage(messages.incorrectCredentials));
				return;
			}

			addErrorNotification(formatMessage(messages.somethingWentWrong));
		},
	});
	const [email, setEmail] = useState<LoginFieldState>({
		error: false,
		value: "",
	});
	const [password, setPassword] = useState<LoginFieldState>({
		error: false,
		value: "",
	});

	const login: FormEventHandler<HTMLFormElement> = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (!isValidEmail(email.value)) {
			addErrorNotification(formatMessage(messages.emailBadFormatted));

			return;
		}

		mutate({
			email: email.value,
			password: password.value,
		});
	};

	const handleOnChangeEmail: ChangeEventHandler<HTMLInputElement> = (event: ChangeEvent<HTMLInputElement>) => {
		setEmail((prevState) => {
			return {
				...prevState,
				value: event.target.value,
			};
		});
	};

	const handleOnChangePassword: ChangeEventHandler<HTMLInputElement> = (event: ChangeEvent<HTMLInputElement>) => {
		setPassword((prevState) => {
			return {
				...prevState,
				value: event.target.value,
			};
		});
	};

	return (
		<section
			className="bg-gray-50 dark:bg-gray-900"
			data-testid="LOGIN_FORM"
		>
			<Image
				src="/color-smoke-login.jpeg"
				alt="Login Background"
				className="opacity-40"
				layout="fill"
			/>
			<div className="relative z-20 mx-auto flex min-h-screen flex-col items-center justify-start  px-6 py-20">
				<div className="w-full rounded-lg bg-white shadow-2xl dark:border dark:border-gray-700 dark:bg-gray-800 sm:max-w-lg md:mt-0 xl:p-0">
					<div className="relative mt-6 hidden h-28 w-full items-center text-2xl font-semibold text-gray-900 dark:text-white md:flex">
						<Image
							className="object-contain object-center"
							src="/logo-complete.png"
							layout="fill"
							alt="Vinglet Logo"
						/>
					</div>
					<div className="space-y-4 p-6 sm:p-8 md:space-y-6">
						<div className="space-y-0">
							<h1 className="mb-2 text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl">
								<FormattedMessage {...messages.signInToYourAccount} />
							</h1>
							<p className="text-gray-500">
								<FormattedMessage {...messages.undertakeRiskFree} />
							</p>
						</div>
						<form
							className="space-y-4 md:space-y-6"
							onSubmit={login}
						>
							<div>
								<InputField
									type="email"
									name="email"
									id="email"
									placeholder="name@company.com"
									required
									labelValue={formatMessage(messages.yourEmail)}
									value={email.value}
									color={email.error ? "failure" : "gray"}
									autoComplete="email"
									onChange={handleOnChangeEmail}
								/>
							</div>
							<div>
								<InputField
									type="password"
									name="password"
									id="password"
									min={8}
									max={20}
									placeholder="••••••••"
									required
									labelValue={formatMessage(messages.password)}
									value={password.value}
									color={password.error ? "failure" : "gray"}
									autoComplete={"current-password"}
									onChange={handleOnChangePassword}
								/>
							</div>
							<div className="flex justify-center">
								<Link
									href="/forgot-password"
									passHref
								>
									<a className="text-primary-600 dark:text-primary-500 text-sm font-medium hover:underline">
										<FormattedMessage {...messages.forgotPassword} />
									</a>
								</Link>
							</div>
							<Button
								aria-label={formatMessage(messages.signIn)}
								size="lg"
								type="submit"
								color="purple"
								fluid
								loading={isLoading}
							>
								<FormattedMessage {...messages.signIn} />
							</Button>
							<p className="text-sm font-light text-gray-500 dark:text-gray-400">
								<FormattedMessage
									{...messages.doNotHaveAnAccountYet}
									values={{
										signUp: (
											<Link
												href="/signup"
												locale={currentShortLocale}
												passHref
											>
												<a className="text-primary-600 dark:text-primary-500 font-medium hover:underline">
													<FormattedMessage {...messages.signUp} />
												</a>
											</Link>
										),
									}}
								/>
							</p>
						</form>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Login;
