import * as React from "react";
import { FormattedMessage } from "react-intl";

import { AcademicCap, Check, ArrowRight, University } from "@common";

import messages from "./messages";

export const ElearningsHowItWorks = () => (
	<div className="mx-auto px-4 py-16 sm:max-w-xl md:max-w-full md:px-24 lg:max-w-screen-xl lg:px-8 lg:py-20">
		<div className="mb-10 max-w-xl sm:text-center md:mx-auto md:mb-16 lg:max-w-2xl">
			<h2 className="mb-6 max-w-lg font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
				<FormattedMessage {...messages.howItWorks} />
			</h2>
			<p className="text-base text-gray-700 md:text-lg">
				<FormattedMessage {...messages.subTitle} />
			</p>
		</div>
		<div className="row-gap-0 grid gap-8 lg:grid-cols-3">
			<div className="relative text-center">
				<div className="mx-auto mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-indigo-50 sm:h-20 sm:w-20">
					<AcademicCap className="h-12 w-12 text-indigo-800 opacity-40" />
				</div>
				<h6 className="mx-auto mt-10 block w-full max-w-sm text-lg">
					<FormattedMessage {...messages.findTheCourse} />
				</h6>
				<div className="top-0 right-0 flex h-24 items-center justify-center lg:absolute lg:-mr-8">
					<ArrowRight className="w-8 text-gray-300" />
				</div>
			</div>
			<div className="relative text-center">
				<div className="mx-auto mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-indigo-50 sm:h-20 sm:w-20">
					<University className="h-12 w-12 text-indigo-800 opacity-40" />
				</div>
				<h6 className="mx-auto mt-10 block w-full max-w-sm text-lg">
					<FormattedMessage {...messages.goToTheElearningsWebsite} />
				</h6>
				<div className="top-0 right-0 flex h-24 items-center justify-center lg:absolute lg:-mr-8">
					<ArrowRight className="w-8 text-gray-300" />
				</div>
			</div>
			<div className="relative text-center">
				<div className="mx-auto mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-green-50 sm:h-20 sm:w-20">
					<Check className="h-12 w-12 text-green-400" />
				</div>
				<h6 className="mx-auto mt-10 block w-full max-w-sm text-lg">
					<FormattedMessage {...messages.startLearning} />
				</h6>
			</div>
		</div>
	</div>
);
