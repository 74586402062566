import { useIntl } from "react-intl";

import { isValidEmail, isValidUrl, URLUtils } from "@utils";

import messages from "./messages";

export const useOnboardingValidator = () => {
	const { formatMessage } = useIntl();

	const validateFullName = (fullNameToValidate: string): string => {
		if (fullNameToValidate.length === 0) {
			return formatMessage(messages.thisFieldIsRequired);
		}

		return "";
	};

	const validateURL = (urlToValidate: string): string => {
		const urlWithoutHTTPHeader = URLUtils.removeHttpsProtocol(urlToValidate);

		if (urlWithoutHTTPHeader.length > 0 && !isValidUrl(urlToValidate)) {
			return formatMessage(messages.theUrlIsNotValid);
		}

		return "";
	};

	const validateEmail = (emailToValidate: string): string => {
		if (emailToValidate.length === 0) {
			return formatMessage(messages.thisFieldIsRequired);
		}

		if (!isValidEmail(emailToValidate)) {
			return formatMessage(messages.checkThatTheField);
		}

		return "";
	};

	return {
		validateFullName,
		validateURL,
		validateEmail,
	};
};
