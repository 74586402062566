import * as React from "react";

import { Card } from "@common";

export const ProfileInvolvedLoading = () => (
	<Card className="max-w-96 w-full">
		<div className="text-center">
			<div className="mx-auto -mt-10 mb-5 aspect-square h-32 w-32 rounded bg-gray-300 shadow-md dark:bg-gray-700" />
			<div className="mx-auto mb-2 h-4 w-32 bg-gray-300 dark:bg-gray-700" />
			<div className="mx-auto h-2 w-32 bg-gray-300 dark:bg-gray-700" />
		</div>
	</Card>
);
