import { ChangeEventHandler, useContext, useState } from "react";
import queryString from "query-string";
import { FormattedMessage } from "react-intl";

import { AcademicCap, Button, InternalLink, Select } from "@common";
import { CategoriesContext, getStringifiedList } from "@libs";

import { ElearningType } from "constants/Elearning";

import messages from "./messages";

export const ElearningsQuickSearchForm = () => {
	const { categories } = useContext(CategoriesContext);
	const attendanceCategories = categories.filter((category) => category.category == "ATTENDANCE");

	const [urlParams, setUrlParams] = useState<{
		elearningType: ElearningType;
		elearningModality: number | null;
	}>({
		elearningType: ElearningType.Course,
		elearningModality: attendanceCategories[0].id,
	});

	const onChangeElearningType: ChangeEventHandler<HTMLSelectElement> = ({ target }) =>
		setUrlParams({
			...urlParams,
			elearningType: target.value as ElearningType,
		});

	const onChangeElearningModality: ChangeEventHandler<HTMLSelectElement> = ({ target }) =>
		setUrlParams({
			...urlParams,
			elearningModality: Number(target.value),
		});

	const getElearningsExplorerLink = () => {
		let elearningsExplorerLink = "/elearnings/explorer";

		switch (urlParams.elearningType) {
			case ElearningType.Course:
			case ElearningType.Degree:
			case ElearningType.DoctorDegree:
			case ElearningType.Master:
			case ElearningType.PostgraduateDegree:
				elearningsExplorerLink += "/courses";
				break;
			case ElearningType.Workshop:
				elearningsExplorerLink += "/workshops";
				break;
			case ElearningType.Webinar:
				elearningsExplorerLink += "/webinars";
				break;
		}

		if (urlParams.elearningModality) {
			const elearningModalityQueryString = queryString.stringify(
				{
					modalitiesIds: getStringifiedList([urlParams.elearningModality]),
				},
				{
					skipEmptyString: true,
					skipNull: true,
				},
			);

			return `${elearningsExplorerLink}?${elearningModalityQueryString}`;
		} else {
			return elearningsExplorerLink;
		}
	};

	return (
		<div data-testid="ELEARNINGS_QUICK_SEARCH_FORM">
			<div className="relative flex w-full max-w-2xl items-center gap-2 rounded-xl bg-white p-3">
				<div className="w-60">
					<label
						htmlFor="elearningTypes"
						className="sr-only mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
					>
						<FormattedMessage {...messages.elearningTypes} />
					</label>
					<Select
						id="elearningTypes"
						name="elearningTypes"
						sizing="lg"
						icon={AcademicCap}
						onChange={onChangeElearningType}
						shadow
					>
						<option
							key={ElearningType.Course}
							value={ElearningType.Course}
						>
							<FormattedMessage {...messages.courses} />
						</option>
						<option
							key={ElearningType.Webinar}
							value={ElearningType.Webinar}
						>
							<FormattedMessage {...messages.webinars} />
						</option>
						<option
							key={ElearningType.Workshop}
							value={ElearningType.Workshop}
						>
							<FormattedMessage {...messages.workshops} />
						</option>
					</Select>
				</div>
				<div className="w-60">
					<label
						htmlFor="elearningModalities"
						className="sr-only mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
					>
						<FormattedMessage {...messages.elearningModalities} />
					</label>
					<Select
						name="elearningModalities"
						id="elearningModalities"
						sizing="lg"
						icon={AcademicCap}
						onChange={onChangeElearningModality}
						shadow
					>
						{attendanceCategories.map((attendanceCategory) => (
							<option
								key={String(attendanceCategory.id)}
								value={String(attendanceCategory.id)}
							>
								{attendanceCategory.text}
							</option>
						))}
					</Select>
				</div>
				<div className="flex-1">
					<InternalLink href={getElearningsExplorerLink()}>
						<Button
							size="lg"
							fluid
							color="dark"
						>
							<FormattedMessage {...messages.search} />
						</Button>
					</InternalLink>
				</div>
			</div>
		</div>
	);
};
