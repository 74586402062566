import * as React from "react";
import { Badge } from "flowbite-react";

import { Beaker, Card, Globe, InternalLink } from "@common";

import BusinessProfileModel from "models/business-profiles/BusinessProfile";

type StartupCardProps = BusinessProfileModel;

const MAX_CATEGORIES_PER_FEATURE = 5;

export const StartupCard = ({
	info: { description, fullName, avatar, url },
	features: { sectors, regions },
}: StartupCardProps) => {
	return (
		<Card
			className="h-full w-full max-w-none"
			imgClassName="w-52 h-52 aspect-square shadow-md mx-auto rounded overflow-hidden -mt-10 bg-white"
			imgAlt={fullName}
			imgSrc={avatar.src}
			imgHref={url}
		>
			<div className="flex h-full flex-col justify-between text-left">
				<InternalLink
					href={url}
					className="mb-3 block"
				>
					<h5 className="mt-0 text-lg font-semibold capitalize tracking-tight text-gray-900 line-clamp-2 hover:text-blue-600 dark:text-white">
						{fullName}
					</h5>
				</InternalLink>
				<p className="text-gray-600 line-clamp-3">{description}</p>
				<ul className="mb-6 flex w-full flex-1 flex-col gap-4 text-neutral-500">
					<li className="flex gap-2">
						<Globe className="h-6 w-6 shrink-0" />
						<div className="flex flex-wrap gap-2">
							{regions.slice(0, MAX_CATEGORIES_PER_FEATURE).map((region) => (
								<Badge
									key={region.key}
									color="warning"
								>
									{region.text}
								</Badge>
							))}
						</div>
					</li>

					<li className="flex gap-2">
						<Beaker className="h-6 w-6 shrink-0" />
						<div className="flex flex-wrap gap-2">
							{sectors.slice(0, MAX_CATEGORIES_PER_FEATURE).map((sector) => (
								<Badge key={sector.key}>{sector.text}</Badge>
							))}
							{sectors.length > MAX_CATEGORIES_PER_FEATURE && (
								<span className="text-sm font-semibold"> +{sectors.length - MAX_CATEGORIES_PER_FEATURE} </span>
							)}
						</div>
					</li>
				</ul>
			</div>
		</Card>
	);
};
