import { defineMessages } from "react-intl";

export default defineMessages({
	cancel: {
		defaultMessage: "Cancel",
	},
	continue: {
		defaultMessage: "Continue",
	},
	previous: {
		defaultMessage: "Previous",
	},
	finish: {
		defaultMessage: "Finish",
	},
	thisFieldIsRequired: {
		defaultMessage: "This field is required",
	},
	profileAlreadyTaken: {
		defaultMessage: "There is another profile already using this URL, try another one",
	},
	somethingWentWrong: {
		defaultMessage: "Something went wrong creating the profile, try again later please",
	},
	tabPersonalInfo: {
		defaultMessage: "Personal info",
	},
	tabAccountInfo: {
		defaultMessage: "Account info",
	},
	tabPersonalSkills: {
		defaultMessage: "Personal skills",
	},
	tabTechnicalSkills: {
		defaultMessage: "Technical skills",
	},
});
