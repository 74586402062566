import * as React from "react";
import Image from "next/image";

import { Card, InternalLink } from "@common";

import BusinessProfileModel from "models/business-profiles/BusinessProfile";

interface ProfileInvolvedProps {
	businessProfileModel: BusinessProfileModel;
}

export const ProfileInvolved = ({ businessProfileModel }: ProfileInvolvedProps) => (
	<Card className="max-w-96 w-full">
		<div className="text-center">
			<div className="relative mx-auto -mt-10 mb-5 aspect-square w-32 overflow-hidden rounded shadow-md">
				<Image
					layout="fill"
					alt={businessProfileModel.info.fullName}
					src={businessProfileModel.info.avatar.src}
				/>
			</div>
			<div>
				<InternalLink
					className="text-lg"
					href={businessProfileModel.info.url}
				>
					{businessProfileModel.info.fullName}
				</InternalLink>
			</div>
			<span className="text-md text-gray-500">{businessProfileModel.info.country.text}</span>
		</div>
	</Card>
);
