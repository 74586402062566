import * as React from "react";

export const TeamUserLoading = () => {
	return (
		<div className="p-4">
			<div className="flex flex-col items-center justify-center">
				<div className="flex-shrink-0">
					<div className="h-20 w-20 rounded-full bg-gray-200 dark:bg-gray-700" />
				</div>
				<div className="mt-2 flex flex-col items-center">
					<div className="mb-2 h-4 w-16 bg-gray-200 dark:bg-gray-700" />
					<div className="h-2 w-20 bg-gray-200 dark:bg-gray-700" />
				</div>
			</div>
		</div>
	);
};
