import React, { ChangeEventHandler, FocusEventHandler } from "react";
import { useIntl } from "react-intl";

import { Filter, InputField } from "@common";

import messages from "../messages";

interface CostPerHourProps {
	onChangeCostPerHour: (min?: number, max?: number) => void;
	currentSelectedTalentsCostPerHour: {
		min?: number;
		max?: number;
	};
	filterIdsPrefix: string;
}

export const CostPerHour = ({
	onChangeCostPerHour,
	currentSelectedTalentsCostPerHour,
	filterIdsPrefix,
}: CostPerHourProps) => {
	const { formatMessage } = useIntl();
	const { min, max } = currentSelectedTalentsCostPerHour;

	const onChangeMinCostPerHour: ChangeEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;

		onChangeCostPerHour(value ? Number(value) : undefined, max);
	};

	const onChangeMaxCostPerHour: ChangeEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;

		onChangeCostPerHour(min, value ? Number(value) : undefined);
	};

	const onBlurMaxCostPerHour: FocusEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;

		if (min && Number(value) < min) {
			onChangeCostPerHour(Number(value), max);
		}
	};

	const onBlurMinCostPerHour: FocusEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;

		if (max && Number(value) > max) {
			onChangeCostPerHour(min, Number(value));
		}
	};

	return (
		<Filter
			id="costPerHour"
			title={formatMessage(messages.costPerHour)}
		>
			<div className="flex items-center justify-center gap-4">
				<div className="w-full">
					<InputField
						type="number"
						name={`${filterIdsPrefix}-min-costPerHour`}
						id={`${filterIdsPrefix}-min-costPerHour`}
						placeholder="$0"
						required
						value={min}
						onChange={onChangeMinCostPerHour}
						onBlur={onBlurMinCostPerHour}
						labelValue={formatMessage(messages.min)}
					/>
				</div>
				<div className="w-full">
					<InputField
						type="number"
						name={`${filterIdsPrefix}-max-costPerHour`}
						id={`${filterIdsPrefix}-max-costPerHour`}
						placeholder="$5000000"
						required
						value={max}
						onChange={onChangeMaxCostPerHour}
						onBlur={onBlurMaxCostPerHour}
						labelValue={formatMessage(messages.max)}
					/>
				</div>
			</div>
		</Filter>
	);
};
