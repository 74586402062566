import { useIntl } from "react-intl";
import dayjs from "dayjs";
import classNames from "classnames";
import { Pagination } from "flowbite-react";
import { useRouter } from "next/router";
import queryString from "query-string";

import {getStringifiedList, useDebounce, useLocales} from "@libs";
import { useElearningsQuery } from "@api";

import ElearningModel from "models/elearnings/Elearning";

import { ElearningsCardLoading } from "../../ElearningsCard/ElearningsCardLoading";
import { ElearningsCard } from "../../ElearningsCard/ElearningsCard";
import { ElearningsFilters } from "../ElearningsExplorerFilters/ElearningsExplorerFilters";

import messages from "./messages";

export interface ElearningsExplorerListProps {
	filters: ElearningsFilters;
}

export const ElearningsExplorerList = ({
	filters: { modalitiesIds, schoolsIds, languagesIds, marketsIds, pageNumber, elearningTypeSearch },
}: ElearningsExplorerListProps) => {
	const router = useRouter();
	const { formatMessage } = useIntl();
	const debouncedQueryParams = useDebounce(
		{
			page: pageNumber ? pageNumber : 1,
			perPage: 12,
			"search[type]": elearningTypeSearch,
			"search[schools]": schoolsIds,
			"search[sectors]": marketsIds,
			"search[languages]": languagesIds,
			"search[careerModalities]": modalitiesIds,
		},
		1000,
	);
	const { data: elearnings, isLoading } = useElearningsQuery(debouncedQueryParams, {
		enabled: Boolean(debouncedQueryParams),
	});
	const {currentShortLocale} = useLocales();
	const elearningsModels = elearnings?.items.map((course) => ElearningModel.generateFromAPI(course));

	const handleOnPageChange = async (newPageNumber: number) => {
		await router.push(
			router.pathname +
				`?page=${newPageNumber}&${queryString.stringify(
					{
						modalitiesIds: getStringifiedList(modalitiesIds),
						schoolsIds: getStringifiedList(schoolsIds),
						marketsIds: getStringifiedList(marketsIds),
						languagesIds: getStringifiedList(languagesIds),
					},
					{
						skipEmptyString: true,
						skipNull: true,
					},
				)}`,
		);
	};

	const getTotalPages = () => (elearnings?.num_pages ? elearnings?.num_pages : 0);

	return (
		<>
			<ul
				className={classNames(
					"mt-6 w-full text-center",
					"grid items-center justify-between gap-4",
					"grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4",
				)}
			>
				{isLoading
					? [1, 2, 3, 4, 5, 6, 7, 8].map((index) => <ElearningsCardLoading key={index} />)
					: elearningsModels?.map((course) => (
							<li
								key={course.id}
								className="h-full w-full flex-1"
								data-testid={`ELEARNING_${course.type}_${course.id}`}
							>
								<ElearningsCard
									elearningId={Number(course.id)}
									title={course.title}
									elearningType={course.type}
									elearningModality={course.careerModality.text || formatMessage(messages.online)}
									href={course.getInternalURL(currentShortLocale)}
									imgSrc={course.getFormattedImage().src}
									startDate={dayjs(course.startDate)}
									endDate={dayjs(course.endDate)}
									businessProfile={{
										name: course.owner.info.fullName,
										imgSrc: course.owner.info.avatar.src,
										href: course.owner.info.url,
									}}
								/>
							</li>
					  ))}
			</ul>
			{getTotalPages() > 1 && (
				<div className="mt-24 w-full text-center">
					<Pagination
						currentPage={pageNumber}
						layout={"pagination"}
						totalPages={getTotalPages()}
						onPageChange={handleOnPageChange}
					/>
				</div>
			)}
		</>
	);
};
