import React, { ChangeEventHandler, FocusEventHandler } from "react";
import { useIntl } from "react-intl";

import { Filter, InputField } from "@common";

import messages from "../messages";

interface NumberOfStartupsToInvestProps {
	onChangeNumberOfStartupsToInvest: (min?: number, max?: number) => void;
	currentSelectedPostulateNumberOfStartupsToInvest: {
		min?: number;
		max?: number;
	};
	filterIdsPrefix: string;
}

export const NumberOfStartupsToInvest = ({
	onChangeNumberOfStartupsToInvest,
	currentSelectedPostulateNumberOfStartupsToInvest,
	filterIdsPrefix,
}: NumberOfStartupsToInvestProps) => {
	const { formatMessage } = useIntl();
	const { min, max } = currentSelectedPostulateNumberOfStartupsToInvest;

	const onChangeMinNumberOfStartupsToInvest: ChangeEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;

		onChangeNumberOfStartupsToInvest(value ? Number(value) : undefined, max);
	};

	const onChangeMaxNumberOfStartupsToInvest: ChangeEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;

		onChangeNumberOfStartupsToInvest(min, value ? Number(value) : undefined);
	};

	const onBlurMaxNumberOfStartupsToInvest: FocusEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;

		if (min && Number(value) < min) {
			onChangeNumberOfStartupsToInvest(Number(value), max);
		}
	};

	const onBlurMinNumberOfStartupsToInvest: FocusEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;

		if (max && Number(value) > max) {
			onChangeNumberOfStartupsToInvest(min, Number(value));
		}
	};

	return (
		<Filter
			id="numberOfStartupsToInvest"
			title={formatMessage(messages.numberOfStartupsToInvest)}
		>
			<div className="flex items-center justify-center gap-4">
				<div className="w-full">
					<InputField
						type="number"
						name={`${filterIdsPrefix}-min-numberOfStartupsToInvest`}
						id={`${filterIdsPrefix}-min-numberOfStartupsToInvest`}
						placeholder="0"
						value={min}
						onChange={onChangeMinNumberOfStartupsToInvest}
						onBlur={onBlurMinNumberOfStartupsToInvest}
						labelValue={formatMessage(messages.min)}
					/>
				</div>
				<div className="w-full">
					<InputField
						type="number"
						name={`${filterIdsPrefix}-max-numberOfStartupsToInvest`}
						id={`${filterIdsPrefix}-max-numberOfStartupsToInvest`}
						placeholder="40"
						value={max}
						onChange={onChangeMaxNumberOfStartupsToInvest}
						onBlur={onBlurMaxNumberOfStartupsToInvest}
						labelValue={formatMessage(messages.max)}
					/>
				</div>
			</div>
		</Filter>
	);
};
