import { defineMessages } from "react-intl";

export default defineMessages({
	type: {
		defaultMessage: "Type of talent offer",
	},
	sectors: {
		defaultMessage: "Sectors",
	},
	regions: {
		defaultMessage: "Regions",
	},
	countries: {
		defaultMessage: "Countries",
	},
	schools: {
		defaultMessage: "Schools",
	},
	languages: {
		defaultMessage: "Languages",
	},
	averageSalary: {
		defaultMessage: "Average salary",
	},
	averageEquity: {
		defaultMessage: "Average Equity",
	},
	costPerHour: {
		defaultMessage: "Cost per hour",
	},
	min: {
		defaultMessage: "Minimum",
	},
	max: {
		defaultMessage: "Maximum",
	},
});
