import * as React from "react";

import { Card } from "@common";

export const TeamUserLoading = () => (
	<Card className="relative mt-20">
		<div className="absolute -top-16 right-1/2 mb-4 translate-x-1/2 transform text-center">
			<div className="h-40 w-40 rounded-full bg-gray-200 dark:bg-gray-700" />
		</div>
		<div className="rounded-lg bg-white pt-24 dark:bg-gray-800">
			<div className="pb-4 text-center">
				<div className="mx-auto mb-2 h-6 w-20 bg-gray-200 dark:bg-gray-700" />
				<div className="mx-auto mb-4 h-4 w-24 bg-gray-200 dark:bg-gray-700" />
				<div className="mx-auto h-2 w-20 bg-gray-200 dark:bg-gray-700" />
			</div>
		</div>
	</Card>
);
