import { useState } from "react";
import { useRouter } from "next/router";

import { ElearningTypeSearch } from "@api";
import { getListFromStringifiedList } from "@libs";

import CategoryModel from "models/categories/Category";

import * as Filters from "./Filters";

type CategoriesCounter = number;

export type ElearningsFilters = {
	schoolsIds: number[];
	languagesIds: number[];
	marketsIds: number[];
	modalitiesIds: number[];
	pageNumber: number;
	elearningTypeSearch: ElearningTypeSearch;
};

export interface ElearningsExplorerFiltersProps {
	topModalities?: [CategoryModel, CategoriesCounter][];
	topSchools: [CategoryModel, CategoriesCounter][];
	topLanguages: [CategoryModel, CategoriesCounter][];
	topMarkets: [CategoryModel, CategoriesCounter][];
	onChangeFilters: (filters: Omit<ElearningsFilters, "pageNumber" | "elearningTypeSearch">) => void;
	filtersIdsPrefix: {
		modalities: string;
		schools: string;
		languages: string;
		markets: string;
	};
}

export const ElearningsExplorerFilters = ({
	topModalities,
	topSchools,
	topLanguages,
	topMarkets,
	onChangeFilters,
	filtersIdsPrefix,
}: ElearningsExplorerFiltersProps) => {
	const router = useRouter();
	const { modalitiesIds, schoolsIds, languagesIds, marketsIds } = router.query;
	const initCategoriesIdsClickedFromRouterQuery = (categoriesIds: string[]): Record<number, boolean> => {
		return categoriesIds.reduce((acc, categoryId) => {
			return {
				...acc,
				[Number(categoryId)]: true,
			};
		}, {});
	};

	const [modalitiesIdsClicked, setModalitiesIdsClicked] = useState<Record<number, boolean>>(
		initCategoriesIdsClickedFromRouterQuery(getListFromStringifiedList(modalitiesIds as string)),
	);
	const [schoolsIdsClicked, setSchoolsIdsClicked] = useState<Record<number, boolean>>(
		initCategoriesIdsClickedFromRouterQuery(getListFromStringifiedList(schoolsIds as string)),
	);
	const [languagesIdsClicked, setLanguagesIdsClicked] = useState<Record<number, boolean>>(
		initCategoriesIdsClickedFromRouterQuery(getListFromStringifiedList(languagesIds as string)),
	);
	const [marketsIdsClicked, setMarketsIdsClicked] = useState<Record<number, boolean>>(
		initCategoriesIdsClickedFromRouterQuery(getListFromStringifiedList(marketsIds as string)),
	);

	const handleOnChangeFilters = (
		schoolsIdsClicked: Record<number, boolean>,
		languagesIdsClicked: Record<number, boolean>,
		marketsIdsClicked: Record<number, boolean>,
		modalitiesIdsClicked: Record<number, boolean>,
	) => {
		const schoolsIds = Object.keys(schoolsIdsClicked)
			.filter((schoolId) => schoolsIdsClicked[Number(schoolId)])
			.map((schoolId) => Number(schoolId));
		const languagesIds = Object.keys(languagesIdsClicked)
			.filter((languageId) => languagesIdsClicked[Number(languageId)])
			.map((languageId) => Number(languageId));
		const marketsIds = Object.keys(marketsIdsClicked)
			.filter((marketId) => marketsIdsClicked[Number(marketId)])
			.map((marketId) => Number(marketId));
		const modalitiesIds = Object.keys(modalitiesIdsClicked)
			.filter((modalityId) => modalitiesIdsClicked[Number(modalityId)])
			.map((modalityId) => Number(modalityId));

		onChangeFilters({
			schoolsIds,
			languagesIds,
			marketsIds,
			modalitiesIds,
		});
	};

	const handleOnCheckedModality = (categoryId: number) => {
		const newModalitiesIdsClicked = {
			...modalitiesIdsClicked,
			[categoryId]: !modalitiesIdsClicked[categoryId],
		};

		setModalitiesIdsClicked(newModalitiesIdsClicked);
		handleOnChangeFilters(schoolsIdsClicked, languagesIdsClicked, marketsIdsClicked, newModalitiesIdsClicked);
	};
	const handleOnCheckedSchool = (categoryId: number) => {
		const newSchoolsIdsClicked = {
			...schoolsIdsClicked,
			[categoryId]: !schoolsIdsClicked[categoryId],
		};

		setSchoolsIdsClicked(newSchoolsIdsClicked);
		handleOnChangeFilters(newSchoolsIdsClicked, languagesIdsClicked, marketsIdsClicked, modalitiesIdsClicked);
	};
	const handleOnCheckedLanguage = (categoryId: number) => {
		const newLanguagesIdsClicked = {
			...languagesIdsClicked,
			[categoryId]: !languagesIdsClicked[categoryId],
		};

		setLanguagesIdsClicked(newLanguagesIdsClicked);
		handleOnChangeFilters(schoolsIdsClicked, newLanguagesIdsClicked, marketsIdsClicked, modalitiesIdsClicked);
	};
	const handleOnCheckedMarket = (categoryId: number) => {
		const newMarketsIdsClicked = {
			...marketsIdsClicked,
			[categoryId]: !marketsIdsClicked[categoryId],
		};

		setMarketsIdsClicked(newMarketsIdsClicked);
		handleOnChangeFilters(schoolsIdsClicked, languagesIdsClicked, newMarketsIdsClicked, modalitiesIdsClicked);
	};

	return (
		<>
			{topModalities && (
				<Filters.Modalities
					modalities={topModalities}
					modalitiesIdsClicked={modalitiesIdsClicked}
					onChangeOneModality={handleOnCheckedModality}
					filterIdsPrefix={filtersIdsPrefix.modalities}
				/>
			)}
			<Filters.Schools
				schools={topSchools}
				schoolsIdsClicked={schoolsIdsClicked}
				onChangeOneSchool={handleOnCheckedSchool}
				filterIdsPrefix={filtersIdsPrefix.schools}
			/>
			<Filters.Markets
				markets={topMarkets}
				marketsIdsClicked={marketsIdsClicked}
				onChangeOneMarket={handleOnCheckedMarket}
				filterIdsPrefix={filtersIdsPrefix.markets}
			/>
			<Filters.Languages
				languages={topLanguages}
				languagesIdsClicked={languagesIdsClicked}
				onChangeOneLanguage={handleOnCheckedLanguage}
				filterIdsPrefix={filtersIdsPrefix.languages}
			/>
		</>
	);
};
