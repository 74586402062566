import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useRouter } from "next/router";

import { CategoryType, useCreateAdviserMutation } from "@api";
import { LabelListProfile } from "@common";
import { useNotifications } from "@libs";

import { BusinessProfileTypes, MicroBusinessProfileTypes } from "constants/Profiles";
import CategoriesServiceSingleton from "services/categories/CategoriesService";
import CategoryModel from "models/categories/Category";
import CategorySelectableModel from "models/categories/CategorySelectable";

import { useOnboardingInfoReducer } from "../onboarding-reducer";
import { OnboardingModal } from "../OnboardingModal";
import { InitialProfileForm } from "../Forms/InitialProfileForm/InitialProfileForm";
import { AdviserStatistic, AdviserStatisticForm } from "../Forms/AdviserStatisticForm/AdviserStatisticForm";
import { commonOnboardingMessages } from "../messages";

import messages from "./messages";
import { AdviserSkills } from "./AdviserSkills/AdviserSkills";

interface OnboardingAdviserModalProps {
	hasReachedTheLimit: boolean;
}

export const OnboardingAdviserModal = ({ hasReachedTheLimit }: OnboardingAdviserModalProps) => {
	const { formatMessage } = useIntl();
	const { addErrorNotification } = useNotifications();
	const router = useRouter();
	const [onboardingState, setOnboardingState] = useState<{
		currentIndexActiveStep: number;
	}>({
		currentIndexActiveStep: 0,
	});
	const {
		infoState,
		isThereAnyError,
		changeFullName,
		changeUrl,
		changeCountry,
		changeSlug,
		changeEmail,
		acceptTermsAndConditions,
		rejectTermsAndConditions,
	} = useOnboardingInfoReducer();
	const [adviserStatistic, setAdviserStatistic] = useState<AdviserStatistic>({
		wagePerHour: 0,
		percentajePerStartup: 0,
	});

	const categoryList = CategoriesServiceSingleton.getList();

	const selectableLanguages = categoryList
		.filterByCategoryType(CategoryType.LANGUAGES, true)
		.map((category: CategoryModel) => CategorySelectableModel.createFromCommonCategory(category));
	const selectableRegions = categoryList
		.filterByCategoryType(CategoryType.REGION, true)
		.map((category: CategoryModel) => CategorySelectableModel.createFromCommonCategory(category));

	const [adviserSkills, setAdviserSkills] = useState<CategorySelectableModel[]>([]);
	const [languages, setLanguages] = useState<CategorySelectableModel[]>(selectableLanguages);
	const [regions, setRegions] = useState<CategorySelectableModel[]>(selectableRegions);

	const { mutateAsync, isLoading } = useCreateAdviserMutation();

	const handleOnboardingOnPressContinue = () =>
		setOnboardingState({
			...onboardingState,
			currentIndexActiveStep: onboardingState.currentIndexActiveStep + 1,
		});
	const handleOnboardingOnPressPrevious = () =>
		setOnboardingState({
			...onboardingState,
			currentIndexActiveStep: onboardingState.currentIndexActiveStep - 1,
		});

	const saveProfile = () => {
		const profile = {
			name: infoState.fullName,
			city: "",
			url: infoState.url,
			email: infoState.email,
			emblem: "",
			slug: infoState.slug.value,
			country: infoState.country ? Number(infoState.country) : undefined,
			description: "",
			currency: "USD",
			cost: adviserStatistic.wagePerHour,
			percentage: adviserStatistic.percentajePerStartup,
			region: regions.filter((region) => region.selected && region.id).map((region) => region.id) as number[],
			languages: languages
				.filter((language) => language.selected && language.id)
				.map((language) => language.id) as number[],
			areas: [],
			social_networks: [],
			skills: adviserSkills
				.filter((adviserSkill) => adviserSkill.selected && adviserSkill.id)
				.map((adviserSkill) => adviserSkill.id) as number[],
		};

		mutateAsync(profile)
			.then(() => {
				router.push(`/${MicroBusinessProfileTypes.ADVISER}/${infoState.slug.value}`);
			})
			.catch(() => addErrorNotification(formatMessage(commonOnboardingMessages.somethingWentWrong)));
	};

	const onChangeTermsAndConditions = (checked: boolean) =>
		checked ? acceptTermsAndConditions() : rejectTermsAndConditions();
	const isContinueButtonEnabled =
		!isThereAnyError() &&
		!infoState.slug.isLoading &&
		infoState.fullName.length > 0 &&
		infoState.email.length > 0 &&
		infoState.slug.value.length > 0 &&
		infoState.termsAndCondition;

	const stepsNames: string[] = [
		formatMessage(commonOnboardingMessages.tabInfo),
		formatMessage(commonOnboardingMessages.tabStatistics),
		formatMessage(commonOnboardingMessages.tabAdviserSkills),
		formatMessage(commonOnboardingMessages.tabLanguages),
		formatMessage(commonOnboardingMessages.tabRegion),
	];

	return (
		<section data-testid="ADVISER_ONBOARDING">
			<OnboardingModal
				modal={{ title: formatMessage(messages.createNewAdviser) }}
				hasReachedTheMaximumCreatedProfiles={hasReachedTheLimit}
				stepNames={stepsNames}
				businessProfileType={BusinessProfileTypes.ADVISER}
				currentIndexActiveStep={onboardingState.currentIndexActiveStep}
				continueButton={{
					onPress: handleOnboardingOnPressContinue,
					disabled: !isContinueButtonEnabled,
				}}
				previousButton={{
					onPress: handleOnboardingOnPressPrevious,
					disabled: isLoading,
				}}
				finishButton={{
					onPress: saveProfile,
					disabled: isLoading,
					loading: isLoading,
				}}
			>
				<InitialProfileForm
					info={infoState}
					errors={infoState.errors}
					onChangeFullName={changeFullName}
					onChangeCountry={changeCountry}
					onChangeUrl={changeUrl}
					onChangeEmail={changeEmail}
					onChangeSlug={changeSlug}
					onChangeTermsAndConditions={onChangeTermsAndConditions}
				/>
				<div>
					<p className="px-20">
						<FormattedMessage {...commonOnboardingMessages.adviserStatistic} />
					</p>
					<AdviserStatisticForm
						adviserStatistic={adviserStatistic}
						onUpdateAdviserStatistic={setAdviserStatistic}
					/>
				</div>
				<div className="mb-8 flex flex-col gap-4">
					<p>
						<FormattedMessage {...commonOnboardingMessages.adviserSkills} />
					</p>
					<AdviserSkills onUpdate={setAdviserSkills} />
				</div>
				<div className="mb-8 flex flex-col gap-4 px-20">
					<p>
						<FormattedMessage {...commonOnboardingMessages.languagesTexts} />
					</p>
					<LabelListProfile
						basic
						showAll
						editable
						color="blue"
						onUpdate={setLanguages}
						items={languages}
					/>
				</div>
				<div className="mb-8 flex flex-col gap-4 px-20">
					<p>
						<FormattedMessage {...commonOnboardingMessages.regionTexts} />
					</p>
					<LabelListProfile
						basic
						showAll
						editable
						color="orange"
						onUpdate={setRegions}
						items={regions}
					/>
				</div>
			</OnboardingModal>
		</section>
	);
};
