import { defineMessages } from "react-intl";

export default defineMessages({
	title: {
		defaultMessage: "Marketplace, from start-ups to the world",
	},
	subTitle: {
		defaultMessage: "Product offers and services from start-ups that you can validate, buy or subscribe",
	},
});
