import { defineMessages } from "react-intl";

export default defineMessages({
	cancel: {
		defaultMessage: "Cancel",
	},
	continue: {
		defaultMessage: "Continue",
	},
	previous: {
		defaultMessage: "Previous",
	},
	finish: {
		defaultMessage: "Finish",
	},
	thisFieldIsRequired: {
		defaultMessage: "This field is required",
	},
	theUrlIsNotValid: {
		defaultMessage: "This URL is not valid",
	},
	checkThatTheField: {
		defaultMessage: "We don't recognise this as a valid email",
	},
	profileAlreadyTaken: {
		defaultMessage: "There is another profile already using this URL, try another one",
	},
	maxCreatedProfiles: {
		defaultMessage: "You have reached the maximum adviser per user",
	},
});

export const commonOnboardingMessages = defineMessages({
	tabInfo: {
		defaultMessage: "Info",
	},
	tabImage: {
		defaultMessage: "Images",
	},
	tabMarkets: {
		defaultMessage: "Sectors",
	},
	tabSchools: {
		defaultMessage: "Schools",
	},
	tabLanguages: {
		defaultMessage: "Languages",
	},
	tabRegion: {
		defaultMessage: "Regions",
	},
	tabAdviserSkills: {
		defaultMessage: "Adviser skills",
	},
	tabStatistics: {
		defaultMessage: "Statistics",
	},
	tagProfile: {
		defaultMessage: "Profile",
	},
	adviserSkills: {
		defaultMessage: "Identify the Skills that fit your profile",
	},
	marketsTexts: {
		defaultMessage: "Identify the sectors that you work",
	},
	schoolsTexts: {
		defaultMessage: "Select the faculties of your University",
	},
	languagesTexts: {
		defaultMessage: "Select the languages that you can communicate",
	},
	regionTexts: {
		defaultMessage: "Identify the regions you work in person or remotely",
	},
	somethingWentWrong: {
		defaultMessage: "Something went wrong creating the profile, try again later please",
	},
	adviserStatistic: {
		defaultMessage:
			"Startups need containment of advisors. As an advisor you can charge with money or with equity. In case you want to charge with equity, clarify the average percentage that you would be willing to request for being a Startup member and contain the entrepreneur's proposal.",
	},
});
