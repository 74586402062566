import * as React from "react";
import TendersTalentDashboardBody from "./TendersTalentDashboardBody";
import TendersTalentDashboardDeleteModal from "./TendersTalentDashboardDeleteModal";
import TendersTalentDashboardHeader from "./TendersTalentDashboardHeader";
import { TendersTalentDashboardProps } from "./typing";
import { useRouter } from "next/router";

const TendersTalentDashboard = (props: TendersTalentDashboardProps) => {
	const { challengeTenderID, talents, deleteTalent, isMine } = props;
	const router = useRouter();
	const [state, setState] = React.useState({
		openedDeleteModal: false,
		idTalentToDelete: "",
	});
	const { openedDeleteModal, idTalentToDelete } = state;
	const hideDeleteModal = () => {
		setState({
			openedDeleteModal: false,
			idTalentToDelete: "",
		});
	};
	const onDeleteTalent = () => {
		deleteTalent(idTalentToDelete);
		hideDeleteModal();
	};
	const showDeleteModal = (idTalent: string) => {
		setState({
			openedDeleteModal: true,
			idTalentToDelete: idTalent,
		});
	};
	const handleOnUpdateTalent = (talentId: string) => {
		router.push(`/tenders/${challengeTenderID}/talents/update?talentId=${talentId}`);
	};

	return (
		<section className="TendersTalentDashboard">
			<TendersTalentDashboardDeleteModal
				open={openedDeleteModal}
				onCancel={hideDeleteModal}
				onDelete={onDeleteTalent}
			/>
			<TendersTalentDashboardHeader
				isMine={isMine}
				challengeTenderID={challengeTenderID}
			/>
			<TendersTalentDashboardBody
				talents={talents}
				onDeleteTalent={showDeleteModal}
				onUpdateTalent={handleOnUpdateTalent}
			/>
		</section>
	);
};

export default TendersTalentDashboard;
