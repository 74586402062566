import * as React from "react";

import { useTalentAppliersQuery } from "@api";

import TalentApplierModel from "models/talents/TalentApplier";

import { TeamUser } from "./TeamUser/TeamUser";
import { TeamUserLoading } from "./TeamUser/TeamUserLoading";

interface HiredPeopleFromTalentProps {
	talentTitle: string;
	talentId: number;
}

export const HiredPeopleFromTalent = ({ talentTitle, talentId }: HiredPeopleFromTalentProps) => {
	const { data: appliers, isLoading, isError } = useTalentAppliersQuery(talentId);

	if (isError) {
		return <></>;
	}

	if (isLoading || !appliers) {
		return <TeamUserLoading />;
	}

	const hiredAppliersModels = appliers
		.map((applier) => TalentApplierModel.generateFromAPI(applier))
		.filter((applier) => applier.status.key === "hired");

	return (
		<>
			{hiredAppliersModels.map((applier) => (
				<TeamUser
					key={applier.user.links.link}
					profileLink={applier.user.links.link}
					profileImage={applier.user.info.getAvatarImage()}
					profileFullName={`${applier.user.info.name} ${applier.user.info.surname}`}
					rolName={talentTitle}
				/>
			))}
		</>
	);
};
