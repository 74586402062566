import { defineMessages } from "react-intl";

export default defineMessages({
	title: {
		defaultMessage: "Postulate to the proper business profiles",
	},
	subTitle: {
		defaultMessage:
			"Catch the attention of business angels, incubators, and accelerators and make your project grow up",
	},
});
