import * as React from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";

import { useElearningsQuery } from "@api";
import { Button, InternalLink } from "@common";

import ElearningModel from "models/elearnings/Elearning";

import { ElearningsCard } from "../../ElearningsCard/ElearningsCard";
import { ElearningsCardLoading } from "../../ElearningsCard/ElearningsCardLoading";

import messages from "./messages";
import {useLocales} from "@libs";

export const ElearningsWorkshops = () => {
	const { data: workshops, isLoading: isWorkshopLoading } = useElearningsQuery(
		{
			page: 1,
			perPage: 4,
			orderDirection: "DESC",
			"search[type]": "Workshop",
		},
		{
			staleTime: 120_000,
		},
	);
	const {currentShortLocale} = useLocales();
	const workshopsModels = workshops?.items.map((workshop) => ElearningModel.generateFromAPI(workshop));

	return (
		<div>
			<div className="flex items-center justify-between gap-4">
				<div className="w-full lg:max-w-2xl">
					<h2 className="text-2xl font-extrabold xl:text-4xl">
						<FormattedMessage {...messages.title} />
					</h2>
					<p className="text-sm font-medium text-neutral-600">
						<FormattedMessage {...messages.subTitle} />
					</p>
				</div>
				<InternalLink
					className="shrink-0"
					href="/elearnings/explorer/workshops"
				>
					<Button
						size="xl"
						color="success"
					>
						<FormattedMessage {...messages.exploreAll} />
					</Button>
				</InternalLink>
			</div>

			<ul
				className={classNames(
					"relative mt-8 w-full overflow-x-auto rounded-xl bg-green-50 p-8 text-center",
					"flex items-stretch justify-between gap-4",
					"snap-x snap-mandatory",
					"xl:grid xl:w-auto xl:grid-cols-4 xl:overflow-x-hidden",
					"xl:snap-none",
				)}
			>
				{isWorkshopLoading
					? [1, 2, 3, 4].map((index) => (
							<div
								key={index}
								className={classNames(
									"flex w-full shrink-0 snap-center items-center justify-center",
									"xl:w-auto xl:flex-1",
								)}
							>
								<ElearningsCardLoading />
							</div>
					  ))
					: workshopsModels?.map((workshop) => (
							<div
								key={workshop.id}
								className={classNames(
									"flex w-full shrink-0 snap-center items-center justify-center",
									"xl:w-auto xl:flex-1",
								)}
							>
								<li
									className="h-full xl:w-full"
									data-testid={`ELEARNING_${workshop.type}_${workshop.id}`}
								>
									<ElearningsCard
										elearningId={Number(workshop.id)}
										title={workshop.title}
										elearningType={workshop.type}
										elearningModality={workshop.careerModality.text}
										href={workshop.getInternalURL(currentShortLocale)}
										imgSrc={workshop.getFormattedImage().src}
										startDate={dayjs(workshop.startDate)}
										endDate={dayjs(workshop.endDate)}
										businessProfile={{
											name: workshop.owner.info.fullName,
											imgSrc: workshop.owner.info.avatar.src,
											href: workshop.owner.info.url,
										}}
									/>
								</li>
							</div>
					  ))}
			</ul>
		</div>
	);
};
