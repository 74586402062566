import { defineMessages } from "react-intl";

import { MarketplaceOffer, MarketplaceType } from "@api";

export const marketplaceTypesMessages = defineMessages<MarketplaceType>({
	PRODUCT: {
		defaultMessage: "Products",
	},
	SERVICE: {
		defaultMessage: "Services",
	},
});

export const marketplaceOfferMessages = defineMessages<MarketplaceOffer>({
	SELL: {
		defaultMessage: "Sell",
	},
	VALIDATE: {
		defaultMessage: "Validate",
	},
});
