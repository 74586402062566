import { defineMessages } from "react-intl";

export default defineMessages({
	networkingPlatforms: {
		defaultMessage: "Networking platforms",
	},
	thereAreNoNetworkingPlatforms: {
		defaultMessage: "There are no networking platforms loaded yet",
	},
});
