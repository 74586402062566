import { useState } from "react";
import { useRouter } from "next/router";

import { TalentTypes } from "@api";
import { getListFromStringifiedList } from "@libs";

import CategoryModel from "models/categories/Category";

import * as Filters from "./Filters";

type CategoriesCounter = number;
type MinAndMaxFilters = {
	min: number | undefined;
	max: number | undefined;
};

type Distinct<T, DistinctName> = T & { __TYPE__: DistinctName };
type SectorsIdsClicked = Distinct<Record<number, boolean>, "SectorsIdsClicked">;
type RegionsIdsClicked = Distinct<Record<number, boolean>, "RegionsIdsClicked">;
type LanguagesIdsClicked = Distinct<Record<number, boolean>, "LanguagesIdsClicked">;
type SchoolsIdsClicked = Distinct<Record<number, boolean>, "SchoolsIdsClicked">;
type CountriesIdsClicked = Distinct<Record<number, boolean>, "CountriesIdsClicked">;
type AverageSalary = Distinct<MinAndMaxFilters, "AverageSalary">;
type AverageEquity = Distinct<MinAndMaxFilters, "AverageEquity">;
type CostPerHour = Distinct<MinAndMaxFilters, "CostPerHour">;

export type TalentsFilters = {
	talentType: TalentTypes | undefined;
	sectorsIds: number[];
	regionsIds: number[];
	languagesIds: number[];
	schoolsIds: number[];
	countriesIds: number[];
	averageSalary: AverageSalary;
	averageEquity: AverageEquity;
	costPerHour: CostPerHour;
	pageNumber: number;
};

export interface TalentsExplorerFiltersProps {
	topSectors: [CategoryModel, CategoriesCounter][];
	topRegions: [CategoryModel, CategoriesCounter][];
	topLanguages: [CategoryModel, CategoriesCounter][];
	topSchools: [CategoryModel, CategoriesCounter][];
	topCountries: [CategoryModel, CategoriesCounter][];
	onChangeFilters: (filters: Omit<TalentsFilters, "pageNumber">) => void;
	filtersIdsPrefix: {
		talentType: string;
		sectors: string;
		regions: string;
		languages: string;
		schools: string;
		countries: string;
		averageSalary: string;
		averageEquity: string;
		costPerHour: string;
	};
}

export const TalentsExplorerFilters = ({
	topSectors,
	topRegions,
	topLanguages,
	topSchools,
	topCountries,
	onChangeFilters,
	filtersIdsPrefix,
}: TalentsExplorerFiltersProps) => {
	const router = useRouter();
	const {
		talentType,
		sectorsIds,
		regionsIds,
		languagesIds,
		schoolsIds,
		countriesIds,
		minAverageSalary,
		maxAverageSalary,
		minAverageEquity,
		maxAverageEquity,
		minCostPerHour,
		maxCostPerHour,
	} = router.query;
	const initCategoriesIdsClickedFromRouterQuery = (categoriesIds: string[]): Record<number, boolean> => {
		return categoriesIds.reduce((acc, categoryId) => {
			return {
				...acc,
				[Number(categoryId)]: true,
			};
		}, {});
	};

	const [selectedTalentType, setSelectedTalentType] = useState<TalentTypes>(
		(talentType as TalentTypes) || TalentTypes.JOBS,
	);
	const [sectorsIdsClicked, setSectorsIdsClicked] = useState<SectorsIdsClicked>(
		initCategoriesIdsClickedFromRouterQuery(getListFromStringifiedList(sectorsIds as string)) as SectorsIdsClicked,
	);
	const [regionsIdsClicked, setRegionsIdsClicked] = useState<RegionsIdsClicked>(
		initCategoriesIdsClickedFromRouterQuery(getListFromStringifiedList(regionsIds as string)) as RegionsIdsClicked,
	);
	const [languagesIdsClicked, setLanguagesIdsClicked] = useState<LanguagesIdsClicked>(
		initCategoriesIdsClickedFromRouterQuery(getListFromStringifiedList(languagesIds as string)) as LanguagesIdsClicked,
	);
	const [schoolsIdsClicked, setSchoolsIdsClicked] = useState<SchoolsIdsClicked>(
		initCategoriesIdsClickedFromRouterQuery(getListFromStringifiedList(schoolsIds as string)) as SchoolsIdsClicked,
	);
	const [countriesIdsClicked, setCountriesIdsClicked] = useState<CountriesIdsClicked>(
		initCategoriesIdsClickedFromRouterQuery(getListFromStringifiedList(countriesIds as string)) as CountriesIdsClicked,
	);
	const [averageSalary, setAverageSalary] = useState<AverageSalary>({
		min: Number.isInteger(minAverageSalary) ? Number(minAverageSalary) : undefined,
		max: Number.isInteger(maxAverageSalary) ? Number(maxAverageSalary) : undefined,
	} as AverageSalary);
	const [averageEquity, setAverageEquity] = useState<AverageEquity>({
		min: Number.isInteger(minAverageEquity) ? Number(minAverageEquity) : undefined,
		max: Number.isInteger(maxAverageEquity) ? Number(maxAverageEquity) : undefined,
	} as AverageEquity);
	const [costPerHour, setCostPerHour] = useState<CostPerHour>({
		min: Number.isInteger(minCostPerHour) ? Number(minCostPerHour) : undefined,
		max: Number.isInteger(maxCostPerHour) ? Number(maxCostPerHour) : undefined,
	} as CostPerHour);

	const handleOnChangeFilters = (
		talentType: TalentTypes,
		sectorsIdsClicked: SectorsIdsClicked,
		regionsIdsClicked: RegionsIdsClicked,
		languagesIdsClicked: LanguagesIdsClicked,
		schoolsIdsClicked: SchoolsIdsClicked,
		countriesIdsClicked: CountriesIdsClicked,
		averageSalary: AverageSalary,
		averageEquity: AverageEquity,
		costPerHour: CostPerHour,
	) => {
		const sectorsIds = Object.keys(sectorsIdsClicked)
			.filter((sectorId) => sectorsIdsClicked[Number(sectorId)])
			.map((sectorId) => Number(sectorId));
		const regionsIds = Object.keys(regionsIdsClicked)
			.filter((regionId) => regionsIdsClicked[Number(regionId)])
			.map((regionId) => Number(regionId));
		const languagesIds = Object.keys(languagesIdsClicked)
			.filter((languageId) => languagesIdsClicked[Number(languageId)])
			.map((languageId) => Number(languageId));
		const schoolsIds = Object.keys(schoolsIdsClicked)
			.filter((schoolId) => schoolsIdsClicked[Number(schoolId)])
			.map((schoolId) => Number(schoolId));
		const countriesIds = Object.keys(countriesIdsClicked)
			.filter((countryId) => countriesIdsClicked[Number(countryId)])
			.map((countryId) => Number(countryId));

		onChangeFilters({
			talentType,
			sectorsIds,
			regionsIds,
			languagesIds,
			schoolsIds,
			countriesIds,
			averageSalary,
			averageEquity,
			costPerHour,
		});
	};

	const handleOnChangeTalentType = (newTalentType: TalentTypes) => {
		setSelectedTalentType(newTalentType);
		handleOnChangeFilters(
			newTalentType,
			sectorsIdsClicked,
			regionsIdsClicked,
			languagesIdsClicked,
			schoolsIdsClicked,
			countriesIdsClicked,
			averageSalary,
			averageEquity,
			costPerHour,
		);
	};
	const handleOnCheckedSector = (categoryId: number) => {
		const newSectorsIdsClicked: SectorsIdsClicked = {
			...sectorsIdsClicked,
			[categoryId]: !sectorsIdsClicked[categoryId],
		};

		setSectorsIdsClicked(newSectorsIdsClicked);
		handleOnChangeFilters(
			selectedTalentType,
			newSectorsIdsClicked,
			regionsIdsClicked,
			languagesIdsClicked,
			schoolsIdsClicked,
			countriesIdsClicked,
			averageSalary,
			averageEquity,
			costPerHour,
		);
	};
	const handleOnCheckedRegion = (categoryId: number) => {
		const newRegionsIdsClicked: RegionsIdsClicked = {
			...regionsIdsClicked,
			[categoryId]: !regionsIdsClicked[categoryId],
		};

		setRegionsIdsClicked(newRegionsIdsClicked);
		handleOnChangeFilters(
			selectedTalentType,
			sectorsIdsClicked,
			newRegionsIdsClicked,
			languagesIdsClicked,
			schoolsIdsClicked,
			countriesIdsClicked,
			averageSalary,
			averageEquity,
			costPerHour,
		);
	};
	const handleOnCheckedLanguage = (categoryId: number) => {
		const newLanguagesIdsClicked: LanguagesIdsClicked = {
			...languagesIdsClicked,
			[categoryId]: !languagesIdsClicked[categoryId],
		};

		setLanguagesIdsClicked(newLanguagesIdsClicked);
		handleOnChangeFilters(
			selectedTalentType,
			sectorsIdsClicked,
			regionsIdsClicked,
			newLanguagesIdsClicked,
			schoolsIdsClicked,
			countriesIdsClicked,
			averageSalary,
			averageEquity,
			costPerHour,
		);
	};
	const handleOnCheckedSchool = (categoryId: number) => {
		const newSchoolsIdsClicked: SchoolsIdsClicked = {
			...schoolsIdsClicked,
			[categoryId]: !schoolsIdsClicked[categoryId],
		};

		setSchoolsIdsClicked(newSchoolsIdsClicked);
		handleOnChangeFilters(
			selectedTalentType,
			sectorsIdsClicked,
			regionsIdsClicked,
			languagesIdsClicked,
			newSchoolsIdsClicked,
			countriesIdsClicked,
			averageSalary,
			averageEquity,
			costPerHour,
		);
	};
	const handleOnCheckedCountry = (categoryId: number) => {
		const newCountriesIdsClicked: CountriesIdsClicked = {
			...countriesIdsClicked,
			[categoryId]: !countriesIdsClicked[categoryId],
		};

		setCountriesIdsClicked(newCountriesIdsClicked);
		handleOnChangeFilters(
			selectedTalentType,
			sectorsIdsClicked,
			regionsIdsClicked,
			languagesIdsClicked,
			schoolsIdsClicked,
			newCountriesIdsClicked,
			averageSalary,
			averageEquity,
			costPerHour,
		);
	};
	const handleOnChangeAverageSalary = (min?: number, max?: number) => {
		const newAverageSalary: AverageSalary = {
			min,
			max,
		} as AverageSalary;
		setAverageSalary(newAverageSalary);
		handleOnChangeFilters(
			selectedTalentType,
			sectorsIdsClicked,
			regionsIdsClicked,
			languagesIdsClicked,
			schoolsIdsClicked,
			countriesIdsClicked,
			newAverageSalary,
			averageEquity,
			costPerHour,
		);
	};
	const handleOnChangeAverageEquity = (min?: number, max?: number) => {
		const newAverageEquity: AverageEquity = {
			min,
			max,
		} as AverageEquity;
		setAverageEquity(newAverageEquity);
		handleOnChangeFilters(
			selectedTalentType,
			sectorsIdsClicked,
			regionsIdsClicked,
			languagesIdsClicked,
			schoolsIdsClicked,
			countriesIdsClicked,
			averageSalary,
			newAverageEquity,
			costPerHour,
		);
	};
	const handleOnChangeCostPerHour = (min?: number, max?: number) => {
		const newCostPerHour: CostPerHour = {
			min,
			max,
		} as CostPerHour;
		setCostPerHour(newCostPerHour);
		handleOnChangeFilters(
			selectedTalentType,
			sectorsIdsClicked,
			regionsIdsClicked,
			languagesIdsClicked,
			schoolsIdsClicked,
			countriesIdsClicked,
			averageSalary,
			averageEquity,
			newCostPerHour,
		);
	};

	const isEitherJobOrCoFounder =
		selectedTalentType === TalentTypes.JOBS || selectedTalentType === TalentTypes.COFOUNDER;

	return (
		<>
			<Filters.TalentType
				filterIdsPrefix={filtersIdsPrefix.talentType}
				onChangeType={handleOnChangeTalentType}
				currentSelectedTalentType={selectedTalentType}
			/>
			<Filters.AverageEquity
				filterIdsPrefix={filtersIdsPrefix.averageEquity}
				currentSelectedTalentsAverageEquity={{
					min: averageEquity.min,
					max: averageEquity.max,
				}}
				onChangeAverageEquity={handleOnChangeAverageEquity}
			/>

			{isEitherJobOrCoFounder && (
				<>
					<Filters.AverageSalary
						filterIdsPrefix={filtersIdsPrefix.averageSalary}
						currentSelectedTalentsAverageSalary={{
							min: averageSalary.min,
							max: averageSalary.max,
						}}
						onChangeAverageSalary={handleOnChangeAverageSalary}
					/>
					<Filters.Sectors
						sectors={topSectors}
						sectorsIdsClicked={sectorsIdsClicked}
						onChangeOneSector={handleOnCheckedSector}
						filterIdsPrefix={filtersIdsPrefix.sectors}
					/>
					<Filters.Countries
						countries={topCountries}
						countriesIdsClicked={countriesIdsClicked}
						onChangeOneCountry={handleOnCheckedCountry}
						filterIdsPrefix={filtersIdsPrefix.countries}
					/>
					<Filters.Schools
						schools={topSchools}
						schoolsIdsClicked={schoolsIdsClicked}
						onChangeOneSchool={handleOnCheckedSchool}
						filterIdsPrefix={filtersIdsPrefix.schools}
					/>
				</>
			)}

			{selectedTalentType === TalentTypes.ADVISER && (
				<>
					<Filters.CostPerHour
						filterIdsPrefix={filtersIdsPrefix.costPerHour}
						currentSelectedTalentsCostPerHour={{
							min: averageSalary.min,
							max: averageSalary.max,
						}}
						onChangeCostPerHour={handleOnChangeCostPerHour}
					/>
					<Filters.Regions
						regions={topRegions}
						regionsIdsClicked={regionsIdsClicked}
						onChangeOneRegion={handleOnCheckedRegion}
						filterIdsPrefix={filtersIdsPrefix.regions}
					/>
					<Filters.Languages
						languages={topLanguages}
						languagesIdsClicked={languagesIdsClicked}
						onChangeOneLanguage={handleOnCheckedLanguage}
						filterIdsPrefix={filtersIdsPrefix.languages}
					/>
				</>
			)}
		</>
	);
};
