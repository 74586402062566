import {TalentLandscapeCard} from "containers/Searcher/ContentSearcher/TalentLandscapeCard/TalentLandscapeCard";
import {TalentLandscapeCardActions} from "containers/Searcher/ContentSearcher/TalentLandscapeCard/typing";

import {TalentDashboardTexts} from "i18n/containers/TalentDashboard";
import {TalentDashboardTranslation} from "i18n/containers/TalentDashboard/typing";
import TalentModel from "models/talents/Talent";
import * as React from "react";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import {useLocales} from "@libs";

const TendersTalentDashboardBody = (props: { talents: TalentModel[] } & TalentLandscapeCardActions) => {
    const {talents = [], onDeleteTalent, onUpdateTalent} = props;
    const {currentShortLocale} = useLocales();
    const tendersTalentDashboardTranslation: TalentDashboardTranslation = {
        ...TalentDashboardTexts[currentShortLocale],
    };

    return (
        <section className="TendersTalentDashboard__selection-process">
            {talents.length > 0 ? (
                talents.map((item) => (
                    <TalentLandscapeCard
                        {...item}
                        key={`${item.id}`}
                        onDeleteTalent={onDeleteTalent}
                        onUpdateTalent={onUpdateTalent}
                    />
                ))
            ) : (
                <Message
                    className="full-width margin-1-top"
                    content={tendersTalentDashboardTranslation.noTalents}
                />
            )}
        </section>
    );
};

export default React.memo(TendersTalentDashboardBody);
