import { useIntl } from "react-intl";

import { Filter, InputCheckboxField, ShowMoreToggle } from "@common";

import CategoryModel from "models/categories/Category";

import messages from "../messages";

type CategoriesCounter = number;

interface LanguagesProps {
	languages: [CategoryModel, CategoriesCounter][];
	languagesIdsClicked: Record<number, boolean>;
	onChangeOneLanguage: (categoryId: number) => void;
	filterIdsPrefix: string;
}

export const Languages = ({ languages, languagesIdsClicked, onChangeOneLanguage, filterIdsPrefix }: LanguagesProps) => {
	const { formatMessage } = useIntl();

	return (
		<Filter
			id="languages"
			title={formatMessage(messages.languages)}
		>
			<ShowMoreToggle
				initialValue={false}
				className="flex flex-col gap-2"
				items={languages.map(([language, counter]) => {
					const handleOnChangeLanguage = () => onChangeOneLanguage(Number(language.id));

					return (
						<InputCheckboxField
							key={language.key}
							id={filterIdsPrefix + String(language.id)}
							name={language.key}
							checked={languagesIdsClicked[language.id as number]}
							onChange={handleOnChangeLanguage}
							labelValue={
								<p className="flex w-full">
									<span className="flex-1"> {language.text} </span>
									<span className="shrink-0 text-neutral-400"> ({counter}) </span>
								</p>
							}
						/>
					);
				})}
			/>
		</Filter>
	);
};
