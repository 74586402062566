import * as React from "react";

import {
	OnboardingStartupModal,
	OnboardingAdviserModal,
	OnboardingCompanyModal,
	OnboardingAcceleratorModal,
	OnboardingIncubatorModal,
	OnboardingBusinessAngelModal,
	OnboardingUniversityModal,
	OnboardingInstitutionModal,
} from "../Onboarding/OnboardingModal";

import { SelectBusinessProfileTranslation } from "i18n/components/SelectBusinessProfile/typing";

const AcceleratorImage = "/accelerator_onboarding.jpg";
const AngelImage = "/angel_onboarding.jpg";
const CompanyImage = "/company_onboarding.jpg";
const InstitutionImage = "/institution_onboarding.jpg";
const StartupImage = "/startup_onboarding.jpg";
const UniversityImage = "/university_onboarding.jpg";
const AdviserImage = "/adviser_onboarding.jpg";
const IncubatorImage = "/incubator_onboarding.jpg";

type BusinessProfilesModal = {
	image: {
		src: string;
	};
	header: string;
	description: string;
	modal: JSX.Element;
};

const BusinessProfilesModalsList = (
	profileTranslations: SelectBusinessProfileTranslation,
	hasReachedTheLimitOfCreatedAdvisers: boolean,
): BusinessProfilesModal[] => [
	{
		image: {
			src: String(StartupImage),
		},
		header: profileTranslations.titleStartup,
		description: profileTranslations.descriptionStartup,
		modal: <OnboardingStartupModal />,
	},
	{
		image: {
			src: String(AdviserImage),
		},
		header: profileTranslations.titleAdviser,
		description: profileTranslations.descriptionAdviser,
		modal: <OnboardingAdviserModal hasReachedTheLimit={hasReachedTheLimitOfCreatedAdvisers} />,
	},
	{
		image: {
			src: String(CompanyImage),
		},
		header: profileTranslations.titleCompany,
		description: profileTranslations.descriptionCompany,
		modal: <OnboardingCompanyModal />,
	},
	{
		image: {
			src: String(AcceleratorImage),
		},
		header: profileTranslations.titleAccelerator,
		description: profileTranslations.descriptionAccelerator,
		modal: <OnboardingAcceleratorModal />,
	},
	{
		image: {
			src: String(IncubatorImage),
		},
		header: profileTranslations.titleIncubator,
		description: profileTranslations.descriptionIncubator,
		modal: <OnboardingIncubatorModal />,
	},
	{
		image: {
			src: String(AngelImage),
		},
		header: profileTranslations.titleBusinessAngel,
		description: profileTranslations.descriptionBusinessAngel,
		modal: <OnboardingBusinessAngelModal />,
	},
	{
		image: {
			src: String(UniversityImage),
		},
		header: profileTranslations.titleUniversity,
		description: profileTranslations.descriptionUniversity,
		modal: <OnboardingUniversityModal />,
	},
	{
		image: {
			src: String(InstitutionImage),
		},
		header: profileTranslations.titleInstitution,
		description: profileTranslations.descriptionInstitution,
		modal: <OnboardingInstitutionModal />,
	},
];

export default BusinessProfilesModalsList;
