import * as React from "react";

import { useTenderTalentsQuery } from "@api";

import TalentModel from "models/talents/Talent";

import { ChallengeTenderProps } from "@app-components";

import { TenderDescription } from "./TenderDescription/TenderDescription";
import { TenderTalents } from "./TenderTalents/TenderTalents";
import { TenderImages } from "./TenderImages/TenderImages";
import { TenderTeam } from "./TenderTeam/TenderTeam";
import { TenderMentors } from "./TenderTeam/TenderMentors/TenderMentors";
import { TenderNetworks } from "./TenderNetworks/TenderNetworks";

type ChallengeTenderGeneralProps = Pick<ChallengeTenderProps, "tender">;

export const ChallengeTenderGeneral = ({ tender }: ChallengeTenderGeneralProps) => {
	const { data: tenderTalents, isLoading: isTenderTalentsLoading } = useTenderTalentsQuery(tender.id);

	const tenderTalentsModels = tenderTalents?.map((tenderTalent) => TalentModel.generateFromAPI(tenderTalent)) || [];

	return (
		<>
			<TenderDescription tender={tender} />
			<TenderTalents
				tenderId={tender.id}
				talents={tenderTalentsModels}
				isLoading={isTenderTalentsLoading}
			/>
			<TenderImages tender={tender} />
			<TenderTeam
				tender={tender}
				talents={tenderTalentsModels}
				isTenderTalentsLoading={isTenderTalentsLoading}
			/>
			<TenderMentors tender={tender} />
			<TenderNetworks tender={tender} />
		</>
	);
};
