import { defineMessages } from "react-intl";

export default defineMessages({
	wantJoinTheTeam: {
		defaultMessage: "Want to join the team?",
	},
	applyToTalentOffers: {
		defaultMessage: "Apply to the talent offers",
	},
	viewAllTalentOffers: {
		defaultMessage: "View all talent offers",
	},
});
