import * as React from "react";
import Image from "next/image";

import { Card, InternalLink } from "@common";

interface TeamUserProps {
	profileLink: string;
	profileImage: string;
	profileFullName: string;
	profileEmblem: string;
	rolName: string;
}

export const TeamUser = ({ profileLink, profileImage, profileEmblem, profileFullName, rolName }: TeamUserProps) => {
	return (
		<Card className="relative mt-20">
			<div className="absolute -top-16 right-1/2 mb-4 translate-x-1/2 transform text-center">
				<InternalLink
					className="relative mx-auto block h-40 w-40"
					href={`/profile/${profileLink}`}
				>
					<Image
						className="rounded-full object-cover"
						layout="fill"
						src={profileImage}
						alt={profileFullName}
					/>
				</InternalLink>
			</div>
			<div className="rounded-lg bg-white pt-24 dark:bg-gray-800">
				<div className="pb-4 text-center">
					<InternalLink
						href={`/profile/${profileLink}`}
						className="text-xl text-gray-800 dark:text-white"
					>
						{profileFullName}
					</InternalLink>
					<p className="text-lg font-light text-gray-500 dark:text-gray-200">{rolName}</p>
					{profileEmblem && (
						<p className="text-md mx-auto font-light italic text-gray-400 dark:text-gray-400">
							&quot;{profileEmblem}&quot;
						</p>
					)}
				</div>
			</div>
		</Card>
	);
};
