import { defineMessages } from "react-intl";

export default defineMessages({
	elearningTypes: {
		defaultMessage: "Elearning types",
	},
	courses: {
		defaultMessage: "Courses",
	},
	webinars: {
		defaultMessage: "Webinars",
	},
	workshops: {
		defaultMessage: "Workshops",
	},
	elearningModalities: {
		defaultMessage: "Elearning modalities",
	},
	search: {
		defaultMessage: "Search",
	},
});
