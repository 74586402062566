import { ChangeEventHandler } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { TalentTypes } from "@api";
import { Briefcase, Filter, Select } from "@common";

import messages from "../messages";

import { talentTypesMessages } from "./messages";

interface TypeProps {
	onChangeType: (talentType: TalentTypes) => void;
	currentSelectedTalentType?: TalentTypes;
	filterIdsPrefix: string;
}

export const TalentType = ({ onChangeType, currentSelectedTalentType, filterIdsPrefix }: TypeProps) => {
	const { formatMessage } = useIntl();
	const talentOptions: TalentTypes[] = [TalentTypes.JOBS, TalentTypes.COFOUNDER, TalentTypes.ADVISER];

	const onChangeTalentType: ChangeEventHandler<HTMLSelectElement> = (event) => {
		if (talentOptions.includes(event.target.value as TalentTypes)) {
			onChangeType(event.target.value as TalentTypes);
			return;
		}

		onChangeType(TalentTypes.JOBS);
	};

	return (
		<Filter
			id="type"
			title={formatMessage(messages.type)}
		>
			<Select
				name="talent-type"
				id={`${filterIdsPrefix}-talent-type`}
				sizing="lg"
				icon={Briefcase}
				onChange={onChangeTalentType}
				shadow
				value={currentSelectedTalentType || TalentTypes.JOBS}
			>
				{talentOptions.map((talentOption) => (
					<option
						key={talentOption}
						value={talentOption}
					>
						<FormattedMessage {...talentTypesMessages[talentOption]} />
					</option>
				))}
			</Select>
		</Filter>
	);
};
