import { HTMLProps, ReactNode } from "react";
import { DropdownItemProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";
import { SemanticCOLORS } from "semantic-ui-react/dist/commonjs/generic";

export enum FilterSearcherGroupType {
	MODAL_SELECT_GROUP = "MODAL_SELECT_GROUP",
}

export enum FilterSearcherItemType {
	BUTTON_LINK = "BUTTON_LINK",
	CHECKBOX = "CHECKBOX",
	SELECT = "SELECT",
	MODAL_SELECT = "MODAL_SELECT",
	SLIDER = "SLIDER",
	RANGE = "RANGE",
}

export interface ButtonLinkSearcherItemProps {
	anchorProps: HTMLProps<HTMLAnchorElement>;
	label: string;
	color: SemanticCOLORS;
}

export interface FilterSearcherItemValueProps {
	text: string | number | ReactNode;
	value: string | number;
	[key: string]: any;
}

export interface FilterSearcherGroup {
	title: string;
	value: any[];
	[key: string]: any;
}

export interface FilterSearcherItemProps {
	title: string;
	value: FilterSearcherItemValueProps | DropdownItemProps[] | FilterSearcherGroup[] | ButtonLinkSearcherItemProps;
	type: FilterSearcherItemType | FilterSearcherGroupType;
	onChange?: ([]: any) => void;
	[key: string]: any;
}

export interface FilterSearcherSectionProps {
	title: string;
	items: FilterSearcherItemProps[];
	onChange: (
		fullKey: string,
		value: string | FilterSearcherGroup[] | boolean | number,
		restartSection?: boolean,
	) => void;
	keyword: string;
}

export interface FilterSearcherProps {
	sections: FilterSearcherSectionProps[];
}
