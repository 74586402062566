import { defineMessages } from "react-intl";

import { TalentTypes } from "@api";

export const talentTypesMessages = defineMessages<TalentTypes>({
	[TalentTypes.JOBS]: {
		defaultMessage: "Talents",
	},
	[TalentTypes.COFOUNDER]: {
		defaultMessage: "Co-founders",
	},
	[TalentTypes.ADVISER]: {
		defaultMessage: "Advisers",
	},
});
