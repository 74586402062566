import { defineMessages } from "react-intl";

export default defineMessages({
	topSchool: {
		defaultMessage: "Top schools",
	},
	topSchoolSubParagraph: {
		defaultMessage: "Trending schools to improve your skills",
	},
});
