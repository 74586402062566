import * as React from "react";
import { useRouter } from "next/router";
import Link from "next/link";

import { CookiesUtils } from "@utils";


import { ButtonsTexts } from "i18n/components/Buttons";
import { ButtonsTranslation } from "i18n/components/Buttons/typing";
import { ChallengesTexts } from "i18n/components/Challenges/index";
import { ChallengesTranslation } from "i18n/components/Challenges/typing";
import { getSocial, SocialItemProps } from "constants/URLs";
import ChallengeTenderModel, { ChallengeTenderSocialType } from "models/challenge-tenders/ChallengeTender";
import { deleteChallengeTenderAPI, patchChallengeTenderAPI } from "api/ChallengeTenderAPI";
import { Image } from "elements/HeroImage/typing";

import UnitChallengeTenderFieldDescription from "./Fields/UnitChallengeTenderFieldDescription";
import UnitChallengeTenderFieldImage from "./Fields/UnitChallengeTenderFieldImage";
import UnitChallengeTenderFieldImages from "./Fields/UnitChallengeTenderFieldImages";
import UnitChallengeTenderFieldTitle from "./Fields/UnitChallengeTenderFieldTitle";
import UnitChallengeTendersFieldFiles from "./Fields/UnitChallengeTendersFieldFiles";
import UnitChallengeTenderFieldSocial from "./Fields/UnitChallengeTenderFieldSocial";
import TenderSettingsMenu, { TenderSettingsMenuItem } from "./TenderSettingsMenu";

import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {useLocales} from "@libs";

const getSanitizedValue = (value: string, item: SocialItemProps) => {
	let sanitizedValue: string;
	sanitizedValue = value.replace(item.longRoot, "");

	return sanitizedValue;
};

//TODO Refactor this section
export const TenderSettings = (props: { tender: ChallengeTenderModel }) => {
	const { currentShortLocale } = useLocales();
	const router = useRouter();
	const { activeSection = TenderSettingsMenuItem.GENERAL } = router.query;
	const [tender, setTender] = React.useState(props.tender);
	const [validTitle, isValidTitle] = React.useState(true);
	const [stateRequest, setStateRequest] = React.useState({
		done: false,
		error: false,
		loading: false,
	});
	const challengesTranslations: ChallengesTranslation = {
		...ChallengesTexts[currentShortLocale],
	};
	const buttonTranslation: ButtonsTranslation = {
		...ButtonsTexts[currentShortLocale],
	};
	const patchChallengeTender = (tenderData: any) => {
		const accessToken = CookiesUtils.getAccessTokenFromCookie();

		setStateRequest({
			done: false,
			error: false,
			loading: true,
		});

		patchChallengeTenderAPI(tenderData, tender.id, accessToken)()
			.then((res) => {
				if (res.message) {
					setStateRequest({
						done: false,
						error: true,
						loading: false,
					});
				} else {
					setStateRequest({
						done: true,
						error: false,
						loading: false,
					});
				}
			})
			.catch(() =>
				setStateRequest({
					done: false,
					error: true,
					loading: false,
				}),
			);
	};
	const deleteChallengeTender = () => {
		const accessToken = CookiesUtils.getAccessTokenFromCookie();

		deleteChallengeTenderAPI(tender.id, accessToken)();

		router.push(`/challenge/${tender.challenge.slug}`);
	};
	const handleOnSaveChanges = () => {
		patchChallengeTender(tender.exportToAPIRequest());
	};
	const handleOnChangeImage = (image: Image) => {
		setTender(
			ChallengeTenderModel.generateFromObject({
				...tender,
				coverImage: image.src,
			}),
		);
	};
	const handleOnChangeImages = (images: Image[]) => {
		setTender(
			ChallengeTenderModel.generateFromObject({
				...tender,
				images: images.map((item) => item.src),
			}),
		);
	};
	const handleOnChangeTitle = (newTitle: string) => {
		setTender(
			ChallengeTenderModel.generateFromObject({
				...tender,
				title: newTitle,
			}),
		);
	};
	const handleOnChangeSocial =
		(newSocial: ChallengeTenderSocialType) => (socialProps: SocialItemProps) => (newValue: string) => {
			setTender(
				ChallengeTenderModel.generateFromObject({
					...tender,
					socialNetwork: {
						...tender.socialNetwork,
						[newSocial]: socialProps.longRoot + newValue,
					},
				}),
			);
		};
	const validateTitle = (isValid: boolean) => isValidTitle(isValid);
	const handleOnChangeDescription = (newDescription: React.ReactText) => {
		setTender(
			ChallengeTenderModel.generateFromObject({
				...tender,
				description: String(newDescription),
			}),
		);
	};

	return (
		<div className="flex w-full flex-col gap-8 px-32">
			{stateRequest.done && (
				<Message
					success
					header={challengesTranslations.savedCorrectly}
					content={challengesTranslations.savedDesc}
				/>
			)}
			{stateRequest.error && (
				<Message
					error
					header={challengesTranslations.savedUncorrectly}
					content={challengesTranslations.noSavedDesc}
				/>
			)}
			<h2 className="padding-2-top">
				<Link
					href={`/tenders/${tender.id}`}
					passHref
				>
					<Button
						as="a"
						className="middle-inline-block margin-1-right"
						basic
					>
						<Icon name="arrow left" />
						{challengesTranslations.backToProposal}
					</Button>
				</Link>
				{challengesTranslations.settingFor} &quot;{tender.title}&quot;
			</h2>
			<div className="mx-auto flex w-full">
				<TenderSettingsMenu
					tenderId={tender.id}
					activeSection={activeSection as TenderSettingsMenuItem}
				/>
				{activeSection === TenderSettingsMenuItem.GENERAL && (
					<Form className="UnitChallengeTendersSettings__fields padding-4-x">
						<UnitChallengeTenderFieldTitle
							title={tender.title}
							onChange={handleOnChangeTitle}
							validate={validateTitle}
						/>
						<UnitChallengeTenderFieldDescription
							description={tender.description}
							onChange={handleOnChangeDescription}
						/>
						<Button
							className="padding-3-x"
							color="green"
							disabled={stateRequest.loading || !validTitle}
							loading={stateRequest.loading}
							onClick={handleOnSaveChanges}
						>
							<Icon name="save" />
							{buttonTranslation.save}
						</Button>
					</Form>
				)}
				{activeSection === TenderSettingsMenuItem.IMAGES && (
					<Form className="UnitChallengeTendersSettings__fields padding-4-x">
						<UnitChallengeTenderFieldImage
							image={tender.getFormattedCoverImage()}
							onChange={handleOnChangeImage}
						/>
						<UnitChallengeTenderFieldImages
							images={tender.getFormatedImages() ? tender.getFormatedImages() : []}
							onChange={handleOnChangeImages}
						/>
						<Button
							className="padding-3-x"
							color="green"
							disabled={stateRequest.loading}
							loading={stateRequest.loading}
							onClick={handleOnSaveChanges}
						>
							<Icon name="save" />
							{buttonTranslation.save}
						</Button>
					</Form>
				)}
				{activeSection === TenderSettingsMenuItem.DOCUMENTS && (
					<Form className="UnitChallengeTendersSettings__fields padding-4-x">
						<UnitChallengeTendersFieldFiles
							tender={tender}
							setTender={setTender}
							requestLoading={stateRequest.loading}
							patchChallengeTender={patchChallengeTender}
						/>
					</Form>
				)}
				{activeSection === TenderSettingsMenuItem.STREAMING && (
					<Form className="UnitChallengeTendersSettings__fields padding-4-x">
						<h3 className="font-color-grey">Streaming</h3>
						<Message
							info
							header={challengesTranslations.settingForStreamingInfoTitle}
							content={
								<>
									<p>{challengesTranslations.settingForStreamingInfoDescParagraph1}</p>
									<p>{challengesTranslations.settingForStreamingInfoDescParagraph2(getSocial().meet.profileRoot)}</p>
								</>
							}
						/>
						<UnitChallengeTenderFieldSocial
							item={getSocial().meet}
							currentValue={getSanitizedValue(
								tender.socialNetwork[ChallengeTenderSocialType.GOOGLE_MEET],
								getSocial().meet,
							)}
							onChange={handleOnChangeSocial(ChallengeTenderSocialType.GOOGLE_MEET)(getSocial().meet)}
							actionColor="google plus"
							actionIcon="google plus"
						/>
						<UnitChallengeTenderFieldSocial
							item={getSocial().team}
							currentValue={getSanitizedValue(
								tender.socialNetwork[ChallengeTenderSocialType.MICROSOFT_TEAM],
								getSocial().team,
							)}
							onChange={handleOnChangeSocial(ChallengeTenderSocialType.MICROSOFT_TEAM)(getSocial().team)}
							actionColor="violet"
							actionIcon="microsoft"
						/>
						<UnitChallengeTenderFieldSocial
							item={getSocial().zoom}
							currentValue={getSanitizedValue(tender.socialNetwork[ChallengeTenderSocialType.ZOOM], getSocial().zoom)}
							onChange={handleOnChangeSocial(ChallengeTenderSocialType.ZOOM)(getSocial().zoom)}
							actionColor="blue"
							actionIcon="zoom"
						/>
						<UnitChallengeTenderFieldSocial
							item={getSocial().trello}
							currentValue={getSanitizedValue(
								tender.socialNetwork[ChallengeTenderSocialType.TRELLO],
								getSocial().trello,
							)}
							onChange={handleOnChangeSocial(ChallengeTenderSocialType.TRELLO)(getSocial().trello)}
							actionColor="vk"
							actionIcon="trello"
						/>
						<Button
							className="padding-3-x margin-2-top"
							color="green"
							basic
							disabled={stateRequest.loading || !validTitle}
							loading={stateRequest.loading}
							onClick={handleOnSaveChanges}
						>
							<Icon name="save" />
							{buttonTranslation.save}
						</Button>
					</Form>
				)}
				{activeSection === TenderSettingsMenuItem.DELETE_TENDER && (
					<Form className="UnitChallengeTendersSettings__fields padding-4-x">
						<h3 className="font-color-grey">{challengesTranslations.deleteProposal}</h3>
						<p>{challengesTranslations.deleteWarning}</p>
						<Button
							negative
							onClick={deleteChallengeTender}
						>
							{challengesTranslations.deleteProposal}
						</Button>
					</Form>
				)}
			</div>
		</div>
	);
};
