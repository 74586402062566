import {ButtonsTexts} from "i18n/components/Buttons";
import {ButtonsTranslation} from "i18n/components/Buttons/typing";
import {TalentDashboardTexts} from "i18n/containers/TalentDashboard";
import {TalentDashboardTranslation} from "i18n/containers/TalentDashboard/typing";
import Link from "next/link";
import * as React from "react";
import {useLocales} from "@libs";

const TendersTalentDashboardHeader = (props: { challengeTenderID: number; isMine: boolean }) => {
    const {challengeTenderID, isMine} = props;
    const linkToCreateNew = `/tenders/${challengeTenderID}/talents/new`;
    const {currentShortLocale} = useLocales();
    const buttonsTranslations: ButtonsTranslation = {
        ...ButtonsTexts[currentShortLocale],
    };
    const TendersTalentDashboardTranslation: TalentDashboardTranslation = {
        ...TalentDashboardTexts[currentShortLocale],
    };

    return (
        <header>
            <h2>{TendersTalentDashboardTranslation.talentDashboard}</h2>
            {isMine && (
                <div>
                    <Link
                        href={linkToCreateNew}
                        passHref
                    >
                        <a>{buttonsTranslations.createNew}</a>
                    </Link>
                </div>
            )}
        </header>
    );
};

export default React.memo(TendersTalentDashboardHeader);
