import * as React from "react";

import ChallengeTenderModel from "models/challenge-tenders/ChallengeTender";

import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";

import { TenderTeam } from "./TenderTeam";
import { TenderMentors } from "./TenderMentors/TenderMentors";

export const ChallengeTenderTeam = ({ tender }: { tender: ChallengeTenderModel }) => (
	<main className="flex flex-col gap-10 px-20">
		<TenderTeam tender={tender} />
		<Divider />
		<TenderMentors tender={tender} />
	</main>
);
