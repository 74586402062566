import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Card, InternalLink } from "@common";

import InternalFormTextarea from "elements/InternalFormTextarea/InternalFormTextarea";
import ChallengeTenderModel from "models/challenge-tenders/ChallengeTender";

import { TenderDocuments } from "./TenderDocuments";
import { TenderProfilesInvolved } from "./TenderProfilesInvolved/TenderProfilesInvolved";
import messages from "./messages";

interface TenderDescriptionProps {
	tender: ChallengeTenderModel;
}

export const TenderDescription = ({ tender }: TenderDescriptionProps) => {
	const { formatMessage } = useIntl();
	const profileAdminModel = tender.admin;

	return (
		<Card className="-mt-8 w-full max-w-none p-20">
			<div>
				<h1 className="flex flex-col">{tender.title}</h1>
				<p className="mt-2 mb-4 flex gap-1">
					<FormattedMessage
						{...messages.adminBy}
						values={{
							administrator: (
								<InternalLink href={`/profile/${profileAdminModel.links.link}`}>
									{profileAdminModel.info.name} {profileAdminModel.info.surname}
								</InternalLink>
							),
						}}
					/>
				</p>
				{tender.description ? (
					<p className="my-8">
						<InternalFormTextarea
							placeholder={formatMessage(messages.challengesDescription)}
							displayWithoutScrolling
							displayWithoutPadding
							value={tender.description}
						/>
					</p>
				) : (
					<p className="my-8 text-gray-500">
						<FormattedMessage {...messages.missingDescription} />
					</p>
				)}
				<TenderDocuments tender={tender} />
				<h5 className="text-lg">
					<FormattedMessage {...messages.relatedProfiles} />
				</h5>
				<div className="mt-10 flex w-full items-center gap-4">
					<TenderProfilesInvolved tender={tender} />
				</div>
			</div>
		</Card>
	);
};
