import { defineMessages } from "react-intl";

import { MarketplaceOffer, MarketplaceType } from "@api";

export const marketplaceTypesMessages = defineMessages<MarketplaceType | "ALL">({
	PRODUCT: {
		defaultMessage: "Products",
	},
	SERVICE: {
		defaultMessage: "Services",
	},
	ALL: {
		defaultMessage: "All",
	},
});

export const marketplaceOfferMessages = defineMessages<MarketplaceOffer | "ALL">({
	SELL: {
		defaultMessage: "Sell",
	},
	VALIDATE: {
		defaultMessage: "Validate",
	},
	ALL: {
		defaultMessage: "All",
	},
});
