import classNames from "classnames";
import { Pagination } from "flowbite-react";
import { useRouter } from "next/router";
import queryString from "query-string";

import { getStringifiedList, useDebounce } from "@libs";
import { CategoryType, FetchAdvisersRequestParams, useAdvisersQuery } from "@api";

import CategoriesServiceSingleton from "services/categories/CategoriesService";

import { TalentsFilters } from "../TalentsExplorerFilters/TalentsExplorerFilters";
import { AdviserCard } from "../../AdviserCard/AdviserCard";
import { AdviserCardLoading } from "../../AdviserCard/AdviserCardLoading";

export interface AdvisersExplorerListProps {
	filters: TalentsFilters;
}

export const AdvisersExplorerList = ({
	filters: {
		talentType,
		regionsIds,
		sectorsIds,
		languagesIds,
		schoolsIds,
		countriesIds,
		averageSalary,
		averageEquity,
		costPerHour,
		pageNumber,
	},
}: AdvisersExplorerListProps) => {
	const router = useRouter();
	const categoryList = CategoriesServiceSingleton.getList();
	const talentTypeCategoryList = categoryList.filterByCategoryType(CategoryType.TALENT_TYPE, true);
	const selectedTalentType = talentTypeCategoryList.find((talentTypeCategory) => talentTypeCategory.key === talentType);
	const debouncedQueryParams = useDebounce(
		{
			page: pageNumber ? pageNumber : 1,
			perPage: 20,
			"search[type]": selectedTalentType?.id,
			"search[regions]": regionsIds,
			"search[languages]": languagesIds,
			averageEquity: {
				"search[min_averageEquity]": averageEquity.min,
				"search[max_averageEquity]": averageEquity.max,
			},
			costPerHour: {
				"search[min_costPerHour]": costPerHour.min,
				"search[max_costPerHour]": costPerHour.max,
			},
		} as FetchAdvisersRequestParams,
		1000,
	);
	const { data: advisers, isLoading } = useAdvisersQuery(debouncedQueryParams, {
		enabled: Boolean(debouncedQueryParams),
	});

	const handleOnPageChange = async (newPageNumber: number) => {
		await router.push(
			router.pathname +
				`?page=${newPageNumber}&${queryString.stringify(
					{
						talentType,
						sectorsIds: getStringifiedList(sectorsIds),
						regionsIds: getStringifiedList(regionsIds),
						languagesIds: getStringifiedList(languagesIds),
						schoolsIds: getStringifiedList(schoolsIds),
						countriesIds: getStringifiedList(countriesIds),
						minAverageSalary: averageSalary.min,
						maxAverageSalary: averageSalary.max,
						minAverageEquity: averageEquity.min,
						maxAverageEquity: averageEquity.max,
						minCostPerHour: costPerHour.min,
						maxCostPerHour: costPerHour.max,
					},
					{
						skipEmptyString: true,
						skipNull: true,
					},
				)}`,
		);
	};

	const getTotalPages = () => (advisers?.num_pages ? advisers?.num_pages : 0);

	return (
		<>
			<ul
				className={classNames(
					"mt-16 w-full text-center",
					"grid gap-4 gap-y-24",
					"grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4",
				)}
			>
				{isLoading
					? [1, 2, 3, 4, 5, 6, 7, 8].map((index) => <AdviserCardLoading key={index} />)
					: advisers?.items?.map((adviser) => (
							<li
								key={adviser.id}
								className="h-full w-full flex-1"
								data-testid={`ADVISER_${adviser.id}`}
							>
								<AdviserCard {...adviser} />
							</li>
					  ))}
			</ul>
			{getTotalPages() > 1 && (
				<div className="mt-24 w-full text-center">
					<Pagination
						currentPage={pageNumber}
						layout={"pagination"}
						totalPages={getTotalPages()}
						onPageChange={handleOnPageChange}
					/>
				</div>
			)}
		</>
	);
};
