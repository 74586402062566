import { defineMessages } from "react-intl";

export default defineMessages({
	sectors: {
		defaultMessage: "Sectors",
	},
	regions: {
		defaultMessage: "Regions",
	},
});
