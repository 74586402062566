import { FormattedMessage } from "react-intl";

import { useTenderTalentsQuery } from "@api";
import { TenderTalentsList, Briefcase } from "@common";

import ChallengeTenderModel from "models/challenge-tenders/ChallengeTender";
import TalentModel from "models/talents/Talent";

import { TenderExtendedContentLoading } from "./TenderExtendedContentLoading";
import messages from "./messages";

export const TenderExtendedContent = ({ tender }: { tender: ChallengeTenderModel }) => {
	const { data: tenderTalents, isLoading } = useTenderTalentsQuery(tender.id);

	const tenderTalentsModels = tenderTalents?.map((tenderTalent) => TalentModel.generateFromAPI(tenderTalent)) || [];

	return (
		<div className="mb-4 px-4">
			{isLoading ? (
				<TenderExtendedContentLoading />
			) : (
				<div className="w-full space-y-4 rounded border border-gray-200 p-4 shadow">
					<p className="text-md flex items-center gap-2 font-bold text-neutral-600">
						<Briefcase className="h-6 w-6" />
						<FormattedMessage {...messages.talentsOffers} />
					</p>
					{tenderTalentsModels.length > 0 ? (
						<TenderTalentsList talents={tenderTalentsModels} />
					) : (
						<p className="w-full text-center text-neutral-400">
							<FormattedMessage {...messages.talentsNotUploadedYet} />
						</p>
					)}
				</div>
			)}
		</div>
	);
};
