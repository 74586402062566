import CategorySelectableModel from "models/categories/CategorySelectable";

export enum LabelAdviserType {
	BUSINESS = "BUSINESS",
	SALES_AND_MARKETING = "SALES_AND_MARKETING",
	INVESTMENT = "INVESTMENT",
	DESIGN_AND_PRODUCT = "DESIGN_AND_PRODUCT",
	IT = "IT",
	LEGAL = "LEGAL",
	ACCOUNTANT = "ACCOUNTANT",
	ENGINEERING_AND_ARCHITECTURE = "ENGINEERING_AND_ARCHITECTURE",
	MEDICINE_AND_PSYCHOLOGY = "MEDICINE_AND_PSYCHOLOGY",
}

interface LabelAdviserState {
	business: CategorySelectableModel[];
	salesAndMarketing: CategorySelectableModel[];
	investment: CategorySelectableModel[];
	designAndProduct: CategorySelectableModel[];
	it: CategorySelectableModel[];
	legal: CategorySelectableModel[];
	accountant: CategorySelectableModel[];
	engineeringAndArchitecture: CategorySelectableModel[];
	medicineAndPsychology: CategorySelectableModel[];
}

export const initialState: LabelAdviserState = {
	business: [],
	salesAndMarketing: [],
	investment: [],
	designAndProduct: [],
	it: [],
	legal: [],
	accountant: [],
	engineeringAndArchitecture: [],
	medicineAndPsychology: [],
};

export const labelReducer = (
	state: LabelAdviserState,
	action: { type: LabelAdviserType; payload: CategorySelectableModel[] },
) => {
	switch (action.type) {
		case LabelAdviserType.BUSINESS:
			state = { ...state, business: action.payload };
			break;
		case LabelAdviserType.SALES_AND_MARKETING:
			state = { ...state, salesAndMarketing: action.payload };
			break;
		case LabelAdviserType.ACCOUNTANT:
			state = { ...state, accountant: action.payload };
			break;
		case LabelAdviserType.DESIGN_AND_PRODUCT:
			state = { ...state, designAndProduct: action.payload };
			break;
		case LabelAdviserType.ENGINEERING_AND_ARCHITECTURE:
			state = { ...state, engineeringAndArchitecture: action.payload };
			break;
		case LabelAdviserType.MEDICINE_AND_PSYCHOLOGY:
			state = { ...state, medicineAndPsychology: action.payload };
			break;
		case LabelAdviserType.INVESTMENT:
			state = { ...state, investment: action.payload };
			break;
		case LabelAdviserType.IT:
			state = { ...state, it: action.payload };
			break;
		case LabelAdviserType.LEGAL:
			state = { ...state, legal: action.payload };
			break;
	}

	return state;
};
