import { useContext, useState } from "react";
import { useIntl } from "react-intl";
import Image from "next/image";

import { useNotifications } from "@libs";
import { useCreateUserProfileMutation } from "@api";
import { BrowserUtils, DateUtils } from "@utils";
import { UserProfileContext } from "@context";

import CategorySelectableModel from "models/categories/CategorySelectable";
import { FeatureUtils } from "utils/FeatureUtils";

import { commonOnboardingMessages } from "../../BusinessProfiles/Onboarding/OnboardingModal/messages";

import { ProfileInfoForm } from "./Forms/InitialProfileForm/PersonalInfoForm";
import { useOnboardingReducer } from "./onboarding-reducer";
import { PersonalSkills } from "./Forms/PersonalSkills/PersonalSkills";
import { TechnicalSkills } from "./Forms/TechnicalSkills/TechnicalSkills";
import { Onboarding } from "./Onboarding";
import messages from "./messages";

const OnboardingProfile = () => {
	const { formatMessage } = useIntl();
	const { user } = useContext(UserProfileContext);
	const { addErrorNotification } = useNotifications();
	const [onboardingState, setOnboardingState] = useState<{
		currentIndexActiveStep: number;
	}>({
		currentIndexActiveStep: 0,
	});
	const { infoState, changeGender, changeBirthdate, changeCountry } = useOnboardingReducer();
	const { personalSkills: personalSkillsCategories, ...technicalSkillsCategories } = FeatureUtils.getTranslatedSkills();

	const [personalSkills, setPersonalSkills] = useState(personalSkillsCategories);
	const [technicalSkills, setTechnicalSkills] = useState<{
		[key: string]: CategorySelectableModel[];
	}>(technicalSkillsCategories);
	const { mutateAsync, isLoading } = useCreateUserProfileMutation();

	const handleOnboardingOnPressContinue = () =>
		setOnboardingState({
			...onboardingState,
			currentIndexActiveStep: onboardingState.currentIndexActiveStep + 1,
		});
	const handleOnboardingOnPressPrevious = () =>
		setOnboardingState({
			...onboardingState,
			currentIndexActiveStep: onboardingState.currentIndexActiveStep - 1,
		});

	const saveProfile = () => {
		const technicalSkillsInOneList = Object.values(technicalSkills).reduce(
			(acc: CategorySelectableModel[], nextValue: CategorySelectableModel[]) => [...acc, ...nextValue],
			[],
		);
		const selectedTechnicalSkills = technicalSkillsInOneList.filter((skill) => skill.selected).map((skill) => skill.id);
		const selectedPersonalSkillsIds = personalSkills.filter((skill) => skill.selected).map((skill) => skill.id);
		const initialSlug = `${user?.first_name.toLowerCase()}-${user?.last_name.toLowerCase()}-${user?.id}`;
		const slugWithJustLettersAndNumbers = initialSlug
			.replace(" ", "-")
			.replace(/[^A-Za-z0-9\-]+/g, "")
			.replace(/(-)\1{1,}/g, "-") // Replace multiple group of "-"
			.trim();

		const profile = {
			emblem: "",
			name: "",
			first_name: "",
			last_name: "",
			skills: [...selectedPersonalSkillsIds, ...selectedTechnicalSkills] as number[],
			educations: [],
			languages: [],
			areas: [],
			jobs: [],
			schools: [],
			gender: String(infoState.gender),
			birthday: {
				datetime: DateUtils.getTimeAPIFormat(infoState.birthdate.getTime()),
				datetimezone: BrowserUtils.isCypress() ? "Europe/Madrid" : DateUtils.getCurrentTimezone(),
			},
			country: Number(infoState.country),
			slug: slugWithJustLettersAndNumbers,
			description: "",
			social_networks: [],
			user: {
				name: "",
				first_name: "",
				last_name: "",
			},
		};

		mutateAsync(profile)
			.then(() => {
				window.location.href = `/profile/${slugWithJustLettersAndNumbers}`;
			})
			.catch(() => addErrorNotification(formatMessage(commonOnboardingMessages.somethingWentWrong)));
	};

	const stepsNames: string[] = [
		formatMessage(messages.tabPersonalInfo),
		formatMessage(messages.tabPersonalSkills),
		formatMessage(messages.tabTechnicalSkills),
	];

	return (
		<section data-testid="PROFILE_ONBOARDING">
			<div className="absolute -bottom-10 -left-32 -z-10 aspect-video w-full max-w-4xl opacity-40">
				<Image
					alt="Rock it with Vinglet"
					src="/illustrations/324.svg"
					layout="fill"
				/>
			</div>
			<Onboarding
				stepNames={stepsNames}
				currentIndexActiveStep={onboardingState.currentIndexActiveStep}
				continueButton={{
					onPress: handleOnboardingOnPressContinue,
				}}
				previousButton={{
					onPress: handleOnboardingOnPressPrevious,
					disabled: isLoading,
				}}
				finishButton={{
					onPress: saveProfile,
					disabled: isLoading,
					loading: isLoading,
				}}
			>
				<div className="mx-auto w-full max-w-2xl">
					<ProfileInfoForm
						info={infoState}
						errors={infoState.errors}
						onChangeGender={changeGender}
						onChangeBirthdate={changeBirthdate}
						onChangeCountry={changeCountry}
					/>
				</div>
				<div className="mx-auto w-full max-w-4xl">
					<PersonalSkills
						personalSkills={personalSkills}
						onChangePersonalSkills={setPersonalSkills}
					/>
				</div>
				<div>
					<TechnicalSkills
						technicalSkills={technicalSkills}
						onChangeTechnicalSkills={setTechnicalSkills}
					/>
				</div>
			</Onboarding>
		</section>
	);
};

export default OnboardingProfile;
