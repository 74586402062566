import { FormattedMessage, useIntl } from "react-intl";
import * as React from "react";
import { ChangeEventHandler } from "react";

import { InputCheckboxField, InputField, Select } from "@common";
import { URLUtils } from "@utils";

import { OnboardingInfoReducer } from "../../onboarding-reducer";
import CategoriesServiceSingleton from "services/categories/CategoriesService";
import { CategoryType } from "api/CategoriesAPI/urls";

import { Slug } from "./Slug";
import messages from "./messages";

export const MAX_LENGTH_BUSINESS_PROFILE_NAME = 50;

interface InitialProfileForm {
	info: OnboardingInfoReducer;
	errors: Record<string, string | undefined>;
	onChangeFullName?: (fullName: string) => void;
	onChangeSlug?: (slug: string) => void;
	onChangeUrl?: (url: string) => void;
	onChangeEmail?: (email: string) => void;
	onChangeCountry?: (country: string) => void;
	onChangeTermsAndConditions?: (status: boolean) => void;
	linkToTermsAndConditions?: string;
}

export const InitialProfileForm = ({
	info,
	errors,
	onChangeFullName,
	onChangeCountry,
	onChangeSlug,
	onChangeUrl,
	onChangeEmail,
	onChangeTermsAndConditions,
	linkToTermsAndConditions,
}: InitialProfileForm) => {
	const { formatMessage } = useIntl();

	const categoryList = CategoriesServiceSingleton.getList();
	const countries = categoryList.filterByCategoryType(CategoryType.COUNTRY, true);

	const handleOnChangeFullName: ChangeEventHandler<HTMLInputElement> = ({ target }) =>
		onChangeFullName && onChangeFullName(target.value);
	const handleOnChangeCountry: ChangeEventHandler<HTMLSelectElement> = ({ target }) =>
		onChangeCountry && onChangeCountry(target.value);
	const handleOnChangeURL: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
		const sanitizedURL = URLUtils.sanitize(target.value);

		if (onChangeUrl) {
			onChangeUrl(sanitizedURL);
		}
	};
	const handleOnChangeEmail: ChangeEventHandler<HTMLInputElement> = ({ target }) =>
		onChangeEmail && onChangeEmail(target.value);
	const handleOnChangeSlug = (slug: string) => onChangeSlug && onChangeSlug(slug);
	const handleOnChangeTermsAndConditions: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
		onChangeTermsAndConditions && onChangeTermsAndConditions(target.checked);
	};

	return (
		<div className="mb-8 flex flex-col gap-4 px-20">
			<div className="w-full">
				<InputField
					type="text"
					name="fullName"
					id="fullName"
					sizing="md"
					required
					maxLength={MAX_LENGTH_BUSINESS_PROFILE_NAME}
					placeholder={formatMessage(messages.fullName)}
					labelValue={formatMessage(messages.fullName) + " *"}
					value={info.fullName}
					color={errors["fullName"] ? "failure" : "gray"}
					errorMessage={errors["fullName"]}
					onChange={handleOnChangeFullName}
				/>
			</div>
			<div>
				<InputField
					type="email"
					name="email"
					id="email"
					sizing="md"
					placeholder="name@something.com"
					required
					labelValue={"Email *"}
					value={info.email}
					color={errors["email"] ? "failure" : "gray"}
					errorMessage={errors["email"]}
					autoComplete="email"
					onChange={handleOnChangeEmail}
				/>
			</div>
			<div className="w-full">
				<Select
					name="country"
					id="country"
					required
					sizing="md"
					placeholder={formatMessage(messages.country)}
					labelValue={formatMessage(messages.country)}
					value={info.country || "none"}
					color={errors["country"] ? "failure" : "gray"}
					onChange={handleOnChangeCountry}
				>
					<option
						key="none"
						value="none"
						disabled
					>
						<FormattedMessage {...messages.selectACountry} />
					</option>
					{countries.map((country) => (
						<option
							key={country.id}
							value={Number(country.id)}
						>
							{country.text}
						</option>
					))}
				</Select>
			</div>
			<div>
				<Slug
					info={info}
					errors={errors}
					onChangeSlug={handleOnChangeSlug}
				/>
			</div>
			<div className="w-full">
				<InputField
					type="text"
					name="url"
					id="url"
					sizing="md"
					placeholder="www.my-website.com"
					labelValue={formatMessage(messages.websiteUrl)}
					value={URLUtils.removeHttpsProtocol(info.url)}
					addon={"https://"}
					color={errors["url"] ? "failure" : "gray"}
					errorMessage={errors["url"]}
					onChange={handleOnChangeURL}
				/>
			</div>
			<div className="flex items-start">
				<InputCheckboxField
					id="terms"
					name="terms"
					aria-describedby="terms"
					onChange={handleOnChangeTermsAndConditions}
					labelValue={
						linkToTermsAndConditions ? (
							<FormattedMessage
								{...messages.acceptTermsAndConditions}
								values={{
									terms: (
										<a
											className="text-primary-600 dark:text-primary-500 font-medium hover:underline"
											rel="noreferrer"
											target="_blank"
											href={linkToTermsAndConditions}
										>
											<FormattedMessage {...messages.termsAndConditions} />
										</a>
									),
								}}
							/>
						) : (
							<FormattedMessage {...messages.vingletConsent} />
						)
					}
				/>
			</div>
		</div>
	);
};
