import * as React from "react";
import classNames from "classnames";

type OnboardingHeaderProps = {
	stepsNames: string[];
	currentIndexActiveStep: number;
};

export const OnboardingHeader = ({ stepsNames, currentIndexActiveStep }: OnboardingHeaderProps) => {
	const getIconStep = (indexItem: number) => {
		return (
			<div
				className={classNames(
					"z-10 flex shrink-0 items-center justify-center",
					"h-6 w-6",
					currentIndexActiveStep > indexItem ? "bg-green-200 dark:bg-green-900" : "bg-blue-200 dark:bg-blue-900",
					"rounded-full",
					currentIndexActiveStep > indexItem
						? "ring-8 ring-green-200 dark:ring-gray-900"
						: "ring-8 ring-white dark:ring-gray-900",
				)}
			>
				{currentIndexActiveStep > indexItem && (
					<svg
						className="h-6 w-6 text-green-800 dark:text-green-300"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
						/>
					</svg>
				)}
			</div>
		);
	};

	return (
		<>
			<ol className="hidden w-full items-center sm:flex">
				{stepsNames.map((stepName, indexItem) => (
					<li
						key={stepName}
						className="relative mb-4 flex-1"
					>
						<div className="flex items-center">
							<div className="flex h-0.5 w-full bg-gray-200 dark:bg-gray-700" />
							{getIconStep(indexItem)}
							<div className="flex h-0.5 w-full bg-gray-200 dark:bg-gray-700" />
						</div>
						<div className="mt-3 text-center">
							<span className="text-lg font-semibold text-gray-900 dark:text-white">{stepName}</span>
						</div>
					</li>
				))}
			</ol>
		</>
	);
};
