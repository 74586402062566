import * as React from "react";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import {useLocales} from "@libs";

import {NewTalentTexts} from "i18n/containers/NewTalent";
import {NewTalentTranslation} from "i18n/containers/NewTalent/typing";
import {ChallengesTexts} from "i18n/components/Challenges/index";
import {ChallengesTranslation} from "i18n/components/Challenges/typing";

import InternalFormTextarea from "elements/InternalFormTextarea/InternalFormTextarea";

const UnitChallengeTenderFieldDescription = (props: {
    description: string;
    onChange: (newDescription: React.ReactText) => void;
}) => {
    const {description, onChange} = props;
    const {currentShortLocale} = useLocales();
    const challengesTranslations: ChallengesTranslation = {
        ...ChallengesTexts[currentShortLocale],
    };
    const newTalentTranslation: NewTalentTranslation = {
        ...NewTalentTexts[currentShortLocale],
    };
    const onChangeDescription = (newDescription: string) => {
        onChange(newDescription);
    };

    return (
        <Form.Field>
            <h3 className="mb-2 font-color-grey">{newTalentTranslation.description}</h3>
            <p className="mb-4">
                {challengesTranslations.challengesDescriptionIntro}
            </p>
            <InternalFormTextarea
                placeholder={challengesTranslations.challengesDescription}
                isEditable
                value={description}
                onChange={onChangeDescription}
            />
        </Form.Field>
    );
};

export default React.memo(UnitChallengeTenderFieldDescription);
