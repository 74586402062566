import { useState } from "react";
import { useRouter } from "next/router";

import { MarketplaceOffer, MarketplaceType } from "@api";
import { getListFromStringifiedList } from "@libs";

import CategoryModel from "models/categories/Category";

import * as Filters from "./Filters";

type CategoriesCounter = number;
type SectorsIdsClicked = Record<number, boolean>;
type RegionsIdsClicked = Record<number, boolean>;

export type MarketplacesFilters = {
	marketplaceType: MarketplaceType | undefined;
	marketplaceOffer: MarketplaceOffer | undefined;
	quantity: {
		min: number | undefined;
		max: number | undefined;
	};
	price: {
		min: number | undefined;
		max: number | undefined;
	};
	discount: {
		min: number | undefined;
	};
	sectorsIds: number[];
	regionsIds: number[];
	pageNumber: number;
};

export interface MarketplacesExplorerFiltersProps {
	topSectors: [CategoryModel, CategoriesCounter][];
	topRegions: [CategoryModel, CategoriesCounter][];
	onChangeFilters: (filters: Omit<MarketplacesFilters, "pageNumber">) => void;
	filtersIdsPrefix: {
		marketplaceType: string;
		sectors: string;
		regions: string;
		quantity: string;
		price: string;
		discount: string;
	};
}

export const MarketplacesExplorerFilters = ({
	topSectors,
	topRegions,
	onChangeFilters,
	filtersIdsPrefix,
}: MarketplacesExplorerFiltersProps) => {
	const router = useRouter();
	const {
		regionsIds,
		sectorsIds,
		marketplaceType,
		marketplaceOffer,
		minQuantity,
		maxQuantity,
		minPrice,
		maxPrice,
		minDiscount,
	} = router.query;
	const initCategoriesIdsClickedFromRouterQuery = (categoriesIds: string[]): Record<number, boolean> => {
		return categoriesIds.reduce((acc, categoryId) => {
			return {
				...acc,
				[Number(categoryId)]: true,
			};
		}, {});
	};

	const [selectedMarketplaceType, setSelectedMarketplaceType] = useState<MarketplaceType | undefined>(
		marketplaceType as MarketplaceType | undefined,
	);
	const [selectedMarketplaceOffer, setSelectedMarketplaceOffer] = useState<MarketplaceOffer | undefined>(
		marketplaceOffer as MarketplaceOffer | undefined,
	);
	const [sectorsIdsClicked, setSectorsIdsClicked] = useState<SectorsIdsClicked>(
		initCategoriesIdsClickedFromRouterQuery(getListFromStringifiedList(sectorsIds as string)),
	);
	const [regionsIdsClicked, setRegionsIdsClicked] = useState<RegionsIdsClicked>(
		initCategoriesIdsClickedFromRouterQuery(getListFromStringifiedList(regionsIds as string)),
	);
	const [quantity, setQuantity] = useState<{
		min: number | undefined;
		max: number | undefined;
	}>({
		min: Number.isInteger(minQuantity) ? Number(minQuantity) : undefined,
		max: Number.isInteger(maxQuantity) ? Number(maxQuantity) : undefined,
	});
	const [price, setPrice] = useState<{
		min: number | undefined;
		max: number | undefined;
	}>({
		min: Number.isInteger(minPrice) ? Number(minPrice) : undefined,
		max: Number.isInteger(maxPrice) ? Number(maxPrice) : undefined,
	});
	const [discount, setDiscount] = useState<{
		min: number | undefined;
	}>({
		min: Number.isInteger(minDiscount) ? Number(minDiscount) : undefined,
	});

	const handleOnChangeFilters = (
		marketplaceType: MarketplaceType | undefined,
		marketplaceOffer: MarketplaceOffer | undefined,
		sectorsIdsClicked: SectorsIdsClicked,
		regionsIdsClicked: RegionsIdsClicked,
		quantity: {
			min: number | undefined;
			max: number | undefined;
		},
		price: {
			min: number | undefined;
			max: number | undefined;
		},
		discount: {
			min: number | undefined;
		},
	) => {
		const sectorsIds = Object.keys(sectorsIdsClicked)
			.filter((sectorId) => sectorsIdsClicked[Number(sectorId)])
			.map((sectorId) => Number(sectorId));
		const regionsIds = Object.keys(regionsIdsClicked)
			.filter((regionId) => regionsIdsClicked[Number(regionId)])
			.map((regionId) => Number(regionId));

		onChangeFilters({
			marketplaceType,
			marketplaceOffer,
			sectorsIds,
			regionsIds,
			quantity,
			price,
			discount,
		});
	};

	const handleOnChangeType = (marketplaceType?: MarketplaceType) => {
		setSelectedMarketplaceType(marketplaceType);
		handleOnChangeFilters(
			marketplaceType,
			selectedMarketplaceOffer,
			sectorsIdsClicked,
			regionsIdsClicked,
			quantity,
			price,
			discount,
		);
	};
	const handleOnChangeOffer = (marketplaceOffer?: MarketplaceOffer) => {
		setSelectedMarketplaceOffer(marketplaceOffer);
		handleOnChangeFilters(
			selectedMarketplaceType,
			marketplaceOffer,
			sectorsIdsClicked,
			regionsIdsClicked,
			quantity,
			price,
			discount,
		);
	};
	const handleOnCheckedRegion = (categoryId: number) => {
		const newRegionsIdsClicked: RegionsIdsClicked = {
			...regionsIdsClicked,
			[categoryId]: !regionsIdsClicked[categoryId],
		};

		setRegionsIdsClicked(newRegionsIdsClicked);
		handleOnChangeFilters(
			selectedMarketplaceType,
			selectedMarketplaceOffer,
			sectorsIdsClicked,
			newRegionsIdsClicked,
			quantity,
			price,
			discount,
		);
	};
	const handleOnCheckedSector = (categoryId: number) => {
		const newSectorsIdsClicked: SectorsIdsClicked = {
			...sectorsIdsClicked,
			[categoryId]: !sectorsIdsClicked[categoryId],
		};

		setSectorsIdsClicked(newSectorsIdsClicked);
		handleOnChangeFilters(
			selectedMarketplaceType,
			selectedMarketplaceOffer,
			newSectorsIdsClicked,
			regionsIdsClicked,
			quantity,
			price,
			discount,
		);
	};
	const handleOnChangeQuantity = (min?: number, max?: number) => {
		const newQuantity = {
			min,
			max,
		};
		setQuantity(newQuantity);
		handleOnChangeFilters(
			selectedMarketplaceType,
			selectedMarketplaceOffer,
			sectorsIdsClicked,
			regionsIdsClicked,
			newQuantity,
			price,
			discount,
		);
	};
	const handleOnChangePrice = (min?: number, max?: number) => {
		const newPrice = {
			min,
			max,
		};
		setPrice(newPrice);
		handleOnChangeFilters(
			selectedMarketplaceType,
			selectedMarketplaceOffer,
			sectorsIdsClicked,
			regionsIdsClicked,
			quantity,
			newPrice,
			discount,
		);
	};
	const handleOnChangeDiscount = (minDiscount: number | undefined) => {
		const newDiscount = {
			min: minDiscount,
		};
		setDiscount(newDiscount);
		handleOnChangeFilters(
			selectedMarketplaceType,
			selectedMarketplaceOffer,
			sectorsIdsClicked,
			regionsIdsClicked,
			quantity,
			price,
			newDiscount,
		);
	};

	return (
		<>
			<Filters.Type
				filterIdsPrefix={filtersIdsPrefix.marketplaceType}
				onChangeType={handleOnChangeType}
				currentSelectedMarketplaceType={selectedMarketplaceType}
			/>
			<Filters.Offer
				filterIdsPrefix={filtersIdsPrefix.marketplaceType}
				onChangeOffer={handleOnChangeOffer}
				currentSelectedMarketplaceOffer={selectedMarketplaceOffer}
			/>
			<Filters.Regions
				regions={topRegions}
				regionsIdsClicked={regionsIdsClicked}
				onChangeOneRegion={handleOnCheckedRegion}
				filterIdsPrefix={filtersIdsPrefix.regions}
			/>
			<Filters.Sectors
				sectors={topSectors}
				sectorsIdsClicked={sectorsIdsClicked}
				onChangeOneSector={handleOnCheckedSector}
				filterIdsPrefix={filtersIdsPrefix.sectors}
			/>
			<Filters.Quantity
				filterIdsPrefix={filtersIdsPrefix.quantity}
				currentSelectedMarketplaceQuantity={{
					min: quantity.min,
					max: quantity.max,
				}}
				onChangeQuantity={handleOnChangeQuantity}
			/>
			{selectedMarketplaceOffer === "SELL" && (
				<>
					<Filters.Price
						filterIdsPrefix={filtersIdsPrefix.price}
						currentSelectedMarketplacePrice={{
							min: price.min,
							max: price.max,
						}}
						onChangePrice={handleOnChangePrice}
					/>
					<Filters.Discount
						filterIdsPrefix={filtersIdsPrefix.discount}
						currentSelectedMarketplaceDiscount={discount.min}
						onChangeDiscount={handleOnChangeDiscount}
					/>
				</>
			)}
		</>
	);
};
