import { SocialItemProps } from "constants/URLs";

import { SocialProfileModalTexts } from "i18n/containers/Modals/SocialProfileModal";
import { SocialProfileModalTranslation } from "i18n/containers/Modals/SocialProfileModal/typing";
import * as React from "react";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import { InputOnChangeData } from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import { isValidUrl } from "@utils";
import {useLocales} from "@libs";

const getSanitizedValue = (value: string, item: SocialItemProps) => {
	let sanitizedValue: string;
	sanitizedValue = value.replace(item.longRoot, "");

	return sanitizedValue;
};

const UnitChallengeTenderFieldSocial = (props: {
	item: SocialItemProps;
	currentValue: string;
	actionColor: string;
	actionIcon: string;
	onChange: (newValue: string) => void;
}) => {
	const { item, currentValue, onChange, actionColor, actionIcon } = props;
	const { currentShortLocale } = useLocales();
	const socialTranslations: SocialProfileModalTranslation = {
		...SocialProfileModalTexts[currentShortLocale],
	};
	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
		const sanitizedValue = getSanitizedValue(data.value, item);
		const fullURL = item.profileRoot + sanitizedValue;

		if (isValidUrl(fullURL)) {
			onChange(sanitizedValue);
		}
	};

	return (
		<Input
			action={{
				color: actionColor,
				labelPosition: "left",
				icon: actionIcon,
				content: "Link:",
			}}
			actionPosition="left"
			label={item.profileRoot}
			className="margin-1-all"
			fluid
			value={currentValue}
			onChange={handleOnChange}
			placeholder={socialTranslations.customLink}
		/>
	);
};

export default React.memo(UnitChallengeTenderFieldSocial);
