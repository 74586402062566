import { useState } from "react";
import classNames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import { useRouter } from "next/router";
import Image from "next/image";

import { InvestorTypes } from "@api";
import { getListFromStringifiedList } from "@libs";

import CategoryModel from "models/categories/Category";

import { PostulateExplorerFilters, PostulateFilters } from "./PostulateExplorerFilters/PostulateExplorerFilters";
import { PostulateExplorerList } from "./PostulateExplorerList/PostulateExplorerList";
import { PostulateExplorerFiltersModal } from "./PostulateExplorerFiltersModal/PostulateExplorerFiltersModal";

import messages from "./messages";

type CategoriesCounter = number;

export interface PostulatesExplorerProps {
	topSectors: [CategoryModel, CategoriesCounter][];
	topRegions: [CategoryModel, CategoriesCounter][];
	topLanguages: [CategoryModel, CategoriesCounter][];
	initialPageNumber: number;
}

export const PostulateExplorer = ({
	topRegions,
	topSectors,
	topLanguages,
	initialPageNumber,
}: PostulatesExplorerProps) => {
	const router = useRouter();
	const { formatMessage } = useIntl();
	const {
		investorType,
		sectorsIds,
		regionsIds,
		minAverageInvestment,
		maxAverageInvestment,
		minAverageEquity,
		maxAverageEquity,
		minNumberOfStartupsToInvest,
		maxNumberOfStartupsToInvest,
		languagesIds,
	} = router.query;
	const [filters, setFilters] = useState<PostulateFilters>({
		investorType: investorType as InvestorTypes | undefined,
		sectorsIds: getListFromStringifiedList(sectorsIds as string),
		regionsIds: getListFromStringifiedList(regionsIds as string),
		averageInvestment: {
			min: Number.isInteger(minAverageInvestment) ? Number(minAverageInvestment) : undefined,
			max: Number.isInteger(maxAverageInvestment) ? Number(maxAverageInvestment) : undefined,
			__TYPE__: "AverageInvestment",
		},
		averageEquity: {
			min: Number.isInteger(minAverageEquity) ? Number(minAverageEquity) : undefined,
			max: Number.isInteger(maxAverageEquity) ? Number(maxAverageEquity) : undefined,
			__TYPE__: "AverageEquity",
		},
		numberOfStartupsToInvest: {
			min: Number.isInteger(minNumberOfStartupsToInvest) ? Number(minNumberOfStartupsToInvest) : undefined,
			max: Number.isInteger(maxNumberOfStartupsToInvest) ? Number(maxNumberOfStartupsToInvest) : undefined,
			__TYPE__: "NumberOfStartupsToInvest",
		},
		languagesIds: getListFromStringifiedList(languagesIds as string),
		pageNumber: initialPageNumber,
	});

	const onChangeFilters = (newFilters: Omit<PostulateFilters, "pageNumber">) => {
		setFilters(() => ({
			...newFilters,
			pageNumber: 0,
		}));
	};

	return (
		<>
			<div className="z-index-99 fixed bottom-0 w-full border-t border-gray-300 bg-white py-8 px-4 lg:hidden">
				<PostulateExplorerFiltersModal
					topSectors={topSectors}
					topRegions={topRegions}
					topLanguages={topLanguages}
					onChangeFilters={onChangeFilters}
				/>
			</div>
			<main className="min-h-full w-full pb-36">
				<div
					className={classNames("w-full py-16 text-center lg:py-28 lg:text-left", "px-4 lg:px-10 xl:px-20 2xl:px-44")}
				>
					<div className="flex items-center justify-around">
						<div>
							<h1 className="text-5xl font-extrabold text-gray-900">
								<FormattedMessage {...messages.title} />
							</h1>
							<p className="mt-4 mb-8 text-lg text-gray-600">
								<FormattedMessage {...messages.subTitle} />
							</p>
						</div>
						<div className="hidden lg:block">
							<div className="relative mx-auto w-96">
								<Image
									className="object-cover"
									alt={formatMessage(messages.title)}
									src="/postulate/person-doing-business.svg"
									layout="responsive"
									width="100%"
									height="100%"
								/>
							</div>
						</div>
					</div>
					<section className="mt-10 py-6 lg:py-20">
						<div className="flex items-stretch justify-between">
							<aside className="hidden h-full w-80 shrink-0 lg:block">
								<PostulateExplorerFilters
									topSectors={topSectors}
									topRegions={topRegions}
									topLanguages={topLanguages}
									onChangeFilters={onChangeFilters}
									filtersIdsPrefix={{
										investorType: "investorType",
										sectors: "sectors",
										regions: "regions",
										averageInvestment: "averageInvestment",
										averageEquity: "averageEquity",
										numberOfStartupsToInvest: "numberOfStartupsToInvest",
										languages: "languages",
									}}
								/>
							</aside>
							<section className="flex-1 pl-0 lg:pl-20">
								<PostulateExplorerList filters={filters} />
							</section>
						</div>
					</section>
				</div>
			</main>
		</>
	);
};
