import * as React from "react";
import CategorySelectableModel from "models/categories/CategorySelectable";

import { LabelListProfile } from "@common";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

interface PersonalSkillsProps {
	personalSkills: CategorySelectableModel[];
	onChangePersonalSkills: (newPersonalSkills: CategorySelectableModel[]) => void;
}

export const PersonalSkills = ({ personalSkills, onChangePersonalSkills }: PersonalSkillsProps) => {
	return (
		<div className="mb-8 flex flex-col gap-4 px-10 md:px-20">
			<h2 className="m-0 pt-12 text-4xl">
				<FormattedMessage {...messages.personalSkills} />
			</h2>
			<h3 className="font-regular mt-0 text-neutral-500">
				<FormattedMessage {...messages.selectSkills} />
			</h3>
			<div className="w-full">
				<LabelListProfile
					gridClassName="flex flex-wrap justify-center"
					labelsTextClassName="text-lg md:text-xl"
					basic
					showAll
					editable
					color="orange"
					items={personalSkills}
					onUpdate={onChangePersonalSkills}
				/>
			</div>
		</div>
	);
};
