import { defineMessages } from "react-intl";

export default defineMessages({
	diffInMonths: {
		defaultMessage: "{months, plural, one {# month} other {# months}}",
	},
	diffInDays: {
		defaultMessage: "{days, plural, one {# day} other {# days}}",
	},
	diffInHours: {
		defaultMessage: "{hours, plural, one {# hour} other {# hours}}",
	},
	startOn: {
		defaultMessage: "Start on {date}",
	},
	duration: {
		defaultMessage: "Duration: {duration}",
	},
});
