import * as React from "react";

import { ChallengeSituation, useBusinessProfileQuery, useChallengeInvitationsQuery, useChallengeQuery } from "@api";

import ChallengeTenderModel from "models/challenge-tenders/ChallengeTender";
import BusinessProfileModel from "models/business-profiles/BusinessProfile";

import { ProfileInvolved } from "./ProfileInvolved";
import { InvitedProfileInvolved } from "./InvitedProfileInvolved";
import { ProfileInvolvedLoading } from "./ProfileInvolvedLoading";

interface TenderProfilesInvolvedProps {
	tender: ChallengeTenderModel;
}

export const TenderProfilesInvolved = ({ tender }: TenderProfilesInvolvedProps) => {
	const { data: challenge } = useChallengeQuery(tender.challenge.slug, {
		enabled: false,
	});
	const { data: creatorProfile, isLoading: isCreatorProfileLoading } = useBusinessProfileQuery(
		challenge?.profile.slug || "",
		{
			enabled: !!challenge?.profile.slug,
		},
	);
	const { data: challengeInvitations } = useChallengeInvitationsQuery(tender.challenge.slug, {
		enabled: !!tender.challenge.slug,
	});
	const profileInvitation = challengeInvitations?.filter(
		(invitedProfile) => invitedProfile.situation === ChallengeSituation.ACCEPTED,
	)[0];

	const creatorProfileModel = creatorProfile && BusinessProfileModel.generateFromAPI(creatorProfile);

	if (isCreatorProfileLoading) {
		return (
			<>
				<ProfileInvolvedLoading />
				<ProfileInvolvedLoading />
			</>
		);
	}

	return (
		<>
			{creatorProfileModel && <ProfileInvolved businessProfileModel={creatorProfileModel} />}
			{profileInvitation && <InvitedProfileInvolved invitedProfileSlug={profileInvitation.destination.slug} />}
		</>
	);
};
