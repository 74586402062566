import classNames from "classnames";
import { Pagination } from "flowbite-react";
import { useRouter } from "next/router";
import queryString from "query-string";

import { getStringifiedList, useDebounce } from "@libs";
import { FetchMarketplacesRequestParams, useMarketplacesQuery } from "@api";

import MarketplaceModel from "models/marketplaces/Marketplace";

import { MarketplaceCardLoading } from "../../MarketplaceCard/MarketplaceCardLoading";
import { MarketplaceCard } from "../../MarketplaceCard/MarketplaceCard";
import { MarketplacesFilters } from "../MarketplacesExplorerFilters/MarketplacesExplorerFilters";

export interface MarketplacesExplorerListProps {
	filters: MarketplacesFilters;
}

export const MarketplacesExplorerList = ({
	filters: { marketplaceType, marketplaceOffer, regionsIds, sectorsIds, pageNumber, quantity, price, discount },
}: MarketplacesExplorerListProps) => {
	const router = useRouter();
	const debouncedQueryParams = useDebounce(
		{
			page: pageNumber ? pageNumber : 1,
			perPage: 12,
			"search[type]": marketplaceType,
			"search[offer]": marketplaceOffer,
			"search[regions]": regionsIds,
			"search[sectors]": sectorsIds,
			quantity: {
				"search[min_quantity]": quantity.min,
				"search[max_quantity]": quantity.max,
			},
			price: {
				"search[min_pvp]": price.min,
				"search[max_pvp]": price.max,
			},
			"search[discount_percentage]": discount.min,
		} as FetchMarketplacesRequestParams,
		1000,
	);
	const { data: marketplaces, isLoading } = useMarketplacesQuery(debouncedQueryParams, {
		enabled: Boolean(debouncedQueryParams),
	});

	const marketplacesModels = marketplaces?.items.map((marketplace) => MarketplaceModel.generateFromAPI(marketplace));

	const handleOnPageChange = async (newPageNumber: number) => {
		await router.push(
			router.pathname +
				`?page=${newPageNumber}&${queryString.stringify(
					{
						marketplaceType,
						marketplaceOffer,
						sectorsIds: getStringifiedList(sectorsIds),
						regionsIds: getStringifiedList(regionsIds),
						minQuantity: quantity.min,
						maxQuantity: quantity.max,
						minPrice: price.min,
						maxPrice: price.max,
						minDiscount: discount.min,
					},
					{
						skipEmptyString: true,
						skipNull: true,
					},
				)}`,
		);
	};

	const getTotalPages = () => (marketplaces?.num_pages ? marketplaces?.num_pages : 0);

	return (
		<>
			<ul
				className={classNames(
					"w-full text-center",
					"grid gap-4",
					"grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4",
				)}
			>
				{isLoading
					? [1, 2, 3, 4, 5, 6, 7, 8].map((index) => <MarketplaceCardLoading key={index} />)
					: marketplacesModels?.map((marketplace) => (
							<li
								key={marketplace.id}
								className="h-full w-full flex-1"
								data-testid={`MARKETPLACE_${marketplace.id}`}
							>
								<MarketplaceCard {...marketplace} />
							</li>
					  ))}
			</ul>
			{getTotalPages() > 1 && (
				<div className="mt-24 w-full text-center">
					<Pagination
						currentPage={pageNumber}
						layout={"pagination"}
						totalPages={getTotalPages()}
						onPageChange={handleOnPageChange}
					/>
				</div>
			)}
		</>
	);
};
