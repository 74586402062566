import { defineMessages } from "react-intl";

export default defineMessages({
	schools: {
		defaultMessage: "Schools",
	},
	markets: {
		defaultMessage: "Markets",
	},
	languages: {
		defaultMessage: "Languages",
	},
	modalities: {
		defaultMessage: "Modalities",
	},
});
