import * as React from "react";

import { useDeleteTenderTalentMutation, useTenderTalentsQuery } from "@api";

import TendersTalentDashboard from "containers/TendersTalentDashboard/TendersTalentDashboard";
import ChallengeTenderModel from "models/challenge-tenders/ChallengeTender";
import TalentModel from "models/talents/Talent";

import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";

export const ChallengeTenderTalents = ({ tender }: { tender: ChallengeTenderModel }) => {
	const { data: tenderTalents, isLoading } = useTenderTalentsQuery(tender.id);
	const { mutate } = useDeleteTenderTalentMutation(tender.id);

	const tenderTalentsModels =
		tenderTalents?.map((tenderTalent) => {
			const talentModel = TalentModel.generateFromAPI(tenderTalent);

			talentModel.isMine = tender.isMine;

			return talentModel;
		}) || [];

	const handleOnDeleteTalent = (tenderTalentId: string) => mutate(Number(tenderTalentId));

	if (isLoading) {
		return (
			<Loader
				className="margin-4-top block"
				active
				inline={"centered"}
			/>
		);
	}

	return (
		<div className="pb-8">
			<TendersTalentDashboard
				challengeTenderID={tender.id}
				isMine={tender.isMine}
				talents={tenderTalentsModels}
				deleteTalent={handleOnDeleteTalent}
			/>
		</div>
	);
};
