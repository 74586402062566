export enum ProductKeys {
	Elearnings = "Elearnings",
	Talents = "Talents",
	Challenges = "Challenges",
}

interface Products {
	text: string;
	key: ProductKeys;
	icon: string;
}

const ProductsList: Products[] = [
	{
		text: "E-learnings",
		key: ProductKeys.Elearnings,
		icon: "book",
	},
	{
		text: "Talents",
		key: ProductKeys.Talents,
		icon: "briefcase",
	},
	{
		text: "Challenges",
		key: ProductKeys.Challenges,
		icon: "trophy",
	},
];

export default ProductsList;
