import { ChangeEventHandler, FocusEventHandler } from "react";
import { useIntl } from "react-intl";

import { Filter, InputField } from "@common";

import messages from "../messages";

interface PriceProps {
	onChangePrice: (min?: number, max?: number) => void;
	currentSelectedMarketplacePrice: {
		min?: number;
		max?: number;
	};
	filterIdsPrefix: string;
}

export const Price = ({ onChangePrice, currentSelectedMarketplacePrice, filterIdsPrefix }: PriceProps) => {
	const { formatMessage } = useIntl();
	const { min, max } = currentSelectedMarketplacePrice;

	const onChangeMinPrice: ChangeEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;

		onChangePrice(value ? Number(value) : undefined, max);
	};

	const onChangeMaxPrice: ChangeEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;

		onChangePrice(min, value ? Number(value) : undefined);
	};

	const onBlurMaxPrice: FocusEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;

		if (min && Number(value) < min) {
			onChangePrice(Number(value), max);
		}
	};

	const onBlurMinPrice: FocusEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;

		if (max && Number(value) > max) {
			onChangePrice(min, Number(value));
		}
	};

	return (
		<Filter
			id="price"
			title={formatMessage(messages.price)}
		>
			<div className="flex items-center justify-center gap-4">
				<div>
					<InputField
						type="number"
						name={`${filterIdsPrefix}-min-price`}
						id={`${filterIdsPrefix}-min-price`}
						placeholder="$0"
						required
						value={min}
						onChange={onChangeMinPrice}
						onBlur={onBlurMinPrice}
						labelValue={formatMessage(messages.min)}
					/>
				</div>
				<div>
					<InputField
						type="number"
						name={`${filterIdsPrefix}-max-price`}
						id={`${filterIdsPrefix}-max-price`}
						placeholder="$60000"
						required
						value={max}
						onChange={onChangeMaxPrice}
						onBlur={onBlurMaxPrice}
						labelValue={formatMessage(messages.max)}
					/>
				</div>
			</div>
		</Filter>
	);
};
