import * as React from "react";
import { Badge } from "flowbite-react";
import { FormattedMessage } from "react-intl";

import { Beaker, Card, Globe, InternalLink } from "@common";

import MarketplaceModel from "models/marketplaces/Marketplace";

import { marketplaceTypesMessages, marketplaceOfferMessages } from "./messages";
import {useLocales} from "@libs";

type MarketplaceCardProps = MarketplaceModel;

const MAX_CATEGORIES_PER_FEATURE = 5;

export const MarketplaceCard = ({
	title,
	getInternalURL,
	getFormattedImages,
	getFormattedPVP,
	getPVPWithDiscount,
	getFormattedPVPWithDiscount,
	type,
	offerType,
	regions,
	sectors,
}: MarketplaceCardProps) => {
	const {currentShortLocale} = useLocales();

	return (
		<Card
			className="relative h-full w-full max-w-none"
			imgClassName="aspect-square"
			imgAlt={title}
			imgSrc={getFormattedImages()[0].src}
			imgHref={getInternalURL(currentShortLocale)}
		>
			<div className="absolute top-2 right-2 mb-1 -ml-1 flex gap-2 rounded-md bg-white p-2">
				<Badge
					color="purple"
					size="sm"
				>
					<FormattedMessage {...marketplaceTypesMessages[type]} />
				</Badge>
				<Badge
					color="success"
					size="sm"
				>
					<FormattedMessage {...marketplaceOfferMessages[offerType]} />
				</Badge>
			</div>
			<div className="flex h-full flex-col justify-between text-left">
				<div className="mb-3 flex items-center justify-between gap-4">
					<InternalLink
						href={getInternalURL(currentShortLocale)}
						className="block"
					>
						<h5 className="mt-0 text-lg font-semibold capitalize tracking-tight text-gray-900 line-clamp-2 hover:text-blue-600 dark:text-white">
							{title}
						</h5>
					</InternalLink>
					<div className="flex flex-col items-center gap-2 text-center">
						{getPVPWithDiscount() > 0 ? (
							<>
								<Badge
									color="success"
									size="md"
								>
									<span>{getFormattedPVPWithDiscount()}</span>
								</Badge>
								<Badge
									color="gray"
									size="xs"
								>
									<span className="line-through">{getFormattedPVP()}</span>
								</Badge>
							</>
						) : (
							<Badge
								color="success"
								size="md"
							>
								<span>{getFormattedPVP()}</span>
							</Badge>
						)}
					</div>
				</div>
				<ul className="mt-6 flex w-full flex-1 flex-col gap-4 text-neutral-500">
					<li className="flex gap-2">
						<Globe className="h-6 w-6 shrink-0" />
						<div className="flex flex-wrap gap-2">
							{regions.slice(0, MAX_CATEGORIES_PER_FEATURE).map((region) => (
								<Badge
									key={region.key}
									color="gray"
								>
									{region.text}
								</Badge>
							))}
						</div>
					</li>

					<li className="flex gap-2">
						<Beaker className="h-6 w-6 shrink-0" />
						<div className="flex flex-wrap gap-2">
							{sectors.slice(0, MAX_CATEGORIES_PER_FEATURE).map((sector) => (
								<Badge key={sector.key}>{sector.text}</Badge>
							))}
							{sectors.length > MAX_CATEGORIES_PER_FEATURE && (
								<span className="text-sm font-semibold"> +{sectors.length - MAX_CATEGORIES_PER_FEATURE} </span>
							)}
						</div>
					</li>
				</ul>
			</div>
		</Card>
	);
};
