import { FormattedMessage } from "react-intl";
import Link from "next/link";

import { AcademicCap, Beaker, Film, InternalLink, PaperAirplane } from "@common";

import messages from "./messages";

export const ElearningsSidebar = () => {
	return (
		<div
			className="h-full w-full pb-20"
			data-testid="ELEARNINGS_SIDEBAR"
		>
			<div className="flex h-full flex-col justify-between overflow-y-auto rounded px-3">
				<div>
					<div className="px-6 pt-3 pb-10">
						<h5 className="text-base font-bold text-green-400 dark:text-white">
							<FormattedMessage {...messages.elearnings} />
						</h5>
						<ul className="space-y-2 pt-6">
							<li>
								<div className="flex items-center rounded-lg bg-violet-100 p-4 text-green-700 hover:text-green-700">
									<PaperAirplane className="h-6 w-6" />
									<span className="ml-3 font-medium">
										<FormattedMessage {...messages.overview} />
									</span>
								</div>
							</li>
							<li>
								<InternalLink
									href="/elearnings/explorer/courses"
									className="flex items-center rounded-lg p-4 text-base font-medium text-neutral-500 hover:bg-gray-100 hover:text-green-500 dark:text-white dark:hover:bg-gray-700"
								>
									<AcademicCap className="h-6 w-6" />
									<span className="ml-3">
										<FormattedMessage {...messages.courses} />
									</span>
								</InternalLink>
							</li>
							<li>
								<InternalLink
									href="/elearnings/explorer/webinars"
									className="flex items-center rounded-lg p-4 text-base font-medium text-neutral-500 hover:bg-gray-100 hover:text-green-500 dark:text-white dark:hover:bg-gray-700"
								>
									<Film className="h-6 w-6" />
									<span className="ml-3">
										<FormattedMessage {...messages.webinars} />
									</span>
								</InternalLink>
							</li>
							<li>
								<InternalLink
									href="/elearnings/explorer/workshops"
									className="flex items-center rounded-lg p-4 text-base font-medium text-neutral-500 hover:bg-gray-100 hover:text-green-500 dark:text-white dark:hover:bg-gray-700"
								>
									<Beaker className="h-6 w-6" />
									<span className="ml-3">
										<FormattedMessage {...messages.workshops} />
									</span>
								</InternalLink>
							</li>
						</ul>
					</div>
				</div>
				{/*<div id="dropdown-cta" className="p-4 mb-10 bg-blue-50 rounded-lg dark:bg-blue-900" role="alert">*/}
				{/*    <p className="w-full flex mb-3 text-sm text-blue-900 dark:text-blue-400">*/}
				{/*        <Cube className="w-20 pr-4"/>*/}
				{/*        <span>*/}
				{/*            Añade skills a tu perfil para mejorar la experiencia dentro de Vinglet*/}
				{/*        </span>*/}
				{/*    </p>*/}
				{/*    <div className="w-full">*/}
				{/*        <Button fluid size="sm">*/}
				{/*            Seleccionar habilidades*/}
				{/*        </Button>*/}
				{/*    </div>*/}
				{/*</div>*/}
			</div>
		</div>
	);
};
