import { defineMessages } from "react-intl";

export default defineMessages({
	knowTheMentors: {
		defaultMessage: "Know the mentors",
	},
	mentor: {
		defaultMessage: "Mentor",
	},
	addNewMentors: {
		defaultMessage: "Add new mentors",
	},
	applyNewMentors: {
		defaultMessage: "Apply new mentors",
	},
	cancel: {
		defaultMessage: "Cancel",
	},
	mentorAlreadySelected: {
		defaultMessage: "Mentor already selected",
	},
	selectMentor: {
		defaultMessage: "Select mentor",
	},
	knowTheMentorsDescription: {
		defaultMessage: "Space to link your teacher, mentor, advisor, evaluator, etc. The people you identify here will be able to download the documents that you have attached within your challenge response, open innovation, hackathon, acceleration program, etc. The documents can only be seen by those people who have linked your answer profile, otherwise they will not be able to download or view the documents."
	}
});
