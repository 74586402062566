import { Translation } from "i18n/config";
import { SelectBusinessProfileTranslation } from "./typing";

export const SelectBusinessProfileTexts: Translation<SelectBusinessProfileTranslation> = {
	es: {
		title: "Crear un nuevo perfil",
		intro: "",
		titleUniversity: "Universidad",
		descriptionUniversity:
			"Regístrate como Universidad, identifica las facultades, carga E-learning y vincúlate con todos los perfiles.",
		titleAccelerator: "Aceleradora",
		descriptionAccelerator:
			"Registrarte como aceleradora, identifica tus mercados e inversión anual, capta talento para tus Startups, carga E-learning y obtén métricas de Startups.",
		titleBusinessAngel: "Business Angel",
		descriptionBusinessAngel:
			"Registrarte como Business Angel, identifica tus mercados e inversión anual, capta talento para tus Startups y obtén métricas de Startups.",
		titleCompany: "Empresa",
		descriptionCompany:
			"Registra tu empresa, busca talento, valida y compra en marketplace, carga formación y vincúlate con Universidades.",
		titleInstitution: "Institución",
		descriptionInstitution:
			"Registrarte como institución pública o privada, identifica tus mercados, carga E-learning y talento.",
		titleStartup: "Startup",
		descriptionStartup:
			"Capta talento, realiza procesos de selección. Contrata a un asesor que contenga tu propuesta. Vende en Marketplace. Y realiza las métricas para captar inversión.",
		titleAdviser: "Asesor",
		descriptionAdviser:
			"Registrarte como asesor, identifica las categorías, trabaja con el ecosistema, carga formación y vincúlate con Universidades.",
		titleIncubator: "Incubadora",
		descriptionIncubator:
			"Registrarte como incubadora, identifica tus mercados e inversión anual, capta talento para tus Startups, carga E-learning y obtén métricas de Startups.",
	},
	en: {
		title: "Create a new profile",
		intro: "",
		titleUniversity: "University",
		descriptionUniversity: "Register as a University, identify faculties, load E-learning and link with all profiles",
		titleAccelerator: "Accelerator",
		descriptionAccelerator:
			"Register as an accelerator, identify your markets and annual investment, capture talent for your Startups, load E-learning and get Startup metrics.",
		titleBusinessAngel: "Business Angel",
		descriptionBusinessAngel:
			"Register as a Business Angel, identify your markets and annual investment, get talent for your Startups and get Startup metrics.",
		titleCompany: "Company",
		descriptionCompany:
			"Register your company, search for talent, validate and buy in marketplace, upload training and connect with Universities.",
		titleInstitution: "Institution",
		descriptionInstitution:
			"Register as a public or private institution, identify your markets, load E-learning and talent.",
		titleStartup: "Startup",
		descriptionStartup:
			"Capture talent, perform selection processes. Hire an advisor that contains your proposal. Sell ​​on Marketplace. And make the metrics to capture investment.",
		titleAdviser: "Adviser",
		descriptionAdviser:
			"Register as an advisor, identify the categories, work with the ecosystem, load training and connect with Universities.",
		titleIncubator: "Incubator",
		descriptionIncubator:
			"Register as an incubator, identify your markets and annual investment, capture talent for your Startups, load E-learning and get Startup metrics.",
	},
	ca: {
		title: "Crear un nou perfil",
		intro: "",
		titleUniversity: "Universitat",
		descriptionUniversity:
			"Registrar-te com Universitat, identifica les facultats, càrrega E-learning i Vincula't amb tots els perfils.",
		titleAccelerator: "Acceleradora",
		descriptionAccelerator:
			"Registrar-te com acceleradora, identifica els teus mercats i inversió anual, capta talent per als teus Startups, càrrega E-learning i aconsegueix mètriques de Startups.",
		titleBusinessAngel: "Business Angel",
		descriptionBusinessAngel:
			"Registrar-te com Business Angel, identifica els teus mercats i inversió anual, capta talent per als teus Startups i aconsegueix mètriques de Startups.",
		titleCompany: "Empresa",
		descriptionCompany:
			"Registra la teva empresa, busca talent, valida i compra a marketplace, càrrega formació i Vincula't amb Universitats.",
		titleInstitution: "Institucion",
		descriptionInstitution:
			"Registrar-te com a institució pública o privada, identifica els teus mercats, càrrega E-learning i talent.",
		titleStartup: "Startup",
		descriptionStartup:
			"Capta talent, realitza processos de selecció. Contracta un assessor que contingui la teva proposta. Ven a Marketplace. I realitza les mètriques per captar inversió.",
		titleAdviser: "Assessor",
		descriptionAdviser:
			"Registrar-te com assessor, identifica les categories, treballa amb l'ecosistema, càrrega formació i Vincula't amb Universitats.",
		titleIncubator: "Incubadora",
		descriptionIncubator:
			"Registrar-te com incubadora, identifica els teus mercats i inversió anual, capta talent per als teus Startups, càrrega E-learning i aconsegueix mètriques de Startups.",
	},
	pt: {
		title: "Criar um novo perfil",
		intro: "",
		titleUniversity: "Universidade",
		descriptionUniversity:
			"Registre-se como uma universidade, identifique as faculdades, carregue o E-learning e vincule-se a todos os perfis.",
		titleAccelerator: "Acelerador",
		descriptionAccelerator:
			"Registre-se como um acelerador, identifique seus mercados e investimentos anuais, atraia talentos para suas Startups, faça upload de E-learning e obtenha métricas de Startup.",
		titleBusinessAngel: "Business Angel",
		descriptionBusinessAngel:
			"Registe-se como Business Angel, identifique os seus mercados e investimento anual, atraia talento para as suas Startups e obtenha métricas de Startup.",
		titleCompany: "Empresa",
		descriptionCompany:
			"Registe-se como empresa, busque talentos, valide e compre no marketplace, carregue treinamentos e vincule-se com Universidade.",
		titleInstitution: "Instituição",
		descriptionInstitution:
			"Registre-se como instituição pública ou privada, identifique seus mercados, faça upload de E-learning e talento.",
		titleStartup: "Startup",
		descriptionStartup:
			"Capturar talentos, realizar processos seletivos. Contrate um consultor que contenha sua proposta. Vender no Marketplace. E faça as métricas para atrair investimentos.",
		titleAdviser: "Orientador",
		descriptionAdviser:
			"Cadastre-se como orientador, identifique as categorias, trabalhe com o ecossistema, carregue treinamentos e link com Universidades.",
		titleIncubator: "Incubadora",
		descriptionIncubator:
			"Registre-se como incubadora, identifique seus mercados e investimento anual, atraia talentos para suas Startups, faça upload de E-learning e obtenha métricas de Startup.",
	},
};
