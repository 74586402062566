import * as React from "react";
import { FormattedMessage } from "react-intl";

import { useUniversitiesQuery } from "@api";
import { Button, InternalLink, UniversitiesList } from "@common";

import BusinessProfileModel from "models/business-profiles/BusinessProfile";

import messages from "./messages";

export const ElearningsUniversities = () => {
	const { data: universities, isLoading: isUniversitiesLoading } = useUniversitiesQuery(
		{
			page: 1,
			perPage: 4,
			orderDirection: "DESC",
		},
		{
			staleTime: 1_000_000,
		},
	);

	const universitiesModels =
		universities?.items
			.filter((university) => university.agreement_accepted)
			.map((university) => BusinessProfileModel.generateFromAPI(university)) || [];

	return (
		<div>
			<div className="flex items-center justify-between gap-4">
				<div className="w-full lg:max-w-2xl">
					<h2 className="text-2xl font-extrabold xl:text-4xl">
						<FormattedMessage {...messages.title} />
					</h2>
					<p className="text-sm font-medium text-neutral-600">
						<FormattedMessage {...messages.subTitle} />
					</p>
				</div>
				<InternalLink
					className="shrink-0"
					href="/universities"
				>
					<Button
						size="xl"
						color="success"
					>
						<FormattedMessage {...messages.exploreAll} />
					</Button>
				</InternalLink>
			</div>
			<UniversitiesList
				universities={universitiesModels}
				loading={isUniversitiesLoading}
			/>
		</div>
	);
};
