import React, { ChangeEventHandler, FocusEventHandler } from "react";
import { useIntl } from "react-intl";

import { Filter, InputField } from "@common";

import messages from "../messages";

interface AverageInvestmentProps {
	onChangeAverageInvestment: (min?: number, max?: number) => void;
	currentSelectedPostulateAverageInvestment: {
		min?: number;
		max?: number;
	};
	filterIdsPrefix: string;
}

export const AverageInvestment = ({
	onChangeAverageInvestment,
	currentSelectedPostulateAverageInvestment,
	filterIdsPrefix,
}: AverageInvestmentProps) => {
	const { formatMessage } = useIntl();
	const { min, max } = currentSelectedPostulateAverageInvestment;

	const onChangeMinAverageInvestment: ChangeEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;

		onChangeAverageInvestment(value ? Number(value) : undefined, max);
	};

	const onChangeMaxAverageInvestment: ChangeEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;

		onChangeAverageInvestment(min, value ? Number(value) : undefined);
	};

	const onBlurMaxAverageInvestment: FocusEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;

		if (min && Number(value) < min) {
			onChangeAverageInvestment(Number(value), max);
		}
	};

	const onBlurMinAverageInvestment: FocusEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;

		if (max && Number(value) > max) {
			onChangeAverageInvestment(min, Number(value));
		}
	};

	return (
		<Filter
			id="averageInvestment"
			title={formatMessage(messages.averageInvestment)}
		>
			<div className="flex items-center justify-center gap-4">
				<div className="w-full">
					<InputField
						type="number"
						name={`${filterIdsPrefix}-min-averageInvestment`}
						id={`${filterIdsPrefix}-min-averageInvestment`}
						placeholder="$0"
						required
						value={min}
						onChange={onChangeMinAverageInvestment}
						onBlur={onBlurMinAverageInvestment}
						labelValue={formatMessage(messages.min)}
					/>
				</div>
				<div className="w-full">
					<InputField
						type="number"
						name={`${filterIdsPrefix}-max-averageInvestment`}
						id={`${filterIdsPrefix}-max-averageInvestment`}
						placeholder="$5000000"
						required
						value={max}
						onChange={onChangeMaxAverageInvestment}
						onBlur={onBlurMaxAverageInvestment}
						labelValue={formatMessage(messages.max)}
					/>
				</div>
			</div>
		</Filter>
	);
};
