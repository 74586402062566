import { useIntl } from "react-intl";
import { useCallback, useEffect, useReducer } from "react";
import { Card } from "flowbite-react";

import { LabelListProfile } from "@common";

import CategorySelectableModel from "models/categories/CategorySelectable";

import { initialState, LabelAdviserType, labelReducer } from "./state";
import { getAdviserSkillsAsync } from "./get-adviser-skills";

import messages from "./messages";

interface AdviserSkillsProps {
	onUpdate: (adviserSkills: CategorySelectableModel[]) => void;
}

export const AdviserSkills = ({ onUpdate }: AdviserSkillsProps) => {
	const { formatMessage } = useIntl();
	const [labels, dispatchLabels] = useReducer(labelReducer, initialState);

	const getCategoriesAdviserSkillsFromLabels = useCallback(
		(): CategorySelectableModel[] =>
			Object.values(labels).reduce((prevValue, nextValue) => {
				return [...prevValue, ...nextValue];
			}, []),
		[labels],
	);
	const handleOnChangeBusiness = (items: CategorySelectableModel[]) => {
		dispatchLabels({
			type: LabelAdviserType.BUSINESS,
			payload: items,
		});
	};
	const handleOnChangeSalesAndMarketing = (items: CategorySelectableModel[]) =>
		dispatchLabels({
			type: LabelAdviserType.SALES_AND_MARKETING,
			payload: items,
		});
	const handleOnChangeInvestment = (items: CategorySelectableModel[]) =>
		dispatchLabels({
			type: LabelAdviserType.INVESTMENT,
			payload: items,
		});
	const handleOnChangeDesignAndProduct = (items: CategorySelectableModel[]) =>
		dispatchLabels({
			type: LabelAdviserType.DESIGN_AND_PRODUCT,
			payload: items,
		});
	const handleOnChangeIT = (items: CategorySelectableModel[]) =>
		dispatchLabels({
			type: LabelAdviserType.IT,
			payload: items,
		});
	const handleOnChangeLegal = (items: CategorySelectableModel[]) =>
		dispatchLabels({
			type: LabelAdviserType.LEGAL,
			payload: items,
		});
	const handleOnChangeAccountant = (items: CategorySelectableModel[]) =>
		dispatchLabels({
			type: LabelAdviserType.ACCOUNTANT,
			payload: items,
		});
	const handleOnChangeEngineeringAndArchitecture = (items: CategorySelectableModel[]) =>
		dispatchLabels({
			type: LabelAdviserType.ENGINEERING_AND_ARCHITECTURE,
			payload: items,
		});
	const handleOnChangeMedicineAndPsychology = (items: CategorySelectableModel[]) =>
		dispatchLabels({
			type: LabelAdviserType.MEDICINE_AND_PSYCHOLOGY,
			payload: items,
		});

	useEffect(() => {
		getAdviserSkillsAsync().then((adviserSkills) => {
			const adviserSkillTypes: LabelAdviserType[] = Object.keys(adviserSkills) as LabelAdviserType[];

			adviserSkillTypes.forEach((adviserSkillType) => {
				const categoriesSelectable: CategorySelectableModel[] = adviserSkills[adviserSkillType];

				dispatchLabels({
					type: adviserSkillType,
					payload: categoriesSelectable.map((category) => {
						return {
							...category,
							selected: false,
						};
					}),
				});
			});
		});
	}, []);

	useEffect(() => {
		if (onUpdate) {
			onUpdate(getCategoriesAdviserSkillsFromLabels());
		}
	}, [getCategoriesAdviserSkillsFromLabels, onUpdate]);

	const cards: {
		title: string;
		categories: CategorySelectableModel[];
		onUpdate: (items: CategorySelectableModel[]) => void;
	}[] = [
		{
			title: formatMessage(messages.business),
			categories: labels.business,
			onUpdate: handleOnChangeBusiness,
		},
		{
			title: formatMessage(messages.salesAndMarketing),
			categories: labels.salesAndMarketing,
			onUpdate: handleOnChangeSalesAndMarketing,
		},
		{
			title: formatMessage(messages.investment),
			categories: labels.investment,
			onUpdate: handleOnChangeInvestment,
		},
		{
			title: formatMessage(messages.desingAndProduct),
			categories: labels.designAndProduct,
			onUpdate: handleOnChangeDesignAndProduct,
		},
		{
			title: formatMessage(messages.it),
			categories: labels.it,
			onUpdate: handleOnChangeIT,
		},
		{
			title: formatMessage(messages.legal),
			categories: labels.legal,
			onUpdate: handleOnChangeLegal,
		},
		{
			title: formatMessage(messages.accountant),
			categories: labels.accountant,
			onUpdate: handleOnChangeAccountant,
		},
		{
			title: formatMessage(messages.engineeringAndArchitecture),
			categories: labels.engineeringAndArchitecture,
			onUpdate: handleOnChangeEngineeringAndArchitecture,
		},
		{
			title: formatMessage(messages.medicineAndPsychology),
			categories: labels.medicineAndPsychology,
			onUpdate: handleOnChangeMedicineAndPsychology,
		},
	];

	return (
		<div className="grid grid-cols-1 gap-4 md:grid-cols-2">
			{cards.map((card) => (
				<Card key={card.title}>
					<div className="flex h-full flex-col gap-3">
						<span className="text-base font-semibold"> {card.title} </span>
						<LabelListProfile
							items={card.categories}
							onUpdate={card.onUpdate}
							gridClassName="flex flex-wrap"
							color="blue"
							showAll
							editable
							basic
						/>
					</div>
				</Card>
			))}
		</div>
	);
};
