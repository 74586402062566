import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { TendersSection } from "@app-components";
import { Briefcase, CogSixTooth, RocketLaunch, UserGroup } from "@common";

import { TenderSidebarItem } from "./TenderSidebarItem";
import messages from "./messages";

export const ChallengeTenderSidebar = ({
	idTender,
	isMine,
	activeSection,
}: {
	idTender: number;
	isMine: boolean;
	activeSection: TendersSection;
}) => {
	const { formatMessage } = useIntl();

	return (
		<header>
			<div className="flex flex-col gap-4 px-10 text-center">
				<ul className="flex flex-col space-y-1 py-4">
					<li className="px-5">
						<div className="flex h-8 flex-row items-center">
							<div className="text-sm font-light tracking-wide text-gray-500">
								<FormattedMessage {...messages.menu} />
							</div>
						</div>
					</li>
					<TenderSidebarItem
						href={`/tenders/${idTender}`}
						text={formatMessage(messages.generalOverview)}
						icon={<RocketLaunch className="h-6 w-6" />}
						isActive={activeSection === "general"}
					/>
					<TenderSidebarItem
						href={`/tenders/${idTender}/team`}
						text={formatMessage(messages.knowTheTeam)}
						icon={<UserGroup className="h-6 w-6" />}
						isActive={activeSection === "teams"}
					/>
					<TenderSidebarItem
						href={`/tenders/${idTender}/talents`}
						text={formatMessage(messages.talentOffers)}
						icon={<Briefcase className="h-6 w-6" />}
						isActive={activeSection === "talents"}
					/>
					{isMine && (
						<>
							<li className="px-5">
								<div className="flex h-8 flex-row items-center">
									<div className="text-sm font-light tracking-wide text-gray-500">
										<FormattedMessage {...messages.settings} />
									</div>
								</div>
							</li>
							<TenderSidebarItem
								href={`/tenders/${idTender}/settings`}
								text={formatMessage(messages.settings)}
								icon={<CogSixTooth className="h-6 w-6" />}
								isActive={activeSection === "settings"}
							/>
						</>
					)}
				</ul>
			</div>
		</header>
	);
};
