import { ChangeEventHandler } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { MarketplaceType } from "@api";
import { Cube, Filter, Select } from "@common";

import messages from "../messages";

import { marketplaceTypesMessages } from "./messages";

interface TypeProps {
	onChangeType: (marketplaceType?: MarketplaceType) => void;
	currentSelectedMarketplaceType?: MarketplaceType;
	filterIdsPrefix: string;
}

export const Type = ({ onChangeType, currentSelectedMarketplaceType, filterIdsPrefix }: TypeProps) => {
	const { formatMessage } = useIntl();
	const marketplaceOptions: MarketplaceType[] = ["SERVICE", "PRODUCT"];

	const onChangeMarketplaceType: ChangeEventHandler<HTMLSelectElement> = (event) => {
		if (marketplaceOptions.includes(event.target.value as MarketplaceType)) {
			onChangeType(event.target.value as MarketplaceType);
			return;
		}

		onChangeType();
	};

	return (
		<Filter
			id="type"
			title={formatMessage(messages.type)}
		>
			<Select
				name="marketplace-type"
				id={`${filterIdsPrefix}-marketplace-type`}
				sizing="lg"
				icon={Cube}
				onChange={onChangeMarketplaceType}
				shadow
				value={currentSelectedMarketplaceType || "ALL"}
			>
				<option
					key={"ALL"}
					value={"ALL"}
				>
					<FormattedMessage {...marketplaceTypesMessages["ALL"]} />
				</option>
				{marketplaceOptions.map((marketplaceOption) => (
					<option
						key={marketplaceOption}
						value={marketplaceOption}
					>
						<FormattedMessage {...marketplaceTypesMessages[marketplaceOption]} />
					</option>
				))}
			</Select>
		</Filter>
	);
};
