import { defineMessages } from "react-intl";

export default defineMessages({
	menu: {
		defaultMessage: "Menu",
	},
	generalOverview: {
		defaultMessage: "General overview",
	},
	knowTheTeam: {
		defaultMessage: "Know the team",
	},
	talentOffers: {
		defaultMessage: "Talent offers",
	},
	settings: {
		defaultMessage: "Settings",
	},
});
