import * as React from "react";
import Image from "next/image";

import {useLocales} from "@libs";

import { SelectBusinessProfileTranslation } from "i18n/components/SelectBusinessProfile/typing";
import { SelectBusinessProfileTexts } from "i18n/components/SelectBusinessProfile";

import BusinessProfilesModalsList from "./BusinessProfilesModalsList";

export const NewBusinessProfile = ({ hasReachedTheLimit }: { hasReachedTheLimit: boolean }) => {
	const { currentShortLocale } = useLocales();
	const profileTranslations: SelectBusinessProfileTranslation = {
		...SelectBusinessProfileTexts[currentShortLocale],
	};
	const businessProfilesModalsList = BusinessProfilesModalsList(profileTranslations, hasReachedTheLimit);

	return (
		<>
			<div className="mt-16 mb-16 w-full text-center">
				<h1> {profileTranslations.title} </h1>
				<p> {profileTranslations.intro} </p>
			</div>
			<div className="mt-10 grid w-full grid-cols-1 items-center justify-center gap-10 px-20 pb-36 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
				{businessProfilesModalsList.map((item) => (
					<div
						key={item.header}
						className="rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800"
					>
						<div className="relative h-36 w-full">
							<Image
								className="rounded-t-lg object-cover"
								src={item.image.src}
								alt={item.header}
								layout="fill"
							/>
						</div>
						<div className="p-5">
							<div className="mb-6 h-auto md:h-40 lg:h-56 xl:h-40">
								<h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{item.header}</h5>
								<p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{item.description}</p>
							</div>
							{item.modal}
						</div>
					</div>
				))}
			</div>
		</>
	);
};
