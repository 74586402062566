import * as React from "react";
import { ChangeEventHandler } from "react";
import { FormattedMessage } from "react-intl";
import { Label as FlowbiteLabel } from "flowbite-react";

import { InputSlider } from "@common";

import messages from "./messages";

export interface Statistic {
	middleFunds: number;
	percentajePerStartup: number;
	startUpsPerYear: number;
}

interface StatisticFormProps {
	statistic: Statistic;
	onUpdateStatistic: (newStatistic: Statistic) => void;
}

export const StatisticForm = ({ statistic, onUpdateStatistic }: StatisticFormProps) => {
	const { middleFunds, percentajePerStartup, startUpsPerYear } = statistic;

	const handleOnChangeMiddleFunds: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
		onUpdateStatistic({
			...statistic,
			middleFunds: Number(target.value),
		});
	};

	const handleOnChangePercentajePerStartup: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
		onUpdateStatistic({
			...statistic,
			percentajePerStartup: Number(target.value),
		});
	};

	const handleOnChangeStartupsPerYear: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
		onUpdateStatistic({
			...statistic,
			startUpsPerYear: Number(target.value),
		});
	};

	return (
		<div className="mb-8 flex flex-col gap-4 px-20">
			<FlowbiteLabel htmlFor="middleFunds">
				<div className="mt-2 text-lg font-semibold">
					<FormattedMessage {...messages.investByYear} />
				</div>
			</FlowbiteLabel>
			<p className="m-0">
				{" "}
				<b className="font-semibold text-gray-400">US$</b> {middleFunds}{" "}
			</p>
			<InputSlider
				id="middleFunds"
				name="middleFunds"
				defaultValue={middleFunds}
				step={10000}
				min={0}
				max={500000}
				onInput={handleOnChangeMiddleFunds}
			/>
			<FlowbiteLabel htmlFor="percentajePerStartup">
				<div className="mt-8 text-lg font-semibold">
					<FormattedMessage {...messages.numberOfStartupsPerYear} />
				</div>
			</FlowbiteLabel>
			<p className="m-0">
				{" "}
				{percentajePerStartup} <b className="font-semibold text-gray-400">%</b>{" "}
			</p>
			<InputSlider
				id="percentajePerStartup"
				name="percentajePerStartup"
				defaultValue={percentajePerStartup}
				step={0.5}
				min={0}
				max={100}
				onInput={handleOnChangePercentajePerStartup}
			/>
			<FlowbiteLabel htmlFor="startUpsPerYear">
				<div className="mt-8 text-lg font-semibold">
					<FormattedMessage {...messages.averageYear} />
				</div>
			</FlowbiteLabel>
			<p className="m-0"> {startUpsPerYear} </p>
			<InputSlider
				id="startUpsPerYear"
				name="startUpsPerYear"
				defaultValue={startUpsPerYear}
				step={1}
				min={0}
				max={30}
				onInput={handleOnChangeStartupsPerYear}
			/>
		</div>
	);
};
