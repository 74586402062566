import {
	SkillAdviserAccountant,
	SkillAdviserBusiness,
	SkillAdviserDesignAndProduct,
	SkillAdviserEngineeringAndArchitecture,
	SkillAdviserInvestment,
	SkillAdviserIT,
	SkillAdviserLegal,
	SkillAdviserMedicineAndPsychology,
	SkillAdviserSalesAndMarketing,
} from "constants/Feature";
import CategorySelectableModel from "models/categories/CategorySelectable";
import { ObjectUtils } from "utils/ObjectUtils";
import { CategoryType } from "api/CategoriesAPI/urls";
import CategoryModel from "models/categories/Category";
import CategoriesServiceSingleton from "services/categories/CategoriesService";

import { LabelAdviserType } from "./state";

const mapObjectPropsToCategorySelectableModels = (
	subCategories: ObjectUtils.ObjectProps[],
): CategorySelectableModel[] => {
	const categoryList = CategoriesServiceSingleton.getList();

	const subCategoryKeys = subCategories.map((subCategory) => subCategory.value);

	return categoryList
		.filterByCategoryType(CategoryType.ADVISER_SKILLS, true)
		.map((category: CategoryModel) => CategorySelectableModel.createFromCommonCategory(category))
		.filter((category: CategorySelectableModel) => subCategoryKeys.includes(category.key));
};

export const getAdviserSkillsAsync = (): Promise<Record<LabelAdviserType, CategorySelectableModel[]>> => {
	return Promise.all([
		SkillAdviserBusiness,
		SkillAdviserSalesAndMarketing,
		SkillAdviserInvestment,
		SkillAdviserDesignAndProduct,
		SkillAdviserIT,
		SkillAdviserLegal,
		SkillAdviserAccountant,
		SkillAdviserEngineeringAndArchitecture,
		SkillAdviserMedicineAndPsychology,
	]).then(
		([
			business,
			salesAndMarketing,
			investment,
			designAndProduct,
			it,
			legal,
			accountant,
			engineeringAndArchitecture,
			medicineAndPsychology,
		]) => {
			return {
				BUSINESS: mapObjectPropsToCategorySelectableModels(business),
				SALES_AND_MARKETING: mapObjectPropsToCategorySelectableModels(salesAndMarketing),
				INVESTMENT: mapObjectPropsToCategorySelectableModels(investment),
				DESIGN_AND_PRODUCT: mapObjectPropsToCategorySelectableModels(designAndProduct),
				IT: mapObjectPropsToCategorySelectableModels(it),
				LEGAL: mapObjectPropsToCategorySelectableModels(legal),
				ACCOUNTANT: mapObjectPropsToCategorySelectableModels(accountant),
				ENGINEERING_AND_ARCHITECTURE: mapObjectPropsToCategorySelectableModels(engineeringAndArchitecture),
				MEDICINE_AND_PSYCHOLOGY: mapObjectPropsToCategorySelectableModels(medicineAndPsychology),
			};
		},
	);
};
