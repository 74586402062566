import { Translation } from "i18n/config";
import { CommonModalTranslation } from "./typing";

export const CommonModalTexts: Translation<CommonModalTranslation> = {
	es: {
		titleCloseModal: "Cerrar ventana",
		adviseCloseModal: "Estas seguro que quieres cerrar la ventana?",
		affirmativeCloseModal: "Si",
		negativeCloseModal: "No",
		titleDelete: "",
		adviseDelete: "Estas seguro que quieres eliminarlo?",
		affirmativeDelete: "Si",
		negativeDelete: "No",
	},
	en: {
		titleCloseModal: "Close Modal",
		adviseCloseModal: "Are you sure you want to close it?",
		affirmativeCloseModal: "Yes",
		negativeCloseModal: "No",
		titleDelete: "",
		adviseDelete: "Are you sure you want to delete it?",
		affirmativeDelete: "Yes",
		negativeDelete: "No",
	},
	ca: {
		titleCloseModal: "Tancar finestra",
		adviseCloseModal: "Aquestes segur que vols tancar la finestra?",
		affirmativeCloseModal: "Si",
		negativeCloseModal: "No",
		titleDelete: "",
		adviseDelete: "Aquestes segur que vols eliminar?",
		affirmativeDelete: "Si",
		negativeDelete: "No",
	},
	pt: {
		titleCloseModal: "Fechar janela",
		adviseCloseModal: "Tem certeza de que deseja fechar a janela?",
		affirmativeCloseModal: "Sim",
		negativeCloseModal: "Não",
		titleDelete: "",
		adviseDelete: "Tem certeza de que deseja excluí-lo?",
		affirmativeDelete: "Sim",
		negativeDelete: "Não",
	},
};
