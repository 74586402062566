import { useState } from "react";
import { useRouter } from "next/router";

import { InvestorTypes } from "@api";
import { getListFromStringifiedList } from "@libs";

import CategoryModel from "models/categories/Category";

import * as Filters from "./Filters";

type CategoriesCounter = number;
type MinAndMaxFilters = {
	min: number | undefined;
	max: number | undefined;
};

type Distinct<T, DistinctName> = T & { __TYPE__: DistinctName };
type SectorsIdsClicked = Distinct<Record<number, boolean>, "SectorsIdsClicked">;
type RegionsIdsClicked = Distinct<Record<number, boolean>, "RegionsIdsClicked">;
type AverageInvestment = Distinct<MinAndMaxFilters, "AverageInvestment">;
type AverageEquity = Distinct<MinAndMaxFilters, "AverageEquity">;
type NumberOfStartupsToInvest = Distinct<MinAndMaxFilters, "NumberOfStartupsToInvest">;
type LanguagesIdsClicked = Distinct<Record<number, boolean>, "LanguagesIdsClicked">;

export type PostulateFilters = {
	investorType: InvestorTypes | undefined;
	sectorsIds: number[];
	regionsIds: number[];
	averageInvestment: AverageInvestment;
	averageEquity: AverageEquity;
	numberOfStartupsToInvest: NumberOfStartupsToInvest;
	languagesIds: number[];
	pageNumber: number;
};

export interface PostulatesExplorerFiltersProps {
	topSectors: [CategoryModel, CategoriesCounter][];
	topRegions: [CategoryModel, CategoriesCounter][];
	topLanguages: [CategoryModel, CategoriesCounter][];
	onChangeFilters: (filters: Omit<PostulateFilters, "pageNumber">) => void;
	filtersIdsPrefix: {
		investorType: string;
		sectors: string;
		regions: string;
		averageInvestment: string;
		averageEquity: string;
		numberOfStartupsToInvest: string;
		languages: string;
	};
}

export const PostulateExplorerFilters = ({
	topSectors,
	topRegions,
	topLanguages,
	onChangeFilters,
	filtersIdsPrefix,
}: PostulatesExplorerFiltersProps) => {
	const router = useRouter();
	const {
		investorType,
		sectorsIds,
		regionsIds,
		minAverageInvestment,
		maxAverageInvestment,
		minAverageEquity,
		maxAverageEquity,
		minNumberOfStartupsToInvest,
		maxNumberOfStartupsToInvest,
		languagesIds,
	} = router.query;
	const initCategoriesIdsClickedFromRouterQuery = (categoriesIds: string[]): Record<number, boolean> => {
		return categoriesIds.reduce((acc, categoryId) => {
			return {
				...acc,
				[Number(categoryId)]: true,
			};
		}, {});
	};

	const [selectedInvestorType, setSelectedInvestorType] = useState<InvestorTypes | undefined>(
		investorType as InvestorTypes | undefined,
	);
	const [sectorsIdsClicked, setSectorsIdsClicked] = useState<SectorsIdsClicked>(
		initCategoriesIdsClickedFromRouterQuery(getListFromStringifiedList(sectorsIds as string)) as SectorsIdsClicked,
	);
	const [regionsIdsClicked, setRegionsIdsClicked] = useState<RegionsIdsClicked>(
		initCategoriesIdsClickedFromRouterQuery(getListFromStringifiedList(regionsIds as string)) as RegionsIdsClicked,
	);
	const [averageInvestment, setAverageInvestment] = useState<AverageInvestment>({
		min: Number.isInteger(minAverageInvestment) ? Number(minAverageInvestment) : undefined,
		max: Number.isInteger(maxAverageInvestment) ? Number(maxAverageInvestment) : undefined,
	} as AverageInvestment);
	const [averageEquity, setAverageEquity] = useState<AverageEquity>({
		min: Number.isInteger(minAverageEquity) ? Number(minAverageEquity) : undefined,
		max: Number.isInteger(maxAverageEquity) ? Number(maxAverageEquity) : undefined,
	} as AverageEquity);
	const [numberOfStartupsToInvest, setNumberOfStartupsToInvest] = useState<NumberOfStartupsToInvest>({
		min: Number.isInteger(minNumberOfStartupsToInvest) ? Number(minNumberOfStartupsToInvest) : undefined,
		max: Number.isInteger(maxNumberOfStartupsToInvest) ? Number(maxNumberOfStartupsToInvest) : undefined,
	} as NumberOfStartupsToInvest);
	const [languagesIdsClicked, setLanguagesIdsClicked] = useState<LanguagesIdsClicked>(
		initCategoriesIdsClickedFromRouterQuery(getListFromStringifiedList(languagesIds as string)) as LanguagesIdsClicked,
	);

	const handleOnChangeFilters = (
		investorType: InvestorTypes | undefined,
		sectorsIdsClicked: SectorsIdsClicked,
		regionsIdsClicked: RegionsIdsClicked,
		averageInvestment: AverageInvestment,
		averageEquity: AverageEquity,
		numberOfStartupsToInvest: NumberOfStartupsToInvest,
		languagesIdsClicked: LanguagesIdsClicked,
	) => {
		const sectorsIds = Object.keys(sectorsIdsClicked)
			.filter((sectorId) => sectorsIdsClicked[Number(sectorId)])
			.map((sectorId) => Number(sectorId));
		const regionsIds = Object.keys(regionsIdsClicked)
			.filter((regionId) => regionsIdsClicked[Number(regionId)])
			.map((regionId) => Number(regionId));
		const languagesIds = Object.keys(languagesIdsClicked)
			.filter((languagesId) => languagesIdsClicked[Number(languagesId)])
			.map((languagesId) => Number(languagesId));

		onChangeFilters({
			investorType,
			sectorsIds,
			regionsIds,
			averageInvestment,
			averageEquity,
			numberOfStartupsToInvest,
			languagesIds,
		});
	};

	const handleOnChangeInvestorType = (investorType?: InvestorTypes) => {
		setSelectedInvestorType(investorType);
		handleOnChangeFilters(
			investorType,
			sectorsIdsClicked,
			regionsIdsClicked,
			averageInvestment,
			averageEquity,
			numberOfStartupsToInvest,
			languagesIdsClicked,
		);
	};
	const handleOnCheckedRegion = (categoryId: number) => {
		const newRegionsIdsClicked: RegionsIdsClicked = {
			...regionsIdsClicked,
			[categoryId]: !regionsIdsClicked[categoryId],
		};

		setRegionsIdsClicked(newRegionsIdsClicked);
		handleOnChangeFilters(
			selectedInvestorType,
			sectorsIdsClicked,
			newRegionsIdsClicked,
			averageInvestment,
			averageEquity,
			numberOfStartupsToInvest,
			languagesIdsClicked,
		);
	};
	const handleOnCheckedSector = (categoryId: number) => {
		const newSectorsIdsClicked: SectorsIdsClicked = {
			...sectorsIdsClicked,
			[categoryId]: !sectorsIdsClicked[categoryId],
		};

		setSectorsIdsClicked(newSectorsIdsClicked);
		handleOnChangeFilters(
			selectedInvestorType,
			newSectorsIdsClicked,
			regionsIdsClicked,
			averageInvestment,
			averageEquity,
			numberOfStartupsToInvest,
			languagesIdsClicked,
		);
	};
	const handleOnChangeAverageInvestment = (min?: number, max?: number) => {
		const newAverageInvestment: AverageInvestment = {
			min,
			max,
		} as AverageInvestment;
		setAverageInvestment(newAverageInvestment);
		handleOnChangeFilters(
			selectedInvestorType,
			sectorsIdsClicked,
			regionsIdsClicked,
			newAverageInvestment,
			averageEquity,
			numberOfStartupsToInvest,
			languagesIdsClicked,
		);
	};
	const handleOnChangeAverageEquity = (min?: number, max?: number) => {
		const newAverageEquity: AverageEquity = {
			min,
			max,
		} as AverageEquity;
		setAverageEquity(newAverageEquity);
		handleOnChangeFilters(
			selectedInvestorType,
			sectorsIdsClicked,
			regionsIdsClicked,
			averageInvestment,
			newAverageEquity,
			numberOfStartupsToInvest,
			languagesIdsClicked,
		);
	};
	const handleOnChangeNumberOfStartupsToInvest = (min?: number, max?: number) => {
		const newNumberOfStartupsToInvest: NumberOfStartupsToInvest = {
			min,
			max,
		} as NumberOfStartupsToInvest;
		setNumberOfStartupsToInvest(newNumberOfStartupsToInvest);
		handleOnChangeFilters(
			selectedInvestorType,
			sectorsIdsClicked,
			regionsIdsClicked,
			averageInvestment,
			averageEquity,
			newNumberOfStartupsToInvest,
			languagesIdsClicked,
		);
	};
	const handleOnCheckedLanguage = (categoryId: number) => {
		const newLanguagesIdsClicked: LanguagesIdsClicked = {
			...languagesIdsClicked,
			[categoryId]: !languagesIdsClicked[categoryId],
		};

		setLanguagesIdsClicked(newLanguagesIdsClicked);
		handleOnChangeFilters(
			selectedInvestorType,
			sectorsIdsClicked,
			regionsIdsClicked,
			averageInvestment,
			averageEquity,
			numberOfStartupsToInvest,
			newLanguagesIdsClicked,
		);
	};

	return (
		<>
			<Filters.InvestorType
				filterIdsPrefix={filtersIdsPrefix.investorType}
				onChangeType={handleOnChangeInvestorType}
				currentSelectedInvestorType={selectedInvestorType}
			/>
			<Filters.Regions
				regions={topRegions}
				regionsIdsClicked={regionsIdsClicked}
				onChangeOneRegion={handleOnCheckedRegion}
				filterIdsPrefix={filtersIdsPrefix.regions}
			/>
			<Filters.Sectors
				sectors={topSectors}
				sectorsIdsClicked={sectorsIdsClicked}
				onChangeOneSector={handleOnCheckedSector}
				filterIdsPrefix={filtersIdsPrefix.sectors}
			/>
			<Filters.AverageInvestment
				filterIdsPrefix={filtersIdsPrefix.averageInvestment}
				currentSelectedPostulateAverageInvestment={{
					min: averageInvestment.min,
					max: averageInvestment.max,
				}}
				onChangeAverageInvestment={handleOnChangeAverageInvestment}
			/>
			<Filters.AverageEquity
				filterIdsPrefix={filtersIdsPrefix.averageEquity}
				currentSelectedPostulateAverageEquity={{
					min: averageEquity.min,
					max: averageEquity.max,
				}}
				onChangeAverageEquity={handleOnChangeAverageEquity}
			/>
			<Filters.NumberOfStartupsToInvest
				filterIdsPrefix={filtersIdsPrefix.numberOfStartupsToInvest}
				currentSelectedPostulateNumberOfStartupsToInvest={{
					min: numberOfStartupsToInvest.min,
					max: numberOfStartupsToInvest.max,
				}}
				onChangeNumberOfStartupsToInvest={handleOnChangeNumberOfStartupsToInvest}
			/>
			<Filters.Languages
				languages={topLanguages}
				languagesIdsClicked={languagesIdsClicked}
				onChangeOneLanguage={handleOnCheckedLanguage}
				filterIdsPrefix={filtersIdsPrefix.languages}
			/>
		</>
	);
};
