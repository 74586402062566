import {DimmeableImage} from "elements/DimmeableImage/DimmeableImage";
import {Image} from "elements/HeroImage/typing";
import {ImagePicker} from "elements/ImagePicker/ImagePicker";

import {NewElearningTexts} from "i18n/containers/NewElearning";
import {NewElearningTranslation} from "i18n/containers/NewElearning/typing";
import * as React from "react";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import {URL_VINGLET_IMAGE} from "constants/URLs";
import {useLocales} from "@libs";

const DEFAULT_IMAGE = "/default-image.png";

const UnitChallengeTenderFieldImage = (props: { image: Image; onChange: (image: Image) => void }) => {
    const {image, onChange} = props;
    const {currentShortLocale} = useLocales();
    const newElearningTranslation: NewElearningTranslation = {
        ...NewElearningTexts[currentShortLocale],
    };
    const onChangeImage = (filename: string) => {
        onChange({
            src: filename,
        });
    };
    const getFullImageURL = (imageSrc: string) => {
        if (imageSrc && imageSrc !== String(DEFAULT_IMAGE)) {
            if (imageSrc.includes(URL_VINGLET_IMAGE)) {
                return imageSrc;
            }

            return URL_VINGLET_IMAGE + imageSrc;
        }

        return String(DEFAULT_IMAGE);
    };

    return (
        <Form.Field className="UnitChallengeTenderFieldImage margin-2-bottom">
            <h3 className="font-color-grey">{newElearningTranslation.backgroundImage}</h3>
            <p> {newElearningTranslation.addImageDescription} </p>
            <DimmeableImage
                fluid
                className="aspect-video overflow-hidden"
                src={getFullImageURL(image.src)}
                content={
                    <ImagePicker
                        image={{
                            src: image.src,
                            alt: "",
                        }}
                        onChange={onChangeImage}
                        className={"margin-0-top"}
                        labelTextButton={newElearningTranslation.changeBackgroundImage}
                        basic
                    />
                }
            />
        </Form.Field>
    );
};

export default React.memo(UnitChallengeTenderFieldImage);
