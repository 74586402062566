import { useContext, useEffect, useState } from "react";

import {UseQueryOptions} from "@tanstack/react-query";
import {fetchTalentAppliers, TalentApplier, TenderTalent, useTenderTalentsQuery} from "@api";
import { CookiesUtils } from "@utils";
import { UserProfileContext } from "@context";

import TalentApplierModel from "models/talents/TalentApplier";

export const useUsersPermissionToDownloadDocuments = (tenderId: number, options: UseQueryOptions<TenderTalent[]> = {}) => {
	const { user } = useContext(UserProfileContext);
	const { data: talents, isLoading } = useTenderTalentsQuery(tenderId, options);
	const [hasPermission, setHasPermission] = useState(false);

	useEffect(() => {
		const accessToken = CookiesUtils.getAccessTokenFromCookie();
		const appliersPromises = talents?.map((talent) => fetchTalentAppliers(talent.id, accessToken)()) || [];

		const getHiredAppliersIds = (appliersByTalents: TalentApplier[][]): string[] => {
			return appliersByTalents.reduce((appliersIds, appliersByTalent) => {
				const hiredAppliersModels = appliersByTalent
					.map((applier) => TalentApplierModel.generateFromAPI(applier))
					.filter((applier) => applier.status.key === "hired");

				return [...appliersIds, ...hiredAppliersModels.map((applier) => applier.user.id)];
			}, [] as string[]);
		};

		Promise.all(appliersPromises)
			.then((appliersByTalents) => {
				const hiredAppliersIds: string[] = getHiredAppliersIds(appliersByTalents);

				setHasPermission(hiredAppliersIds.includes(String(user?.id)));
			})
			.catch(() => {
				setHasPermission(false);
			});
	}, [talents, user?.id]);

	return {
		isLoading,
		hasPermission,
	};
};
