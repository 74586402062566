import { defineMessages } from "react-intl";

export default defineMessages({
	theEmailIsAlreadyTaken: {
		defaultMessage: "The email is already taken by another user, try another one",
	},
	somethingWentWrong: {
		defaultMessage: "Something went wrong, try again later",
	},
	emailBadFormatted: {
		defaultMessage: "The email that you have introduced is wrong or bad formatted",
	},
	signUp: {
		defaultMessage: "Sign up",
	},
	logIn: {
		defaultMessage: "Log in",
	},
	yourEmail: {
		defaultMessage: "Your email",
	},
	password: {
		defaultMessage: "Password",
	},
	yourName: {
		defaultMessage: "Your name",
	},
	yourSurname: {
		defaultMessage: "Your surname",
	},
	createANewAccount: {
		defaultMessage: "Create a new account",
	},
	platformForPhysical: {
		defaultMessage:
			"Platform for physical and legal persons as assessors, companies, investors, institutions and universities",
	},
	acceptTermsAndConditions: {
		defaultMessage: "I accept the {terms}",
	},
	termsAndConditions: {
		defaultMessage: "Terms and Conditions",
	},
	iAlreadyHaveAnAccount: {
		defaultMessage: "I already have an account, I want to {logInLink}",
	},
});
