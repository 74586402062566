import { defineMessages } from "react-intl";

export default defineMessages({
	title: {
		defaultMessage: "Webinars prepared by professionals around the world",
	},
	subTitle: {
		defaultMessage: "Choose among all the webinars to excel your skills",
	},
	exploreAll: {
		defaultMessage: "Explore all webinars",
	},
	online: {
		defaultMessage: "Online",
	},
});
