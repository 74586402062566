import * as React from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import {FormattedMessage} from "react-intl";

import {useElearningsQuery} from "@api";
import {Button, InternalLink} from "@common";

import ElearningModel from "models/elearnings/Elearning";

import {ElearningsCard} from "../../ElearningsCard/ElearningsCard";
import {ElearningsCardLoading} from "../../ElearningsCard/ElearningsCardLoading";

import messages from "./messages";
import {useLocales} from "@libs";

export const ElearningsCourses = () => {
    const {data: courses, isLoading: isCoursesLoading} = useElearningsQuery(
        {
            page: 1,
            perPage: 4,
            orderDirection: "DESC",
            "search[type]": "Course",
        },
        {
            staleTime: 120_000,
        },
    );
    const {currentShortLocale} = useLocales();
    const coursesModel = courses?.items.map((course) => ElearningModel.generateFromAPI(course));

    return (
        <div>
            <div className="flex items-center justify-between gap-4">
                <div className="w-full lg:max-w-2xl">
                    <h2 className="text-2xl font-extrabold xl:text-4xl">
                        <FormattedMessage {...messages.title} />
                    </h2>
                    <p className="text-sm font-medium text-neutral-600">
                        <FormattedMessage {...messages.subTitle} />
                    </p>
                </div>
                <InternalLink
                    className="shrink-0"
                    href="/elearnings/explorer/courses"
                >
                    <Button
                        size="xl"
                        color="success"
                    >
                        <FormattedMessage {...messages.exploreAll} />
                    </Button>
                </InternalLink>
            </div>

            <ul
                className={classNames(
                    "relative mt-8 w-full overflow-x-auto rounded-xl bg-green-50 p-8 text-center",
                    "flex items-stretch justify-between gap-4",
                    "snap-x snap-mandatory",
                    "xl:grid xl:w-auto xl:grid-cols-4 xl:overflow-x-hidden",
                    "xl:snap-none",
                )}
            >
                {isCoursesLoading
                    ? [1, 2, 3, 4].map((index) => (
                        <div
                            key={index}
                            className={classNames(
                                "flex w-full shrink-0 snap-center items-center justify-center",
                                "xl:w-auto xl:flex-1",
                            )}
                        >
                            <ElearningsCardLoading/>
                        </div>
                    ))
                    : coursesModel?.map((course) => (
                        <div
                            key={course.id}
                            className={classNames(
                                "flex w-full shrink-0 snap-center items-center justify-center",
                                "xl:w-auto xl:flex-1",
                            )}
                        >
                            <li
                                className="h-full xl:w-full"
                                data-testid={`ELEARNING_${course.type}_${course.id}`}
                            >
                                <ElearningsCard
                                    elearningId={Number(course.id)}
                                    title={course.title}
                                    elearningType={course.type}
                                    elearningModality={course.careerModality.text}
                                    href={course.getInternalURL(currentShortLocale)}
                                    imgSrc={course.getFormattedImage().src}
                                    startDate={dayjs(course.startDate)}
                                    endDate={dayjs(course.endDate)}
                                    businessProfile={{
                                        name: course.owner.info.fullName,
                                        imgSrc: course.owner.info.avatar.src,
                                        href: course.owner.info.url,
                                    }}
                                />
                            </li>
                        </div>
                    ))}
            </ul>
        </div>
    );
};
