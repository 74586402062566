import { ReactNode } from "react";
import classNames from "classnames";

import { InternalLink } from "@common";

interface TenderSidebarItemProps {
	text: string;
	href: string;
	icon: ReactNode;
	isActive: boolean;
}

export const TenderSidebarItem = ({ text, href, icon, isActive }: TenderSidebarItemProps) => {
	return (
		<li>
			<InternalLink
				href={href}
				className={classNames(
					"relative flex h-11 flex-row items-center border-l-4 border-transparent pr-6 text-gray-600",
					!isActive && "hover:border-indigo-500 hover:bg-gray-50 hover:text-gray-800 focus:outline-none",
					isActive && "bg-indigo-600 text-gray-50 hover:text-gray-50",
				)}
			>
				<span className="ml-4 inline-flex items-center justify-center">{icon}</span>
				<span className="text-md ml-2 truncate tracking-wide">{text}</span>
			</InternalLink>
		</li>
	);
};
