import * as React from "react";
import { Badge } from "flowbite-react";
import { FormattedMessage } from "react-intl";

import { BadgeCheckSolid, Beaker, Card, Globe, InternalLink, Translate } from "@common";

import { MicroBusinessProfileTypes } from "constants/Profiles";
import BusinessProfileModel from "models/business-profiles/BusinessProfile";

import messages, { investorTypesMessages } from "./messages";

type PostulateCardProps = BusinessProfileModel;
type MicroInvestorTypes = Extract<
	MicroBusinessProfileTypes,
	MicroBusinessProfileTypes.BUSINESS_ANGEL | MicroBusinessProfileTypes.INCUBATOR | MicroBusinessProfileTypes.ACCELERATOR
>;

const MAX_CATEGORIES_PER_FEATURE = 5;

export const PostulateCard = ({
	info: { fullName, url, type, avatar, agreementAccepted },
	stats: { averageInvestment, averageInvestmentPercentage, numberOfStartupsToInvest },
	features: { regions, sectors, languages },
}: PostulateCardProps) => {
	return (
		<Card
			className="relative h-full w-full max-w-none"
			imgClassName="aspect-square"
			imgAlt={fullName}
			imgSrc={avatar.src}
			imgHref={url}
		>
			<div className="flex h-full flex-col justify-between text-left">
				<div className="mb-3 flex items-center justify-between gap-4">
					<InternalLink
						href={url}
						className="block"
					>
						<h5 className="mt-0 flex items-center text-lg font-semibold capitalize tracking-tight text-gray-900 line-clamp-2 hover:text-blue-600 dark:text-white">
							{fullName}
							{agreementAccepted && <BadgeCheckSolid className="ml-2 h-6 w-6 shrink-0 text-blue-500" />}
						</h5>
					</InternalLink>
				</div>
				<ul className="flex w-full flex-col gap-2 border-b border-neutral-200 pb-4">
					<li className="flex gap-2">
						<label className="text-neutral-500">
							<FormattedMessage {...messages.typeOfBusinessProfile} />:
						</label>
						<span className="font-semibold text-neutral-900">
							<FormattedMessage {...investorTypesMessages[type as MicroInvestorTypes]} />
						</span>
					</li>
					<li className="flex gap-2">
						<label className="text-neutral-500">
							<FormattedMessage {...messages.averageInvestment} />:
						</label>
						<span className="text-neutral-1000 font-semibold"> ${averageInvestment} (USD) </span>
					</li>
					<li className="flex gap-2">
						<label className="text-neutral-500">
							<FormattedMessage {...messages.averageEquity} />:
						</label>
						<span className="text-neutral-1000 font-semibold"> {averageInvestmentPercentage}% </span>
					</li>
					<li className="flex gap-2">
						<label className="text-neutral-500">
							<FormattedMessage {...messages.numberOfStartupsToInvest} />:
						</label>
						<span className="text-neutral-1000 font-semibold"> {numberOfStartupsToInvest} </span>
					</li>
				</ul>
				<ul className="mt-6 mb-4 flex w-full flex-1 flex-col gap-4 text-neutral-500">
					<li className="flex gap-2">
						<Translate className="h-6 w-6 shrink-0" />
						<div className="flex flex-wrap gap-2">
							{languages.slice(0, MAX_CATEGORIES_PER_FEATURE).map((sector) => (
								<Badge
									color="success"
									key={sector.key}
								>
									{sector.text}
								</Badge>
							))}
						</div>
					</li>
					<li className="flex gap-2">
						<Globe className="h-6 w-6 shrink-0" />
						<div className="flex flex-wrap gap-2">
							{regions.slice(0, MAX_CATEGORIES_PER_FEATURE).map((region) => (
								<Badge
									key={region.key}
									color="gray"
								>
									{region.text}
								</Badge>
							))}
						</div>
					</li>
					<li className="flex gap-2">
						<Beaker className="h-6 w-6 shrink-0" />
						<div className="flex flex-wrap gap-2">
							{sectors.slice(0, MAX_CATEGORIES_PER_FEATURE).map((sector) => (
								<Badge key={sector.key}>{sector.text}</Badge>
							))}
							{sectors.length > MAX_CATEGORIES_PER_FEATURE && (
								<span className="text-sm font-semibold"> +{sectors.length - MAX_CATEGORIES_PER_FEATURE} </span>
							)}
						</div>
					</li>
				</ul>
			</div>
		</Card>
	);
};
