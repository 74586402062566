import * as React from "react";
import { FormattedMessage } from "react-intl";

import { Card, InternalLink } from "@common";

import ChallengeTenderModel from "models/challenge-tenders/ChallengeTender";
import QuillGetTextWithoutHTMLNoSSR from "elements/InternalFormTextarea/QuillGetTextWithoutHTMLNoSSR";

import { TenderExtendedContent } from "./TenderExtendedContent/TenderExtendedContent";
import messages from "./messages";
import {useLocales} from "@libs";

export const TenderCard = (props: { displayExtraContent?: boolean; tender: ChallengeTenderModel }) => {
	const { displayExtraContent, tender } = props;
	const {currentShortLocale} = useLocales();

	return (
		<Card
			className="mb-4 max-w-none"
			imgSrc={tender.getFormattedCoverImage().src}
			imgAlt={tender.title}
			imgHref={tender.getInternalURL(currentShortLocale)}
		>
			<div className="p-4">
				<div>
					<InternalLink href={`/tenders/${tender.id}`}>
						<h3 className="text-neutral-600 hover:text-indigo-500">{tender.title}</h3>
					</InternalLink>
				</div>
				<div className="pb-3 text-neutral-500">
					<FormattedMessage
						{...messages.adminBy}
						values={{
							administrator: (
								<InternalLink href={`/profile/${tender.admin.links.link}`}>
									{tender.admin.info.name} {tender.admin.info.surname}
								</InternalLink>
							),
						}}
					/>
				</div>
				{tender.description && (
					<div>
						<QuillGetTextWithoutHTMLNoSSR
							rawText={tender.description}
							maxLength={300}
						/>
						...
						<InternalLink
							className="pl-1"
							href={`/tenders/${tender.id}`}
						>
							<FormattedMessage {...messages.readMore} />
						</InternalLink>
					</div>
				)}
			</div>
			{displayExtraContent && <TenderExtendedContent tender={tender} />}
		</Card>
	);
};
